import React, {
  InputHTMLAttributes,
  useState,
  ReactNode,
  useRef,
  forwardRef,
  useImperativeHandle,
  KeyboardEvent,
} from "react";
import { useSelector } from "react-redux";
import { RootState } from "src/store";

interface TextInputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  leftIcon?: ReactNode;
  rightIcon?: ReactNode;
  readonly?: boolean;
  onClickOfRightIcon?: () => void;
}

// Forward ref to allow external control
const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      onClickOfRightIcon,
      label,
      className,
      style,
      leftIcon,
      rightIcon,
      readOnly,
      ...props
    },
    ref
  ) => {
    const [value, setValue] = useState<string>("");
    const [isFocused, setIsFocused] = useState<boolean>(false);
    const theme = useSelector((state: RootState) => state.theme);

    const inputRef = useRef<HTMLInputElement>(null);

    // Expose internal inputRef to the parent via forwarded ref
    useImperativeHandle(ref, () => inputRef.current as HTMLInputElement);

    const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>): void => {
      if (event.key === "Enter" && inputRef.current) {
        inputRef.current.blur();
      }
    };

    const handleFocus = () => setIsFocused(true);
    const handleBlur = () => setIsFocused(false);

    const inputPaddingLeft = leftIcon ? "pl-12" : "pl-4";
    const inputPaddingRight = rightIcon ? "pr-12" : "pr-4";

    return (
      <div style={style} className={`flex flex-col my-3 ${className}`}>
        {label && (
          <label className="text-sm text-text-disabled mb-1 ml-2">
            {label}
          </label>
        )}

        <div className="relative">
          {leftIcon && (
            <div className="absolute left-3 top-1/2 transform -translate-y-1/2">
              {leftIcon}
            </div>
          )}
          <input
            ref={inputRef}
            type="text"
            readOnly={readOnly}
            value={value}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
            onChange={(e) => setValue(e.target.value)}
            className={`w-full py-3 px-3 rounded-2xl text-base text-text-title focus:border-text-disabled peer focus:ring-0 ${inputPaddingLeft} ${inputPaddingRight}`}
            style={{
              border: `1px solid ${theme.colors["--color-text-disabled"]}`,
            }}
            placeholder="Enter text"
            {...props}
          />
          {rightIcon && (
            <button onClick={onClickOfRightIcon}>
              <div className="absolute right-3 top-1/2 transform -translate-y-1/2">
                {rightIcon}
              </div>
            </button>
          )}
        </div>
      </div>
    );
  }
);

export default TextInput;
