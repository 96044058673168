import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import { ShowInfoState } from "src/types/exercise";
import exerciseApis from "src/api/exercise";

import ExerciseVideoCard from "src/components/molecules/exercise/ExerciseVideoCard";
import ScrollExpandLayout from "src/components/atoms/Layout/ScrollExpandLayout";
import BottomSheetSelect from "src/components/atoms/BottomSheetSelect";
import NoDailyRoutineCard from "src/components/molecules/exercise/NoDailyRoutineCard";
import { setExerciseVideos } from "src/store/slices/exerciseSlice";
import { useSelector } from "react-redux";
import { RootState } from "src/store";
import SpinnerLoader from "src/components/atoms/Loader/SpinnerLoader";

interface ExerciseVideoScreenProps {
  goal_id: string | null;
}

const ExerciseVideoScreen: React.FC<ExerciseVideoScreenProps> = ({
  goal_id,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { exerciseVideos } = useSelector((state: RootState) => state.exercise);

  const [showInfo, setShowInfo] = useState<ShowInfoState>({
    visible: false,
    title: "",
    description: "",
  });
  const [loading, setLoading] = useState<boolean>(true);

  const fetchAllVideos = async () => {
    try {
      const payload = { page: "1" };
      const allVideos = await exerciseApis.fetchAllVideos(payload);
      if (allVideos.code != 1) {
        throw new Error(allVideos.msg || "Internal server error!");
      }
      const modifiedResponse = modifyAllVideosResponse(allVideos.data || []);
      dispatch(setExerciseVideos(modifiedResponse));
    } catch (error) {
      console.error("Error fetching videos:", error);
    } finally {
      setLoading(false);
    }
  };

  const modifyAllVideosResponse = (data: any[]) => {
    try {
      return data.map((item) => ({
        content_data: item.content_data.map((content: any) => ({
          bookmark_capability: content.bookmark_capability,
          bookmarked: content.bookmarked,
          breathing_exercise: content.breathing_exercise,
          content_master_id: content.content_master_id,
          content_type: content.content_type,
          description: (content.description || "").replace(/(<([^>]+)>)/gi, ""),
          genre: content.genre,
          goal_master_id: content.goal_master_id,
          like_capability: content.like_capability,
          media: content.media,
          no_of_bookmark: content.no_of_bookmark,
          no_of_likes: content.no_of_likes,
          plan_type: content.plan_type,
          title: content.title,
          fitness_level: content.fitness_level,
          exercise_tools: content.exercise_tools,
          liked: content.liked,
        })),
        genre: item.genre,
        genre_master_id: item.genre_master_id,
        has_more_video: !!(
          item.genre_master_id && item.content_data.length > 1
        ),
      }));
    } catch (error) {
      console.error("Error in modifyAllVideosResponse:", error);
      return [];
    }
  };

  const onPressShowInfo = (info: { title: string; description: string }) => {
    setShowInfo((prev) => ({
      visible: !prev.visible,
      title: prev.visible ? "" : info.title,
      description: prev.visible ? "" : info.description,
    }));
  };

  const onPressViewMore = (p: { title: string; genre_master_id: string }) => {
    navigate("/exercise/more-videos", {
      state: {
        title: p.title,
        genre_master_id: p.genre_master_id,
        goal_id: goal_id,
      },
    });
  };

  useEffect(() => {
    // if (exerciseVideos.length > 0) return;
    fetchAllVideos();
  }, [dispatch]);

  return (
    <>
      {!loading ? (
        exerciseVideos.length > 0 ? (
          <>
            {exerciseVideos.map((exerciseVideo, index) => (
              <React.Fragment key={index}>
                {!exerciseVideo?.has_more_video ? (
                  <section className="p-4">
                    <h1 className="text-lg font-bold text-primary">
                      {exerciseVideo.genre || "Exercise Of the Week"}
                    </h1>
                    <ExerciseVideoCard
                      border
                      videoUrl={
                        exerciseVideo?.content_data[0]?.media[0]?.media_url
                      }
                      title={exerciseVideo?.content_data[0]?.title}
                      tags={[
                        `Fitness Level: ${exerciseVideo?.content_data[0]?.fitness_level}`,
                        `Core: ${exerciseVideo?.content_data[0]?.exercise_tools}`,
                      ]}
                      description={exerciseVideo?.content_data[0]?.description}
                      likeCount={exerciseVideo?.content_data[0]?.no_of_likes}
                      liked={exerciseVideo?.content_data[0]?.liked === "Y"}
                      bookMarked={
                        exerciseVideo?.content_data[0]?.bookmarked === "Y"
                      }
                      onInfoClick={() =>
                        onPressShowInfo({
                          title: exerciseVideo?.content_data[0]?.title,
                          description:
                            exerciseVideo?.content_data[0]?.description,
                        })
                      }
                      fullWidth
                      thumbnail_url={
                        exerciseVideo?.content_data[0]?.media[0]
                          ?.thumbnail_image_url
                      }
                      content_master_id={
                        exerciseVideo?.content_data[0]?.content_master_id
                      }
                      genre={exerciseVideo?.content_data[0]?.genre}
                    />
                  </section>
                ) : (
                  <ScrollExpandLayout
                    title={exerciseVideo.genre}
                    viewMore={false}
                    handleViewMore={() =>
                      onPressViewMore({
                        title: exerciseVideo.genre,
                        genre_master_id: exerciseVideo.genre_master_id,
                      })
                    }
                  >
                    {(exerciseVideo.content_data || []).map((content, idx) => (
                      <ExerciseVideoCard
                        key={idx}
                        border={false}
                        videoUrl={content?.media[0]?.media_url}
                        title={content?.title}
                        tags={[
                          `Fitness Level: ${content?.fitness_level}`,
                          `Core: ${content?.exercise_tools}`,
                        ]}
                        description={content?.description}
                        likeCount={content?.no_of_likes}
                        liked={content?.liked === "Y"}
                        bookMarked={content?.bookmarked === "Y"}
                        onInfoClick={() =>
                          onPressShowInfo({
                            title: content?.title,
                            description: content?.description,
                          })
                        }
                        thumbnail_url={content?.media[0]?.thumbnail_image_url}
                        content_master_id={content?.content_master_id}
                        genre={content?.genre}
                      />
                    ))}
                  </ScrollExpandLayout>
                )}
              </React.Fragment>
            ))}
          </>
        ) : (
          <section className="p-4">
            <NoDailyRoutineCard text="No exercise video found" />
          </section>
        )
      ) : (
        <div className="flex justify-center h-[40vh]">
          <SpinnerLoader size={35} />
        </div>
      )}

      {showInfo.visible && (
        <BottomSheetSelect
          open={showInfo.visible}
          close={() => setShowInfo({ ...showInfo, visible: false })}
        >
          <section className="px-4 py-2">
            <h1 className="text-xl font-bold text-text-secodary">
              {showInfo.title}
            </h1>
          </section>
          <hr className="w-full h-0.5 bg-background" />
          <p className="p-4 text-l text-text-subtitle">
            {showInfo.description}
          </p>
        </BottomSheetSelect>
      )}
    </>
  );
};

export default ExerciseVideoScreen;
