import React from "react";

interface Props {
  color?: string;
}

const AddCircleIcon: React.FC<Props> = ({ color = "#760FB2" }) => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.43758 14.168H10.6876V10.7096H14.1667V9.45963H10.6876V5.83463H9.43758V9.45963H5.83341V10.7096H9.43758V14.168ZM10.0056 18.3346C8.85654 18.3346 7.77668 18.1159 6.76604 17.6784C5.7554 17.2409 4.87161 16.6437 4.11466 15.8867C3.35772 15.1298 2.7605 14.2455 2.323 13.2338C1.8855 12.2221 1.66675 11.1411 1.66675 9.99088C1.66675 8.84062 1.8855 7.75966 2.323 6.74799C2.7605 5.73631 3.35772 4.85547 4.11466 4.10547C4.87161 3.35547 5.75592 2.76172 6.7676 2.32422C7.77927 1.88672 8.86023 1.66797 10.0105 1.66797C11.1608 1.66797 12.2417 1.88672 13.2534 2.32422C14.2651 2.76172 15.1459 3.35547 15.8959 4.10547C16.6459 4.85547 17.2397 5.73741 17.6772 6.7513C18.1147 7.76519 18.3334 8.84668 18.3334 9.99576C18.3334 11.1448 18.1147 12.2247 17.6772 13.2353C17.2397 14.246 16.6459 15.1285 15.8959 15.8829C15.1459 16.6373 14.264 17.2345 13.2501 17.6745C12.2362 18.1146 11.1547 18.3346 10.0056 18.3346ZM10.0105 17.0846C11.9758 17.0846 13.6459 16.3937 15.0209 15.0117C16.3959 13.6298 17.0834 11.9562 17.0834 9.99088C17.0834 8.02561 16.3972 6.35547 15.0248 4.98047C13.6524 3.60547 11.9775 2.91797 10.0001 2.91797C8.04175 2.91797 6.37161 3.60416 4.98966 4.97655C3.60772 6.34895 2.91675 8.02387 2.91675 10.0013C2.91675 11.9596 3.60772 13.6298 4.98966 15.0117C6.37161 16.3937 8.04522 17.0846 10.0105 17.0846Z"
        fill={color}
      />
    </svg>
  );
};

export default AddCircleIcon;
