declare global {
  interface Window {
    ReactNativeWebView?: {
      postMessage(message: string): void;
    };
  }
}

const sendMessageToParent = (eventName: string, data = {}) => {
  try {
    console.log("Sending message: ", eventName, " with data: ", data);
    if (window.ReactNativeWebView) {
      window?.ReactNativeWebView?.postMessage(
        JSON.stringify({ action: eventName, data })
      );
    } else {
      console.warn("ReactNativeWebView is not available on the window object.");
    }
  } catch (error) {
    console.log("sendMessageToParent ERROR", error);
  }
};

export default sendMessageToParent;
