import React from "react";
import BACKICON from '../../../assets/icons/progress/progressBackIcon.svg';

interface Props {
    onClick: () => void;
}

const ProgressBackIcon: React.FC<Props> = ({ onClick }) => {
    return (
        <div className="flex gap-2 p-3 bg-white" >
            <img src={BACKICON} onClick={onClick} className="cursor-pointer" />
            <div className="text-base font-normal">Back</div>
        </div>
    )
}

export default ProgressBackIcon