import moment from "moment";
import React from "react";
import Card from "src/components/atoms/Card/Card";

interface RecordedDataCardProps {
  readingName: string;
  readingValue: number;
  recordedDate: string;
}

const ProgressRecordedDataCard: React.FC<RecordedDataCardProps> = ({
  readingName,
  readingValue,
  recordedDate,
}) => {
  return (
    <Card className="!rounded-3xl">
      <div className="p-3 flex flex-col gap-4">
        <div className="flex flex-row justify-between items-center">
          <div className="flex flex-col">
            <p className="text-text-primary text-base font-poppins font-semibold">{readingName}</p>
            <p className="text-text-secondary text-base font-poppins">
              Value: <span className="font-semibold text-base font-poppins">{readingValue}</span>
            </p>
          </div>
          <div>
            <p className="text-text-subtitle text-sm font-poppins">{moment(recordedDate, "YYYY-MM-DD HH:mm:ss").format("DD MMM YYYY")}</p>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default ProgressRecordedDataCard;
