import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

import { hideLoader, showLoader } from "src/store/slices/loaderSlice";
import exerciseApis from "src/api/exercise";
import { ContentData, ShowInfoState } from "src/types/exercise";
import { RootState } from "src/store";

import ExpandableDivs from "src/components/atoms/Layout/ExpandingLayout";
import ScrollExpandLayout from "src/components/atoms/Layout/ScrollExpandLayout";
import BottomSheetSelect from "src/components/atoms/BottomSheetSelect";
import { Header } from "src/components/molecules/Header";
import ExerciseVideoCard from "src/components/molecules/exercise/ExerciseVideoCard";
import NoDailyRoutineCard from "src/components/molecules/exercise/NoDailyRoutineCard";

const ExerciseMoreVideoPage: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const { title = null, genre_master_id = null, goal_id } = location.state;
  const { visible: loading } = useSelector((state: RootState) => state.loader);

  const [exerciseVideos, setExerciseVideos] = useState<ContentData[]>([]);
  const [showInfo, setShowInfo] = useState<ShowInfoState>({
    visible: false,
    title: "",
    description: "",
  });

  const fetchAllVideos = async () => {
    try {
      const payload = {
        genre_master_id: genre_master_id,
        page: "1",
      };
      const allVideos = await exerciseApis.fetchVideosByGenreRequest(payload);
      if (allVideos.code != 1) {
        throw new Error(allVideos.msg || "Internal server error!");
      }
      const modifiedResponse = modifyAllVideosResponse(allVideos.data || []);
      setExerciseVideos(modifiedResponse);
    } catch (error) {
      console.error("Error fetching videos:", error);
    } finally {
      dispatch(hideLoader());
    }
  };

  const modifyAllVideosResponse = (data: any[]) => {
    try {
      return data.map((content) => ({
        bookmark_capability: content.bookmark_capability,
        bookmarked: content.bookmarked,
        breathing_exercise: content.breathing_exercise,
        content_master_id: content.content_master_id,
        content_type: content.content_type,
        description: (content.description || "").replace(/(<([^>]+)>)/gi, ""),
        genre: content.genre,
        goal_master_id: content.goal_master_id,
        like_capability: content.like_capability,
        media: content.media,
        no_of_bookmark: content.no_of_bookmark,
        no_of_likes: content.no_of_likes,
        plan_type: content.plan_type,
        title: content.title,
        fitness_level: content.fitness_level,
        exercise_tools: content.exercise_tools,
        liked: content.liked,
      }));
    } catch (error) {
      console.error("Error in modifyAllVideosResponse:", error);
      return [];
    }
  };

  const onPressShowInfo = (info: { title: string; description: string }) => {
    setShowInfo((prev) => ({
      visible: !prev.visible,
      title: prev.visible ? "" : info.title,
      description: prev.visible ? "" : info.description,
    }));
  };

  useEffect(() => {
    if (!title || !genre_master_id) {
      setExerciseVideos([]);
      return;
    }
    dispatch(showLoader());
    fetchAllVideos();
  }, [dispatch]);

  return (
    <div className="h-screen bg-background">
      <ExpandableDivs
        showExpandBtn={false}
        component1={
          <div>
            <Header
              backButton={true}
              title={location.state?.title || ""}
              onClickOfBackBtn={() =>
                navigate(`/exercise?activeTab=videos&goal_id=${goal_id}`)
              }
            />
          </div>
        }
        component2={
          <div>
            {!loading &&
              (exerciseVideos.length > 0 ? (
                <ScrollExpandLayout title={title} viewMore>
                  {exerciseVideos.map((exerciseVideo, idx) => (
                    <ExerciseVideoCard
                      key={idx}
                      border={false}
                      videoUrl={exerciseVideo.media[0]?.media_url}
                      title={exerciseVideo.title}
                      tags={[
                        `Fitness Level: ${exerciseVideo.fitness_level}`,
                        `Core: ${exerciseVideo.exercise_tools}`,
                      ]}
                      description={exerciseVideo.description}
                      likeCount={exerciseVideo.no_of_likes}
                      liked={exerciseVideo.liked === "Y"}
                      bookMarked={exerciseVideo.bookmarked === "Y"}
                      onInfoClick={() =>
                        onPressShowInfo({
                          title: exerciseVideo.title,
                          description: exerciseVideo.description,
                        })
                      }
                      thumbnail_url={
                        exerciseVideo.media[0]?.thumbnail_image_url
                      }
                      fullWidth
                      content_master_id={exerciseVideo?.content_master_id}
                      genre={exerciseVideo?.genre}
                    />
                  ))}
                </ScrollExpandLayout>
              ) : (
                <section className="p-4">
                  <NoDailyRoutineCard text="No exercise video found" />
                </section>
              ))}
          </div>
        }
      />

      {showInfo.visible && (
        <BottomSheetSelect
          open={showInfo.visible}
          close={() => setShowInfo({ ...showInfo, visible: false })}
        >
          <section className="px-4 py-2">
            <h1 className="text-xl font-bold text-text-secodary">
              {showInfo.title}
            </h1>
          </section>
          <hr className="w-full h-0.5 bg-background" />
          <p className="p-4 text-l text-text-subtitle">
            {showInfo.description}
          </p>
        </BottomSheetSelect>
      )}
    </div>
  );
};

export default ExerciseMoreVideoPage;
