import React, { useState, useRef, useEffect } from "react";
import classNames from "classnames";

import ThreeDotIcon from "../../../assets/icons/three-dot.svg";

interface MenuItem {
  label: string;
  icon?: React.ReactNode;
  onClick: () => void;
}

interface ThreeDotMenuProps {
  items: MenuItem[];
}

const ThreeDotMenu: React.FC<ThreeDotMenuProps> = ({ items }) => {
  const [showMenu, setShowMenu] = useState(false);
  const menuRef = useRef<HTMLDivElement | null>(null);

  // Close menu when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent | TouchEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setShowMenu(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative">
      {/* Three-dot button */}
      <button
        onClick={() => {
          setShowMenu((prev) => !prev);
        }}
        className="focus:outline-none p-2"
      >
        <img src={ThreeDotIcon} className="w-5 h-5" alt="three-dot-icon" />
      </button>

      {/* Menu */}
      {showMenu && (
        <div
          ref={menuRef}
          className="absolute z-10 right-0 bg-white shadow-lg border border-gray-300 rounded-lg w-24"
          style={{ display: showMenu ? "block" : "none" }} // Ensure visibility
        >
          {items.map((item, index) => (
            <button
              key={index}
              className={classNames(
                "flex items-center px-4 py-2 hover:bg-gray-100 w-full",
                index !== items.length - 1 && "border-b border-b-gray-300"
              )}
              onClick={() => {
                item.onClick();
                setShowMenu(false);
              }}
            >
              {item.icon && <span className="mr-2">{item.icon}</span>}
              <span className="text-sm text-text-title">{item.label}</span>
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default ThreeDotMenu;
