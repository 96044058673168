import React from "react";
import "./style.css";

interface SpinnerProps {
  size?: "small" | "medium" | "large"; // Optional size prop
  color?: string; // Optional color prop
  className?: string; // Optional additional className
}

const Spinner: React.FC<SpinnerProps> = ({
  size = "medium",
  color = "#3498db",
  className = "",
}) => {
  const sizeMap = {
    small: "w-4 h-4",
    medium: "w-8 h-8",
    large: "w-12 h-12",
  };

  return (
    <div
      className={`spinner border-4 border-t-transparent rounded-full animate-spin ${sizeMap[size]} ${className}`}
      style={{ borderColor: `${color} transparent transparent transparent` }}
    ></div>
  );
};

export default Spinner;
