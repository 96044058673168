import { methods, request } from "./base";

const exerciseApis = {
  fetchAllVideos: async (payload: Record<string, string>) =>
    await request({
      method: methods.POST,
      route: "/content/exercise_list",
      priv: true,
      payload,
    }),
  fetchVideosByGenreRequest: async (payload: Record<string, string>) =>
    await request({
      method: methods.POST,
      route: "/content/exercise_list_by_genre_id",
      priv: true,
      payload,
    }),
  toggleVideoLike: async (payload: object) =>
    await request({
      method: methods.POST,
      route: "/content/update_likes",
      priv: true,
      payload,
    }),
  toggleVideoBookmark: async (payload: object) =>
    await request({
      method: methods.POST,
      route: "/content/update_bookmarks",
      priv: true,
      payload,
    }),
  fetchExercises: async () =>
    await request({
      method: methods.POST,
      route: "/goal_readings/get_exercise_list",
      priv: true,
    }),
  getGoalAndTarget: async (payload: object) =>
    await request({
      method: methods.POST,
      route: "/goal_readings/goal_log_data_date_wise",
      priv: true,
      payload,
    }),
  logExercise: async (payload: object) =>
    await request({
      method: methods.POST,
      route: "/goal/update_exercise_goal",
      priv: true,
      payload,
    }),
  fetchLoggedExercise: async (payload: object) =>
    await request({
      method: methods.POST,
      route: "/goal_readings/fetch_exercise_goal_logs",
      priv: true,
      payload,
    }),
  fetchPlanDetails: async (payload: object) =>
    await request({
      method: methods.POST,
      route: "/content/exercise_plan_details",
      priv: true,
      payload,
    }),
  markExerciseDone: async (payload: object) =>
    await request({
      method: methods.POST,
      route: "/content/exercise_plan_mark_as_done",
      priv: true,
      payload,
    }),
  markDifficulty: async (payload: object) =>
    await request({
      method: methods.POST,
      route: "/content/exercise_plan_update_difficulty",
      priv: true,
      payload,
    }),
  fetchGraphRecords: (payload: object) => {
    const route = "/goal_readings/get_goal_records";
    return request({
      route,
      method: methods.POST,
      priv: true,
      payload,
    });
  },
};

export default exerciseApis;
