import React from "react";
import { useSelector } from "react-redux";

import AddCircleIcon from "../../../components/atoms/Icons/AddCircleIcon";
import { RootState } from "src/store";
import { SearchFoodItem } from "src/types/foodDiary";

interface Props {
  title: string;
  searchText: string;
  isLoading: boolean;
  data: SearchFoodItem[];
  page: number;
  isLastPageReached: boolean;
  onScroll: any;
  onClickAddIcon: any;
}

const SearchResult: React.FC<Props> = ({
  title,
  searchText,
  isLoading,
  data,
  page,
  isLastPageReached,
  onScroll,
  onClickAddIcon,
}) => {
  const theme = useSelector((state: RootState) => state.theme);

  return (
    <div className="relative h-full">
      {/* Sticky Header */}
      <div className="bg-background sticky top-0 z-10 p-4 border-b border-gray-200">
        <div className="text-base font-semibold">{title}</div>
      </div>
      {data?.length === 0 && searchText && !isLoading ? (
        <p className="px-4 text-base text-text-subtitle mt-4">
          Searched meal not found!
        </p>
      ) : null}

      {/* Scrollable Content */}
      {data?.length > 0 && (
        <div className="overflow-y-auto h-full px-4 pt-2" onScroll={onScroll}>
          {data.map((foodItem, index) => (
            <button
              key={index}
              className="w-full mt-4 flex items-center justify-between border-b border-gray-100 pb-2"
              onClick={() => onClickAddIcon(foodItem)}
            >
              <div>
                <p className="text-left text-sm text-text-ternary">
                  {foodItem?.FOOD_NAME}
                </p>
                <p className="text-left text-xs text-text-subtitle">
                  1 {foodItem?.UNIT_NAME}
                </p>
              </div>
              <div className="flex items-center gap-1">
                <p className="w-16 text-xs text-end text-text-subtitle">
                  {foodItem?.CALORIES_CALCULATED_FOR} Cal
                </p>
                <div className="w-8 h-8 flex items-center justify-center">
                  <AddCircleIcon color={theme.colors["--color-primary"]} />
                </div>
              </div>
            </button>
          ))}

          {isLoading && data?.length > 0 && (
            <div className="text-center py-8">Loading more...</div>
          )}
          {isLastPageReached && data?.length > 0 && (
            <div className="text-center py-8 text-sm text-gray-500">
              No more results.
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SearchResult;
