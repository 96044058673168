import React, { ReactNode, useEffect, useRef } from "react";

interface ScrollExpandLayoutProps {
  children: ReactNode;
  handleViewMore?: () => void;
  viewMore: boolean;
  title: string;
}

const ScrollExpandLayout: React.FC<ScrollExpandLayoutProps> = (props) => {
  const { children, handleViewMore = () => {}, viewMore, title } = props;
  const containerRef = useRef<HTMLDivElement | null>(null);

  return (
    <div className="" ref={containerRef}>
      {!viewMore && (
        <div className="flex px-4 pb-0 justify-between">
          <h1 className="font-bold text-lg text-text-subtitle">{title}</h1>
          <button
            className="font-bold text-base text-primary"
            onClick={handleViewMore}
          >
            View More
          </button>
        </div>
      )}
      <div
        className={`flex p-4 gap-4 ${
          viewMore
            ? "w-full h-full overflow-hidden flex-col"
            : "overflow-y-hidden overflow-x-auto flex-row"
        }`}
      >
        {children}
      </div>
    </div>
  );
};

export default ScrollExpandLayout;
