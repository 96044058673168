import React from "react";

interface Props {
  color?: string;
  filled?: boolean;
}

const BookMarkIcon: React.FC<Props> = ({ color = "#760FB2", filled = false }) => {
  return (
    <svg fill={color} width="100%" height="100%" viewBox="0 0 1920 1920" xmlns="http://www.w3.org/2000/svg">
      <g id="SVGRepo_bgCarrier" stroke-width="0" />
      <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" />
      {!filled
        ? <g id="SVGRepo_iconCarrier"> <path d="m960.481 1412.11 511.758 307.054V170.586c0-31.274-25.588-56.862-56.862-56.862H505.586c-31.274 0-56.862 25.588-56.862 56.862v1548.578l511.757-307.055ZM1585.963 1920 960.48 1544.711 335 1920V170.586C335 76.536 411.536 0 505.586 0h909.79c94.05 0 170.587 76.536 170.587 170.586V1920Z" fill-rule="evenodd" /> </g>
        : <g id="SVGRepo_iconCarrier"> <path d="M1585.963 1920 960.48 1544.711 335 1920V170.586C335 76.536 411.536 0 505.586 0h909.79c94.05 0 170.587 76.536 170.587 170.586V1920Z" fill-rule="evenodd" /> </g>}
    </svg>
  );
};

export default BookMarkIcon;
