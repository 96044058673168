import React, { useCallback } from "react";
import { useSelector } from "react-redux";

import Card from "src/components/atoms/Card/Card";

import FlameIcon from "../../../assets/icons/common/flame.svg";
import ProteinsIcon from "../../../assets/icons/macronutrients/protein.svg";
import FatsIcon from "../../../assets/icons/macronutrients/fat.svg";
import CarbsIcon from "../../../assets/icons/macronutrients/carb.svg";
import FibersIcon from "../../../assets/icons/macronutrients/fiber.svg";

import { RootState } from "src/store";
import { NutritionOption } from "src/types/foodDiary";

interface Props {
  itemQuantity: number | string;
}

const icons: Record<string, any> = {
  protein: ProteinsIcon,
  fats: FatsIcon,
  carbs: CarbsIcon,
  fiber: FibersIcon,
};

const MicronutrientsInformation: React.FC<Props> = ({ itemQuantity }) => {
  const { foodNutritionsDetails } = useSelector(
    (state: RootState) => state.foodDiary
  );

  const renderNutritionDataItem = useCallback(
    (item: NutritionOption, index: number) => {
      return (
        <div className="flex items-center justify-between px-1 py-2">
          <div className="flex items-center gap-2">
            <img
              src={icons[item.key]}
              className="w-6 h-6"
              alt={`macronutrients-icon-${index}`}
            />
            <div className="text-xs text-text-subtitle">{item.title}</div>
          </div>
          <div className="text-xs text-text-subtitle">
            {(Number(item?.value) * Number(itemQuantity)).toFixed(2) + " g"}
          </div>
        </div>
      );
    },
    [itemQuantity, foodNutritionsDetails?.nutritionOption]
  );

  return (
    <div className="p-4">
      <p className="text-base font-bold">Macronutrients Information</p>
      <Card className="mt-3">
        <div className="flex items-center justify-between border-b border-b-gray-300 px-1 pb-3 mb-3">
          <div>
            <div className="text-lg font-semibold">
              {Math.round(
                Number(foodNutritionsDetails?.data?.calories || 0) *
                  Number(itemQuantity)
              )}
            </div>
            <p className="text-xs">Calories</p>
          </div>
          <div className="w-10 h-10 rounded-full bg-gray-100 flex items-center justify-center">
            <img src={FlameIcon} alt="flame-icon" />
          </div>
        </div>
        <div>
          {foodNutritionsDetails?.nutritionOption?.map(renderNutritionDataItem)}
        </div>
      </Card>
    </div>
  );
};

export default MicronutrientsInformation;
