import React, { memo } from "react";

import FoodDiaryItemComponent from "../FoodDiaryItemComponent";

interface FoodItemsProps {
  foodItems: any[];
  message?: string;
  buttonPressEvents: (
    type: "delete" | "consume" | "abstain" | "edit",
    item: any
  ) => void;
  freeUser?: boolean;
  buttonVisible?: boolean;
}

const FoodItems: React.FC<FoodItemsProps> = ({
  foodItems,
  message,
  buttonPressEvents,
  freeUser = true,
  buttonVisible = true,
}) => {
  const renderFoodItem = ({ item, index }: { item: any; index: number }) => {
    const buttonEnable = !freeUser
      ? item?.type === "manual"
        ? true
        : buttonVisible
      : freeUser;
    return (
      <FoodDiaryItemComponent
        isLast={foodItems?.length ? foodItems?.length - 1 === index : false}
        index={index}
        item={item}
        buttonPressEvents={buttonPressEvents}
        buttonEnable={buttonEnable}
      />
    );
  };

  if (!foodItems?.length)
    return <p className="p-4 text-center text-gray-500 text-xs">{message}</p>;
  else
    return (
      <div>
        {foodItems?.map((item, index) => renderFoodItem({ item, index }))}
      </div>
    );
};

export default memo(FoodItems);
