import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LandingState } from "../../types/landing";

const homeInitialState: LandingState = {
  healthDiaries: {
    data: undefined,
    isLoading: false,
    errors: "",
    // isHealthDiaryUpdate: false,
    // isAnimationComplete: false
  },
  healthInsights: {
    data: undefined,
    isLoading: false,
    errors: "",
    isHealthInsightsUpdate: false,
    isAnimationComplete: false,
    isGoogleAppleUpdated: false,
  },
  prescription: {
    data: undefined,
    isLoading: false,
    errors: "",
  },
  isHealthRefresh: false,
  isGoogleAppleConnected: false,
  lastRefreshDate: "",
  isHeightWeightFromHome: false,
  isBookedLabtest: false,
  isBcaSpirometerChanged: false,
  loaderQuestionIndex: 0,
};

export const homeSlice = createSlice({
  name: "landing",
  initialState: homeInitialState,
  reducers: {
    healthDiariesRequest: (state: any, action: PayloadAction<any>) => {
      state.healthDiaries.isLoading = true;
      state.healthDiaries.errors = "";
    },
    healthDiariesSuccess: (state: any, action: PayloadAction<any>) => {
      state.healthDiaries.isLoading = false;
      state.healthDiaries.data = action.payload?.data;
    },
    healthDiariesError: (
      state: any,
      { payload: error }: PayloadAction<string>
    ) => {
      state.healthDiaries.isLoading = false;
      state.healthDiaries.errors = error;
    },
    healthInsightsRequest: (state: any, action: PayloadAction<any>) => {
      state.healthInsights.isLoading = true;
      state.healthInsights.errors = "";
    },
    healthInsightsSuccess: (state: any, action: PayloadAction<any>) => {
      state.healthInsights.isLoading = false;
      state.healthInsights.data = action.payload?.data;
    },
    healthInsightsError: (
      state: any,
      { payload: error }: PayloadAction<string>
    ) => {
      state.healthInsights.isLoading = false;
      state.healthInsights.errors = error;
    },
    // healthDiaryUpdate: (
    //     state: any,
    //     action: PayloadAction<any>,
    // ) => {
    //     state.healthDiaries.isHealthDiaryUpdate = action.payload.isHealthDiaryUpdate;
    // },
    healthInsightsUpdate: (state: any, action: PayloadAction<any>) => {
      state.healthInsights.isHealthInsightsUpdate =
        action.payload.isHealthInsightsUpdate;
    },
    isGoogleAppleUpdated: (state: any, action: PayloadAction<any>) => {
      state.healthInsights.isGoogleAppleUpdated =
        action.payload.isGoogleAppleUpdated;
    },
    healthSectionRefresh: (state: any, action: PayloadAction<any>) => {
      state.isHealthRefresh = action.payload.isHealthRefresh;
    },
    googleAppleConnected: (state: any, action: PayloadAction<any>) => {
      state.isGoogleAppleConnected = action.payload.isGoogleAppleConnected;
    },
    // healthDiaryAnimationCompleted: (
    //     state: any,
    //     action: PayloadAction<any>,
    // ) => {
    //     state.healthDiaries.isAnimationComplete = action.payload.isAnimationComplete;
    // },
    healthInsightsAnimationCompleted: (
      state: any,
      action: PayloadAction<any>
    ) => {
      state.healthInsights.isAnimationComplete =
        action.payload.isAnimationComplete;
    },
    updateLastRefreshDate: (state: any, action: PayloadAction<any>) => {
      state.lastRefreshDate = action.payload.lastRefreshDate;
    },
    updateHeightWeightFromHome: (state: any, action: PayloadAction<any>) => {
      state.isHeightWeightFromHome = action.payload.isHeightWeightFromHome;
    },
    updateBookedLabtest: (state: any, action: PayloadAction<any>) => {
      state.isBookedLabtest = action.payload.isBookedLabtest;
    },
    updateBcaAndSpiromter: (state: any, action: PayloadAction<any>) => {
      state.isBcaSpirometerChanged = action.payload.isBcaSpirometerChanged;
    },
    prescriptionRequest: (state: any, action: PayloadAction<any>) => {
      state.prescription.isLoading = true;
      state.prescription.errors = "";
    },
    prescriptionSuccess: (state: any, action: PayloadAction<any>) => {
      state.prescription.isLoading = false;
      state.prescription.data = action.payload?.data;
    },
    prescriptionError: (
      state: any,
      { payload: error }: PayloadAction<string>
    ) => {
      state.prescription.isLoading = false;
      state.prescription.errors = error;
    },
    getLoaderQuestionIndex: (state: any, action: PayloadAction<any>) => {
      const indices = Array.from({ length: 11 }, (_, index) => index);

      // Shuffle the array using the Fisher-Yates algorithm
      for (let i = indices.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [indices[i], indices[j]] = [indices[j], indices[i]];
      }
      state.loaderQuestionIndex = indices[0];
    },
  },
});

export const {
  healthDiariesRequest,
  healthDiariesSuccess,
  healthDiariesError,
  // healthDiaryUpdate,
  healthInsightsRequest,
  healthInsightsSuccess,
  healthInsightsError,
  healthSectionRefresh,
  // healthDiaryAnimationCompleted,
  healthInsightsAnimationCompleted,
  isGoogleAppleUpdated,
  googleAppleConnected,
  healthInsightsUpdate,
  updateLastRefreshDate,
  updateHeightWeightFromHome,
  updateBookedLabtest,
  updateBcaAndSpiromter,
  getLoaderQuestionIndex,
} = homeSlice.actions;

export default homeSlice.reducer;
