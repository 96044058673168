export const LOCAL_STORAGE = {
  AUTH_TOKEN: "auth_token",
  CLIENT_SOURCE: "client_source",
  USER_INFO: "user_info",
  QUERY_PARAMS: "query_params",
};

export const LOADER_TEXT = [
  "Loading... Did you know laughter is the best medicine? Loading your dose now!",
  "Loading... Why did the doctor carry a red pen? In case they needed to draw blood.",
  "Loading... Like waiting for chai to reach the perfect level of 'kadak'.",
  "Loading... Just like waiting for the Mumbai local during rush hour!",
  "Loading... Like waiting for the perfect 'pakora' to crisp up!",
  "Loading... Just like waiting for the doctor's appointment in a busy  hospital.",
  "Loading... Did you know? Yoga originated in ancient India over 5,000 years ago. Take a deep breath while we load!",
  "Loading... Why did the stethoscope join social media? To get more 'heart' reactions!",
  "Loading... Like waiting for the 'pani puri' vendor to serve up a healthy dose of deliciousness!",
  "Loading... Just like waiting for your chai to cool down enough to avoid burning your tongue!",
  "Loading... Like waiting for your 'desi nuskha' to kick in, but with digital delays!",
];

export const constants = {
  ERROR_MESSAGES: {
    SOMETHING_WENT_WRONG: "Something went wrong!",
  },
  SEARCH_FOOD_ITEM_TITLE: {
    SEARCH_RESULT: "Search Result",
    RECENT_SEARCH: "Recent Search",
  },
};
