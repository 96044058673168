// src/store/slices/insightSlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { common, insight } from "../../types";

// Initial state for the auth slice
const initialState: insight.insightState = {
  updatePatientCaloriesGoal: {
    isLoading: false,
    data: null,
    error: "",
  },
  updatePatientExerciseGoal: {
    isLoading: false,
    data: null,
    error: "",
  },
};

// Define the auth slice
const insightSlice = createSlice({
  name: "insight",
  initialState,
  reducers: {
    updatePatientCaloriesGoalRequest: (
      state: insight.insightState,
      action: PayloadAction<any>
    ) => {
      state.updatePatientCaloriesGoal.isLoading = true;
      state.updatePatientCaloriesGoal.data = null;
      state.updatePatientCaloriesGoal.error = "";
    },
    updatePatientCaloriesGoalSuccess: (
      state: insight.insightState,
      action: PayloadAction<common.ResponseGenerator>
    ) => {
      state.updatePatientCaloriesGoal.isLoading = false;
      state.updatePatientCaloriesGoal.data = action.payload;
      state.updatePatientCaloriesGoal.error = "";
    },
    updatePatientCaloriesGoalError: (
      state: insight.insightState,
      { payload: error }: PayloadAction<string>
    ) => {
      state.updatePatientCaloriesGoal.isLoading = false;
      state.updatePatientCaloriesGoal.data = null;
      state.updatePatientCaloriesGoal.error = error;
    },
    resetUpdatePatientCaloriesGoalRequest: (state: insight.insightState) => {
      state.updatePatientCaloriesGoal.isLoading = false;
      state.updatePatientCaloriesGoal.data = null;
      state.updatePatientCaloriesGoal.error = "";
    },

    updateGoalRequest: (
      state: insight.insightState,
      action: PayloadAction<any>
    ) => {
      state.updatePatientExerciseGoal.isLoading = true;
      state.updatePatientExerciseGoal.data = null;
      state.updatePatientExerciseGoal.error = "";
    },
    updateGoalSuccess: (
      state: insight.insightState,
      action: PayloadAction<any>
    ) => {
      state.updatePatientExerciseGoal.isLoading = true;
      state.updatePatientExerciseGoal.data = action.payload;
      state.updatePatientExerciseGoal.error = "";
    },
    updateGoalFailed: (
      state: insight.insightState,
      { payload: error }: PayloadAction<string>
    ) => {
      state.updatePatientExerciseGoal.isLoading = true;
      state.updatePatientExerciseGoal.data = null;
      state.updatePatientExerciseGoal.error = error;
    },
    resetUpdateGoal: (state: insight.insightState) => {
      state.updatePatientExerciseGoal.isLoading = false;
      state.updatePatientExerciseGoal.data = null;
      state.updatePatientExerciseGoal.error = "";
    },
  },
});

// Export actions and reducer
export const {
  updatePatientCaloriesGoalRequest,
  updatePatientCaloriesGoalSuccess,
  updatePatientCaloriesGoalError,

  resetUpdatePatientCaloriesGoalRequest,

  updateGoalRequest,
  updateGoalSuccess,
  updateGoalFailed,

  resetUpdateGoal,
} = insightSlice.actions;

export default insightSlice.reducer;
