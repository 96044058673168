/**
 * Example
 *
 * const value = 30;
 * <CircularProgress value={value} text={`${value}%`} />
 */

import React from "react";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";

interface Props {
  value: number;
  text?: string;
  className?: string;
}

export const colorsOfprogressBar = (values: number) => {
  if (values === 0) {
    return {
      tintColor: "rgba(145, 145, 145, 0.2)",
      backgroundColor: "rgba(145, 145, 145, 0.2)",
      textColor: "#919191",
    };
  } else if (values > 0 && values < 25) {
    return {
      tintColor: "#FF3333",
      backgroundColor: "rgba(255, 51, 51, 0.2)",
      textColor: "#FF3333",
    };
  } else if (values >= 25 && values < 75) {
    return {
      tintColor: "#FAB000",
      backgroundColor: "rgba(250, 176, 0, 0.2)",
      textColor: "#FAB000",
    };
  } else if (values >= 75 && values <= 100) {
    return {
      tintColor: "#2ecc71",
      backgroundColor: "rgba(27, 179, 51, 0.2)",
      textColor: "#2ecc71",
    };
  } else {
    return {
      tintColor: "#FF3333",
      backgroundColor: "rgba(255, 51, 51, 0.2)",
      textColor: "#FF3333",
    };
  }
};

const CircularProgress: React.FC<Props> = ({ value, text, className }) => {
  const colors = colorsOfprogressBar(value);
  return (
    <div className={className}>
      <CircularProgressbar
        value={value}
        text={text}
        styles={buildStyles({
          pathColor: colors.tintColor,
          textColor: colors.textColor,
          trailColor: colors.backgroundColor,
          backgroundColor: colors.backgroundColor,
        })}
      />
    </div>
  );
};

export default CircularProgress;
