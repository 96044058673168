import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "src/store";
import { ProgressPointer } from "../Icons/ProgressPointer";

interface ProgressRangeMeterProps {
  leftRange: number | null;
  rightRange: number | null;
  value: number | null;
}

const ProgressRangeMeter: React.FC<ProgressRangeMeterProps> = ({ leftRange, rightRange, value }) => {
  const [arrowPosition, setArrowPosition] = useState<number | null>(null);
  const theme = useSelector((state: RootState) => state.theme);

  useEffect(() => {
    if (leftRange === null && rightRange === null || value == null) {
      setArrowPosition(null); // Hide cursor if ranges are null
      return;
    }
    let position: number;
    if (leftRange !== null && rightRange !== null) {
      if (value < leftRange) {
        position = 10; // 10% for left red zone
      } else if (value > rightRange) {
        position = 90; // 90% for right red zone
      } else if (value === leftRange) {
        position = 24; // Start of green zone (20%)
      } else if (value === rightRange) {
        position = 80; // End of green zone (80%)
      } else {
        position = 50;
      }
    } else {
      position = 50;  // Center the pointer if only one range is provided
    }
    setArrowPosition(position);
  }, [leftRange, rightRange, value]);

  return (
    <>
      {arrowPosition ? (
        <>
          {leftRange !== null && rightRange !== null ? (
            <div className="flex flex-col items-center w-full">
              <p className="text-xs text-text-ternary font-manrope">Ideal Range</p>
              <div className="relative w-full mt-4">
                <div className="flex flex-row gap-px relative">
                  {/* Left Red Line */}
                  <div
                    className="h-1 bg-color-extra-light-red"
                    style={{ width: "40%" }}
                  ></div>

                  {/* Green Range */}
                  <div className="w-full">
                    <div className="h-1 w-full bg-color-extra-light-green rounded-full"></div>
                  </div>

                  {/* Right Red Line */}
                  <div
                    className="h-1 bg-color-extra-light-red"
                    style={{ width: "40%" }}
                  ></div>

                  {/* Pointer (Arrow) */}
                  {arrowPosition !== null && (
                    <div
                      className="absolute top-[-13px] left-0 transform -translate-x-1/2"
                      style={{
                        left: `${arrowPosition}%`,
                      }}
                    >
                      <ProgressPointer color={theme.colors["--color-error"]} />
                    </div>
                  )}
                </div>
              </div>
              <div className="flex justify-around w-full mt-2">
                <p className="text-text-ternary font-manrope text-xs">{leftRange}</p>
                <p className="text-text-ternary font-manrope text-xs">{rightRange}</p>
              </div>
            </div>
          ) : (
            <div className="flex flex-col items-center w-full">
              <p className="text-xs text-text-ternary font-manrope">Ideal Range</p>

              <div className="relative w-full mt-4">
                <div className="flex flex-row gap-px relative">
                  {/* Left Range Line */}
                  <div
                    className={`h-1 w-1/2 ${leftRange !== null && rightRange === null
                      ? "bg-color-extra-light-green"
                      : "bg-color-extra-light-red"
                      } rounded-l-full`}
                  ></div>

                  {/* Right Range Line */}
                  <div
                    className={`h-1 w-1/2 ${rightRange !== null && leftRange === null
                      ? "bg-color-extra-light-green"
                      : "bg-color-extra-light-red"
                      } rounded-r-full`}
                  ></div>

                  {/* Pointer (Arrow) */}
                  {arrowPosition !== null && (
                    <div
                      className="absolute top-[-13px] left-0 transform -translate-x-1/2"
                      style={{
                        left: "50%", // Center pointer for single range
                      }}
                    >
                      <ProgressPointer color={theme.colors["--color-error"]} />
                    </div>
                  )}
                </div>
              </div>

              <div className="flex justify-center w-full mt-2">
                {leftRange !== null && (
                  <p className="text-text-ternary font-manrope text-xs">{leftRange}</p>
                )}
                {rightRange !== null && (
                  <p className="text-text-ternary font-manrope text-xs">{rightRange}</p>
                )}
              </div>
            </div>
          )}
        </>
      ) : (
        <div className="flex flex-col text-text-ternary font-manrope text-xs items-center w-full">
        </div>
      )}

    </>
  );
};

export default ProgressRangeMeter;