import React from "react";

interface Props {
  color?: string;
  className?: string;
}

const HealthBackground: React.FC<Props> = ({
  color = "#E4CAFF",
  className,
}) => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width="512"
      height="220"
      className={className}
    >
      <path
        d="M0 0 C8.02847607 5.22315914 13.39473801 11.3970269 15.63671875 20.921875 C16.87516027 28.59020482 15.65797322 35.90280187 11.6640625 42.59375 C6.47991432 49.76871797 0.59822701 53.97891147 -7.9765625 56.31640625 C-17.04721605 57.51148577 -24.58431088 55.88912832 -32.19921875 50.84375 C-39.11112376 45.37882323 -42.83956957 38.48416128 -43.9140625 29.75390625 C-44.33871028 20.31876347 -41.93866714 13.65108175 -35.9765625 6.31640625 C-26.49657863 -3.16357762 -12.24864891 -6.53413055 0 0 Z M-28.2890625 15.69140625 C-31.63694726 20.89922698 -32.38937335 25.0955056 -31.515625 31.140625 C-30.14523098 36.67185302 -26.53074728 40.1635091 -21.9765625 43.31640625 C-16.57736393 45.11613911 -11.04098985 45.01753252 -5.6640625 43.19140625 C-0.99011443 39.93051225 2.53185963 35.87592378 4.0234375 30.31640625 C4.69655898 24.37049986 3.35731332 20.16475615 0.0234375 15.31640625 C-4.03558449 11.12277384 -7.34226034 9.15647762 -13.1640625 9.00390625 C-19.92722067 9.07472466 -23.51320084 10.78646725 -28.2890625 15.69140625 Z "
        fill={color}
        transform="translate(50.9765625,28.68359375)"
      />
      <path
        d="M0 0 C5.51298803 4.43435993 11.48410444 10.11994098 12.875 17.3125 C13.75434115 28.38476742 13.34114082 36.32424335 6.3125 45 C0.32458247 51.25861905 -5.25146262 54.41325664 -14.02807617 54.89916992 C-21.7187034 55.03974839 -27.7081051 53.68765562 -34.125 49.3125 C-35.053125 48.714375 -35.98125 48.11625 -36.9375 47.5 C-43.05130919 41.38619081 -46.07802178 34.19263512 -46.5 25.5625 C-46.27671483 16.92005039 -43.14481105 9.29716087 -37.19140625 3.03515625 C-25.84581516 -6.42307753 -12.66469151 -6.83893342 0 0 Z M-30.5 13.75 C-33.71379084 18.81790094 -35.00479335 23.27644946 -34.125 29.3125 C-32.49223496 34.61165359 -29.81637978 38.43532151 -25.109375 41.40234375 C-20.11708032 43.6921167 -15.17188218 43.91078464 -9.9375 42.3125 C-4.44266146 39.91056459 -1.26917749 35.65060183 1.3125 30.375 C2.53954301 23.69443249 1.2363816 18.60080993 -2.546875 13.078125 C-6.17172282 9.02260219 -10.7400092 7.15104559 -16.125 6.8125 C-21.91346 7.14294358 -26.50513585 9.65000785 -30.5 13.75 Z "
        fill={color}
        transform="translate(497.125,132.6875)"
      />
      <path
        d="M0 0 C4.46424129 2.86147797 7.22484725 6.50911969 9.453125 11.26953125 C10.85681099 18.1475926 10.57861431 24.17853952 7.078125 30.33203125 C2.86305527 35.62948925 -1.09601467 38.86665771 -7.97265625 39.703125 C-16.51785589 39.87709912 -21.17314419 38.65459391 -27.4453125 32.9453125 C-31.46092192 28.70919273 -32.8642707 24.0331077 -32.953125 18.32421875 C-32.54976505 13.18137942 -31.06179211 8.45902257 -27.921875 4.33203125 C-19.24132163 -2.90437942 -10.38801589 -5.13952534 0 0 Z M-20.921875 10.33203125 C-23.50387992 14.4684204 -23.75087302 18.58786436 -22.921875 23.33203125 C-21.20818891 26.40265093 -19.11286048 28.73653851 -15.921875 30.33203125 C-11.98066878 30.96615608 -8.4327897 31.08888501 -4.80859375 29.2734375 C-1.48949116 26.73772559 0.44983567 24.49916372 1.203125 20.33203125 C1.36335278 16.34159653 0.32672897 13.66616817 -1.921875 10.33203125 C-4.71436378 7.33269145 -7.76497441 6.57858738 -11.82421875 6.19921875 C-15.67306655 6.44290744 -17.83947029 8.05105176 -20.921875 10.33203125 Z "
        fill={color}
        transform="translate(39.921875,159.66796875)"
      />
      <path
        d="M0 0 C5.26076201 3.12882093 9.17500123 7.35834185 11.51953125 13.05078125 C12.27160744 19.66205571 12.4534669 26.46195885 8.99609375 32.33203125 C5.17600481 36.77869562 -0.57314756 40.6570202 -6.49609375 41.25390625 C-14.83139072 41.42877262 -20.51160133 38.74833652 -26.48046875 33.05078125 C-30.43805362 27.45557505 -31.27832122 21.78820211 -30.48046875 15.05078125 C-28.35005591 8.56691607 -24.47272467 4.22016679 -18.98046875 0.23828125 C-12.9120909 -1.82063266 -6.07465692 -2.32463249 0 0 Z M-20.48046875 14.05078125 C-22.05740462 18.1475631 -21.76926517 21.16740396 -20.41796875 25.30078125 C-18.02272602 28.70048061 -16.27267713 30.29512922 -12.48046875 32.05078125 C-7.49694712 32.49024524 -4.38294664 31.78404453 -0.48046875 28.61328125 C2.56040111 24.71716674 2.91017948 22.22625755 2.87890625 17.37890625 C2.27408847 13.46073889 0.35076368 11.74719309 -2.48046875 9.05078125 C-9.30920453 5.63641336 -16.19802013 8.07055714 -20.48046875 14.05078125 Z "
        fill={color}
        transform="translate(401.48046875,126.94921875)"
      />
      <path
        d="M0 0 C6.63011603 3.32062954 10.07299431 8.22288918 12.38671875 15.1640625 C13.12721863 21.99851218 12.51303987 27.54164835 8.38671875 33.1640625 C3.48917168 38.3865653 -1.09174903 41.16363064 -8.30078125 41.6015625 C-14.844154 41.44530285 -20.75006812 39.16518268 -25.31640625 34.37890625 C-30.08083451 27.93049904 -30.29303757 21.91722518 -29.61328125 14.1640625 C-27.86659198 7.45544066 -23.43596227 3.61941119 -17.61328125 0.1640625 C-11.97371503 -1.71579291 -5.6796946 -1.81682309 0 0 Z M-19.98828125 13.9140625 C-21.27796939 18.55693982 -21.27779011 22.59022572 -19.42578125 26.9765625 C-16.34174115 30.69867986 -13.94084972 32.02182302 -9.11328125 32.5390625 C-4.12847476 32.00497609 -0.82872535 29.95686534 2.38671875 26.1640625 C3.60463855 22.04780189 3.86049617 18.34850606 2.26171875 14.3515625 C-1.18775837 10.32717252 -3.12864009 8.35258836 -8.55078125 7.6640625 C-13.47134015 7.92450796 -16.93760575 10.03138459 -19.98828125 13.9140625 Z "
        fill={color}
        transform="translate(147.61328125,135.8359375)"
      />
      <path
        d="M0 0 C3.02508145 1.73602105 5.41790069 3.83580137 7 7 C7.26928623 11.10661505 7.39058642 13.96609478 5.9375 17.8125 C3.26058429 20.83482419 0.84255931 21.82769377 -3 23 C-7.87655864 22.90246883 -10.57074294 21.42925706 -14 18 C-15.83548272 14.91305179 -16.00207001 13.11592056 -15.9375 9.5 C-14.82276962 5.33833993 -13.28026327 3.72021832 -10 1 C-6.77140153 -0.61429923 -3.49006347 -0.59570657 0 0 Z "
        fill={color}
        transform="translate(195,194)"
      />
      <path
        d="M0 0 C3.00815661 1.28298191 3.80850308 2.31541627 6 5 C7.37105716 9.28358536 7.53517384 12.70431597 5.8125 16.875 C1.69177044 21.70620017 1.69177044 21.70620017 -2.046875 22.35546875 C-6.04029932 22.60338268 -9.16118157 22.39322107 -12.5 20.0625 C-15.31821658 16.61018468 -16.02371308 14.58823849 -16.2421875 10.21875 C-15.85639279 6.68437266 -14.30350467 4.62343588 -12 2 C-7.94779064 -0.70147291 -4.75672036 -0.76172353 0 0 Z "
        fill={color}
        transform="translate(165,72)"
      />
      <path
        d="M0 0 C3.6578218 2.03993908 5.66946174 3.50419262 8 7 C8.72517392 11.50282411 8.60000036 14.99285654 6.25 18.9375 C3.56654006 21.39733828 1.49401489 22.8717125 -2.18359375 23.1875 C-6.55114354 22.8241683 -8.5736543 22.3480373 -12.0625 19.5 C-14.33643673 16.20398021 -14.71617898 13.13569569 -14.49609375 9.1875 C-13.63728553 5.40062909 -11.82558616 3.62845224 -9 1 C-5.64870516 -0.11709828 -3.51689744 -0.34479387 0 0 Z "
        fill={color}
        transform="translate(316,111)"
      />
      <path
        d="M0 0 C3.12068856 1.79273598 4.54965052 3.24259405 6.375 6.3125 C7.21219504 9.91243865 6.89539601 12.41841597 6 16 C4.17660948 18.70567626 2.92542564 20.53728718 0 22 C-3.79415519 22.53118173 -7.46331178 22.85089348 -10.890625 20.953125 C-14.65709007 17.84487712 -15.89170099 16.08299008 -16.375 11.25 C-16.35058775 7.89941932 -15.75601354 6.15105468 -13.625 3.5625 C-9.37586642 -0.58546373 -5.75934713 -0.71742732 0 0 Z "
        fill={color}
        transform="translate(437,84)"
      />
      <path
        d="M0 0 C3.44585933 1.72292966 5.40248927 3.03878709 7.4375 6.3125 C8.4264782 11.03761805 7.7730318 14.60854276 5.4375 18.8125 C2.19099684 21.72602848 -0.6423716 22.81301221 -5 23 C-9.00383078 21.88417831 -11.22180421 20.90371124 -13.9375 17.75 C-15.29209532 14.24398857 -15.40009361 11.73420699 -15 8 C-11.88286709 1.1423076 -7.51611096 -1.30086536 0 0 Z "
        fill={color}
        transform="translate(436,26)"
      />
      <path
        d="M0 0 C2.76101146 1.82109266 4.52100548 3.04201097 6 6 C6.6326744 10.51910283 6.77294479 14.66253178 4.0625 18.5 C0.11317573 21.72393818 -2.95706396 22.40343488 -8 22 C-11.68489434 20.47797842 -14.20815609 18.58368781 -16 15 C-16.70522322 10.41604905 -16.64997503 7.09102952 -14.25 3.0625 C-9.65546806 -1.14915428 -6.10471813 -1.70786757 0 0 Z "
        fill={color}
        transform="translate(251,20)"
      />
      <path
        d="M0 0 C2.76101146 1.82109266 4.52100548 3.04201097 6 6 C6.6326744 10.51910283 6.77294479 14.66253178 4.0625 18.5 C0.11317573 21.72393818 -2.95706396 22.40343488 -8 22 C-11.68489434 20.47797842 -14.20815609 18.58368781 -16 15 C-16.70522322 10.41604905 -16.64997503 7.09102952 -14.25 3.0625 C-9.65546806 -1.14915428 -6.10471813 -1.70786757 0 0 Z "
        fill={color}
        transform="translate(112,20)"
      />
      <path
        d="M0 0 C4.47870656 2.15937638 7.18198188 4.92952939 10 9 C11.81020278 14.43060835 11.70097747 19.09743772 9.3125 24.25 C6.82634676 28.73741845 3.90575302 31.36474899 -1 33 C-7.41263877 33.79730855 -12.08339214 33.24017081 -17.375 29.41015625 C-21.60539755 25.73906607 -22.63515402 21.75446662 -23.05859375 16.33984375 C-22.94322163 11.73265043 -21.70461444 7.82702026 -18.53125 4.42578125 C-12.61861999 -0.63183608 -7.57238339 -0.71362775 0 0 Z M-16 7 C-18.9741633 11.31733382 -19.6486549 14.81076076 -19 20 C-16.28786969 25.03681343 -13.68125916 28.16583048 -8.1875 29.875 C-3.06680421 30.0758116 0.54841031 28.26059105 4.375 24.9375 C7.30114821 21.44863098 7.3414182 18.7485692 7.27734375 14.3203125 C6.84203773 10.67845649 5.43146136 8.68740466 3 6 C-3.80148335 1.56424999 -9.79893364 1.94205951 -16 7 Z "
        fill={color}
        transform="translate(487,42)"
      />
      <path
        d="M0 0 C4.01351108 2.33137776 6.97682932 4.61925677 8.6875 9.046875 C10.01625744 14.49791931 10.01808309 19.06569617 7.78125 24.25390625 C5.59046687 27.84522574 2.43832729 30.17146136 -1.3125 32.046875 C-6.86781248 32.5541715 -11.73080236 32.80947922 -16.8125 30.359375 C-21.53892686 25.98743016 -24.3861683 22.40754474 -24.6875 15.859375 C-24.60943946 10.53391159 -22.82375009 6.9948034 -19.3125 3.046875 C-13.94616748 -1.78827232 -6.65630622 -2.74347756 0 0 Z M-17.6875 7.109375 C-20.61364821 10.59824402 -20.6539182 13.2983058 -20.58984375 17.7265625 C-20.15453773 21.36841851 -18.74396136 23.35947034 -16.3125 26.046875 C-12.24760861 28.69789113 -9.14013217 29.73653674 -4.3125 29.046875 C-0.27991242 27.5217297 2.16537931 25.80479213 4.625 22.234375 C5.86832407 18.50440279 6.17614783 15.95605763 5.6875 12.046875 C2.97536969 7.01006157 0.36875916 3.88104452 -5.125 2.171875 C-10.24569579 1.9710634 -13.86091031 3.78628395 -17.6875 7.109375 Z "
        fill={color}
        transform="translate(80.3125,89.953125)"
      />
      <path
        d="M0 0 C4.28507734 2.95194217 7.87405085 6.03352125 9.515625 11.11328125 C10.37946821 16.54985974 9.64054458 21.19301495 6.8125 25.9375 C3.10137893 30.00206117 -0.47807614 31.91349637 -5.8203125 32.83984375 C-10.92506097 33.05043568 -15.22160035 31.68908773 -19.05078125 28.27734375 C-23.24155819 23.56647038 -24.61964346 19.64845393 -24.52734375 13.3515625 C-23.83727323 8.44968225 -21.28428839 4.78454684 -17.61328125 1.55078125 C-12.05956926 -2.1387477 -6.17992828 -2.05997609 0 0 Z M-19 8.0625 C-20.67106594 12.1082386 -20.86485912 15.62218967 -20.1875 19.9375 C-18.40163657 23.64660097 -16.82526905 25.47771301 -13.5 27.875 C-9.11529812 29.28141381 -5.59911043 29.28051793 -1.1875 27.9375 C2.0169343 25.41486023 4.54461647 22.85641273 5.8125 18.9375 C6.28607835 14.67529488 6.11080613 12.4914971 4.0625 8.6875 C1.46845689 5.51700287 -0.44494924 3.22650842 -4.58984375 2.5390625 C-10.90987341 2.59749113 -14.72232117 3.04729034 -19 8.0625 Z "
        fill={color}
        transform="translate(231.1875,107.0625)"
      />
      <path
        d="M0 0 C7.30837965 6.595367 7.30837965 6.595367 7.765625 11.484375 C7.86342989 16.86364421 7.74286227 20.75398074 4.5625 25.3125 C0.3499815 29.44638483 -2.7717691 31.47144961 -8.6875 31.625 C-13.77687369 31.57177779 -16.56131874 30.81051724 -20.4375 27.3125 C-25.04139916 22.0864523 -25.8007859 19.0050427 -25.7109375 12.140625 C-25.28738508 7.75988283 -24.19431179 5.77618661 -21.4375 2.3125 C-15.04839677 -2.77270461 -7.40694259 -3.63613546 0 0 Z M-19.75 6.5 C-21.91740228 10.11233713 -22.64403931 13.10735971 -22.4375 17.3125 C-20.81577053 21.99749625 -18.66272615 24.71974759 -14.4375 27.3125 C-10.03648834 28.64110729 -7.28196803 28.5257347 -3.1875 26.625 C-0.16665704 24.9071512 2.0078353 23.51328026 3.5625 20.3125 C5.04816947 14.68817987 4.41962535 11.36008812 1.5625 6.3125 C-1.728652 2.73924926 -4.72117604 1.57990919 -9.44921875 1.21484375 C-13.8825172 1.43258925 -16.62836434 3.5471014 -19.75 6.5 Z "
        fill={color}
        transform="translate(260.4375,169.6875)"
      />
      <path
        d="M0 0 C3.71195444 1.88825509 6.34152631 4.49509371 8.19921875 8.2265625 C9.97456446 13.94128477 9.79715505 19.4412698 7.171875 24.7890625 C0.576508 32.09744215 0.576508 32.09744215 -4.3125 32.5546875 C-9.69176921 32.65249239 -13.58210574 32.53192477 -18.140625 29.3515625 C-22.1829238 25.33123271 -24.27146187 21.78868342 -24.390625 16.0390625 C-24.31040647 11.08957931 -23.31124761 7.48745515 -19.67578125 3.91015625 C-13.77819772 -1.15109514 -7.57698239 -2.29521729 0 0 Z M-18.453125 7.6640625 C-20.75082823 11.32336765 -21.64009227 14.06638924 -20.69921875 18.37109375 C-19.19590735 23.00129287 -17.49916681 25.87511829 -13.140625 28.3515625 C-8.93230928 29.52053909 -6.64591305 29.50571818 -2.453125 28.2265625 C1.57417724 25.94695746 4.06274997 24.01064191 5.42578125 19.4609375 C6.30849334 14.59208342 5.71289429 11.53672413 2.859375 7.3515625 C-0.48155115 3.81972628 -3.41437094 2.61973843 -8.15234375 2.25390625 C-12.63830806 2.47423849 -15.37826894 4.58920644 -18.453125 7.6640625 Z "
        fill={color}
        transform="translate(348.140625,42.6484375)"
      />
      <path
        d="M0 0 C3.652579 2.64832224 5.36785711 6.09575883 6.76171875 10.27734375 C6.80126109 12.610342 6.80573607 14.94442564 6.76171875 17.27734375 C5.44171875 17.60734375 4.12171875 17.93734375 2.76171875 18.27734375 C2.66375 17.5296875 2.56578125 16.78203125 2.46484375 16.01171875 C1.63628566 9.95047934 1.63628566 9.95047934 -1.17578125 4.65234375 C-5.22923386 1.95004201 -9.49997638 1.62347559 -14.23828125 2.27734375 C-17.33459238 3.88283841 -19.45613313 5.23447958 -21.55078125 8.02734375 C-22.5844511 11.41026326 -22.88639691 14.75850032 -23.23828125 18.27734375 C-24.55828125 17.94734375 -25.87828125 17.61734375 -27.23828125 17.27734375 C-27.32154493 11.47267606 -26.82607702 7.30610926 -22.98828125 2.77734375 C-16.23174088 -4.02031147 -8.27378255 -4.56764812 0 0 Z "
        fill={color}
        transform="translate(344.23828125,201.72265625)"
      />
      <path
        d="M0 0 C2.81848004 2.3832211 4.04026887 4.93863976 5.54296875 8.3046875 C5.54296875 9.6246875 5.54296875 10.9446875 5.54296875 12.3046875 C4.22296875 12.3046875 2.90296875 12.3046875 1.54296875 12.3046875 C1.17171875 11.2321875 0.80046875 10.1596875 0.41796875 9.0546875 C-1.33805988 5.00925707 -3.42799561 3.24172387 -7.45703125 1.3046875 C-12.63323819 0.65766163 -16.18678983 1.26807138 -20.45703125 4.3046875 C-22.08569088 6.86112847 -23.32340789 9.49730259 -24.45703125 12.3046875 C-25.44703125 12.3046875 -26.43703125 12.3046875 -27.45703125 12.3046875 C-27.16795327 7.48672108 -26.39861464 4.2027424 -23.03515625 0.59765625 C-16.00853588 -5.15930252 -7.44881911 -4.67042663 0 0 Z "
        fill={color}
        transform="translate(459.45703125,207.6953125)"
      />
      <path
        d="M0 0 C0 0.66 0 1.32 0 2 C-5.94 2 -11.88 2 -18 2 C-18 1.34 -18 0.68 -18 0 C-11.63872727 -2.4824479 -6.2522219 -3.03138031 0 0 Z "
        fill={color}
        transform="translate(121,218)"
      />
    </svg>
  );
};

export default HealthBackground;
