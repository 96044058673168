import React from "react";
import Lottie from "lottie-react";
import bhojan from "src/assets/animations/landing-page/bhojan.json";
import run from "src/assets/animations/landing-page/run.json";
import meditation from "src/assets/animations/landing-page/meditation.json";
import sleeping from "src/assets/animations/landing-page/sleeping.json";
import HealthRectangle from "src/assets/images/landing-page/health-info-back.png";
import HealthDownArrow from "src/assets/images/landing-page/health-down-arrow.png";
import classNames from "classnames";

interface Props {
  className: string;
}
const HealthMonitorCard: React.FC<Props> = ({ className }) => {
  return (
    <div className={classNames("pb-8 bg-transparent", className)}>
      <div className="relative pb-4 px-10">
        <div
          className="z-10 bg-gray-900 rounded-t-3xl rounded-b-4xl p-5 border-[15px] border-gray-700 w-fullrelative"
          data-testid="health-monitor-card-container"
        >
          <div className="flex flex-row">
            <div className="flex flex-col w-[80%]">
              <h2
                data-testid="your-health-monitor-txt"
                className="text-white text-2xl font-bold"
              >
                Your health monitor
              </h2>
              <p
                data-testid="monitor-your-health-des-txt"
                className="text-white text-base mt-4"
              >
                Monitor your health and track your progress journey with key
                health metrics
              </p>
            </div>
            <div
              data-testid="health-monitor-icons-container"
              className="flex flex-col justify-between items-center ml-4"
            >
              <div
                data-testid="health-monitor-bhojan-container"
                className="bg-gray-700 rounded-md mt-4 w-10 h-10 flex justify-center items-center"
              >
                <Lottie
                  data-testid="health-monitor-bhojan-icon"
                  animationData={bhojan}
                  loop
                  style={{ width: 40, height: 40 }}
                />
              </div>
              <div
                data-testid="health-monitor-run-container"
                className="bg-gray-700 rounded-md mt-4 w-10 h-10 flex justify-center items-center"
              >
                <Lottie
                  data-testid="health-monitor-run-icon"
                  animationData={run}
                  loop
                  style={{ width: 40, height: 40 }}
                />
              </div>
              <div
                data-testid="health-monitor-meditation-container"
                className="bg-gray-700 rounded-md mt-4 w-10 h-10 flex justify-center items-center"
              >
                <Lottie
                  data-testid="health-monitor-meditation-icon"
                  animationData={meditation}
                  loop
                  style={{ width: 40, height: 40 }}
                />
              </div>
              <div
                data-testid="health-monitor-sleeping-container"
                className="bg-gray-700 rounded-md mt-4 w-10 h-10 flex justify-center items-center"
              >
                <Lottie
                  data-testid="health-monitor-sleeping-icon"
                  animationData={sleeping}
                  loop
                  style={{ width: 40, height: 40 }}
                />
              </div>
            </div>
          </div>
        </div>
        <img
          src={HealthRectangle}
          alt="Health Rectangle"
          className="w-16 h-16 absolute top-12 right-5 -z-10"
        />
        <img
          src={HealthDownArrow}
          alt="Health Down Arrow"
          className="-z-10 w-20 h-8 mx-auto absolute -bottom-3 left-0 right-0"
        />
      </div>
    </div>
  );
};

export default HealthMonitorCard;
