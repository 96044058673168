import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
// import DietSection from "src/components/molecules/landing/DietSection";
// import AssessmentLandingCard from "src/components/molecules/landing/AssessmentLandingCard";
import { RootState } from "src/store";
import { hideLoader, showLoader } from "src/store/slices/loaderSlice";
import {
  healthInsightsRequest,
  healthInsightsUpdate,
} from "src/store/slices/landingSlice";
import DietSection from "src/components/molecules/landing/DietSection";
import AssessmentLandingCard from "src/components/molecules/landing/AssessmentLandingCard";
import HealthBackground from "src/components/atoms/Icons/HealthBackGround";
import HealthMonitorCard from "src/components/molecules/landing/HealthMonitorCard";
import { useNavigate } from "react-router-dom";
import { useUrlParams } from "src/helpers/common";

const HomeHealth = ({ setLoaderHomePage }: any) => {
  const theme = useSelector((state: RootState) => state.theme);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const landingState = useSelector((state: RootState) => state.landing);
  const paramsData = useUrlParams<any>();
  const [exerciseData, setExerciseData] = useState<any>({});
  const [diet, setDiet] = useState({});
  const [numberOfDays, setNumberOfDays] = useState({ flagValue: 7 });
  const [loader, setLoader] = useState(true);
  const [healthInsightsWithIcons, setHealthInsightsWithIcons] = useState({
    goals: [],
    readings: [],
  });

  useEffect(() => {
    if (numberOfDays?.flagValue > 0) {
      getMyHealthInsights();
    }
  }, [
    numberOfDays?.flagValue,
    landingState?.healthInsights?.isHealthInsightsUpdate,
  ]);

  const getMyHealthInsights = async () => {
    dispatch(showLoader());
    new Promise((resolve, reject) => {
      dispatch(
        healthInsightsRequest({
          resolve,
          reject,
          //   isHealthRefresh: isHealthRefresh,
          payload: { homepage: true },
        })
      );
    })
      .then((goalsAndReadings: any) => {
        dispatch(hideLoader());
        dispatch(healthInsightsUpdate({ isHealthInsightsUpdate: false }));
        let exercise: any;
        if (goalsAndReadings?.data?.goal_data?.length) {
          exercise = goalsAndReadings?.data?.goal_data.filter(
            (goal: any) => goal?.goal_name === "Exercise"
          )[0];
          setExerciseData(exercise);
          const diet = goalsAndReadings.data.readings_response;
          setDiet(diet);
        }
        setHealthInsightsWithIcons({
          goals: goalsAndReadings?.data?.goal_data || [],
          readings: goalsAndReadings?.data?.readings_response,
        });
        if (paramsData?.moduleName?.toLowerCase() === "exercise") {
          navigate(`/exercise?goal_id=${exercise?.goal_master_id}`);
        } else if (paramsData?.moduleName?.toLowerCase() === "diet") {
          navigate("/diet");
        }
      })
      .finally(() => {
        setLoader(false);
        setLoaderHomePage(false);
      });
  };

  return (
    <>
      {!loader && (
        <div className="relative">
          <div className="w-full object-contain bg-background-fourth mt-32 rounded-tl-[30px] rounded-tr-4xl relative mb-[-7px] z-10">
            <div className="-z-10 overflow-hidden">
              <HealthBackground
                color={theme.colors["--color-background-fifth"]}
              />
            </div>
            <HealthMonitorCard className="absolute -top-28 z-0" />
          </div>
          {/* Diet and Exercise Section */}
          <div className="z-0 pb-10 flex-1 mt-[5px] bg-background-fourth rounded-bl-[40px] rounded-br-[40px] mb-[16px]">
            {diet && Object.keys(diet).length > 0 && (
              <DietSection diet={diet}></DietSection>
            )}

            <AssessmentLandingCard exerciseData={exerciseData} />
          </div>
        </div>
      )}
    </>
  );
};

export default HomeHealth;
