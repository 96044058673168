import React from 'react';

import Card from 'src/components/atoms/Card/Card';

import NoDailyRoutine from 'src/assets/icons/exercise/no-daily-routine.svg';

interface NoDailyRoutineCardProps {
    text: string;
}

const NoDailyRoutineCard: React.FC<NoDailyRoutineCardProps> = ({ text }) => {

    return (
        <Card className="p-0 mb-2 bg-background rounded-xl">
            <div className=' flex justify-center p-4 items-center flex-col gap-2'>
                <img src={NoDailyRoutine} alt="No Exercise Icon" />
                <div className='text-text-subtitle text-l text-center'>{text}</div>
            </div>
        </Card>
    );
}

export default NoDailyRoutineCard