import React from "react";

interface InputAnimatedV2Props {
  id: string;
  label: string;
  value: string;
  className?: string;
  width?: string;
  height?: string;
  handleOnClick?: () => void;
}

const InputAnimatedV2: React.FC<InputAnimatedV2Props> = ({
  id,
  label,
  value,
  className = "",
  handleOnClick = () => {},
}) => {
  const hasValue = Boolean(value);

  return (
    <div
      className={`relative w-full select-none shadow-lg rounded-2xl ${className}`}
    >
      <div
        id={id}
        className={`block border border-black rounded-2xl px-3.5 w-full text-sm font-medium text-gray-900 ${
          hasValue ? "pt-5 pb-2" : "py-3.5"
        }`}
        onClick={handleOnClick}
      >
        {value || label}
      </div>
      {hasValue && (
        <label
          htmlFor={id}
          className="absolute text-sm text-black dark:text-text-subtitle transition-transform transform -translate-y-4 scale-75 top-[18px] left-3.5 origin-[0]"
        >
          {label}
        </label>
      )}
    </div>
  );
};

export default InputAnimatedV2;
