import queryString from "query-string";
import { useLocation } from "react-router-dom";

const useQueryParams: any = () => {
  const location = useLocation();
  const parsed = queryString.parse(location.search);

  return {
    ...parsed,
  };
};

export default useQueryParams;
