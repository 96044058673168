// src/store/slices/themeSlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ThemeState {
  colors: Record<string, string>;
}

// Initial state for the theme slice
const initialState: ThemeState = {
  colors: {},
};

// Define the theme slice
const themeSlice = createSlice({
  name: "theme",
  initialState,
  reducers: {
    setThemeColors: (state, action) => {
      state.colors = action.payload;
    },
  },
});

// Export actions and reducer
export const { setThemeColors } = themeSlice.actions;

export default themeSlice.reducer;
