import React from "react";
import classNames from "classnames";

interface InputProps {
  name: string;
  className?: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  label?: string;
  helperText?: string;
  isError?: boolean;
  showCrossBtn?: boolean;
  handleCrossBtnClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  autoFocus?: boolean;
}

const Input: React.FC<InputProps> = ({
  name,
  className,
  value,
  onChange,
  placeholder,
  label,
  helperText,
  isError = false,
  showCrossBtn = false,
  handleCrossBtnClick,
  autoFocus = false,
}) => {
  return (
    <div className={classNames("relative flex flex-col space-y-1", className)}>
      {label && (
        <label htmlFor={name} className="text-sm font-medium text-gray-700">
          {label}
        </label>
      )}
      <input
        id={name}
        name={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        className={`z-0 text-sm text-text-title px-4 py-3 border rounded-md focus:outline-none focus:shadow-lg ${
          isError
            ? "border-red-500 focus:ring-red-500"
            : "border-gray-300 focus:ring-blue-500"
        }`}
        autoFocus={autoFocus}
      />
      {showCrossBtn && (
        <button
          className="z-1 absolute px-4 py-3 -top-1 right-0 font-thin"
          onClick={handleCrossBtnClick}
        >
          <i className="text-text-subtitle fa fa-close" />
        </button>
      )}
      {helperText && (
        <p className={`text-xs ${isError ? "text-red-500" : "text-gray-500"}`}>
          {helperText}
        </p>
      )}
    </div>
  );
};

export default Input;
