import React, { useState } from "react";
import classNames from "classnames";

import Spinner from "../Loader/Spinner";

interface Props {
  name?: string;
  variant?: "primary" | "secondary" | "ternary";
  type?: "submit" | "reset" | "button" | undefined;
  className?: string;
  label?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  loading?: boolean;
  disabled?: boolean;
  children?: React.ReactNode;
}

const Button: React.FC<Props> = ({
  name,
  variant = "primary",
  type = "button",
  className,
  label,
  loading = false,
  disabled = false,
  onClick,
  children,
}) => {
  return (
    <button
      name={name}
      type={type}
      className={classNames(
        "px-4 py-3 flex items-center justify-center gap-2 rounded-xl",
        disabled
          ? "bg-gray-300 border border-gray-300 text-gray-500"
          : variant === "primary"
            ? "bg-primary border border-primary text-white"
            : variant === "secondary"
              ? "bg-white border border-primary text-primary"
              : variant === "ternary"
                ? "bg-white border-[1px] font-manrope border-color-dark-blue text-color-dark-blue"
                : "bg-gray-300 border border-gray-300 text-gray-500",

        className
      )}
      onClick={onClick}
      disabled={disabled}
    >
      <p className="text-base font-semibold">{label}</p>
      {children}
      {loading && <Spinner color="#777" size="medium" />}
    </button>
  );
};

export default Button;
