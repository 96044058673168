import React from "react";
import classNames from "classnames";

import { insightsFilterOptions } from "src/constants/insights";

interface Props {
  selectedDateOption: string;
  setSelectedDateOption: (item: string) => void;
}

const DayMonthHeader: React.FC<Props> = ({
  selectedDateOption,
  setSelectedDateOption,
}) => {
  return (
    <div className="p-4 pt-0">
      <div className="w-full flex items-center shadow-lg rounded-xl bg-white overflow-hidden">
        {insightsFilterOptions?.map((option) => {
          const isSelected = option?.value === selectedDateOption;
          return (
            <button
              className={classNames(
                "w-full px-3 py-2 text-sm",
                isSelected
                  ? "border border-primary text-primary bg-background-primary rounded-xl font-semibold"
                  : "bg-white"
              )}
              onClick={() => setSelectedDateOption(option.value)}
            >
              {option?.label}
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default DayMonthHeader;
