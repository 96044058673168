import React from "react";

interface Tab {
    label: string;
    key: string;
}

interface TabSwitcherProps {
    tabs: Tab[];
    activeTab: string;
    setActiveTab: React.Dispatch<React.SetStateAction<string>>;
}

const TabSwitcher: React.FC<TabSwitcherProps> = ({ tabs, activeTab, setActiveTab }) => {
    return (
        <ul className="flex justify-between">
            {tabs.map((tab) => (
                <li
                    key={tab.key}
                    className="flex flex-col flex-1 items-center cursor-pointer"
                    onClick={() => setActiveTab(tab.key)}
                >
                    <div className={`text-base font-bold ${activeTab === tab.key ? "text-primary" : "text-text-subtitle"}`}>
                        {tab.label}
                    </div>
                    {activeTab === tab.key && (
                        <div className="h-0.5 w-28 mt-1 rounded-tl-full rounded-tr-full bg-primary" />
                    )}
                </li>
            ))}
        </ul>
    );
};

export default TabSwitcher;
