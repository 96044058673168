import { all, call, put, takeEvery } from "redux-saga/effects";
import * as actions from "../slices/insightSlice";

import insightsApis from "src/api/insightsApis";

import { common } from "src/types/index";
import { constants } from "src/constants/common";

const updatePatientCaloriesGoal = function* updatePatientCaloriesGoal({
  payload: { resolve, reject, payload },
}: {
  payload: any;
}) {
  try {
    const response: common.ResponseGenerator = yield call(
      insightsApis.updatePatientCaloriesGoal,
      payload
    );
    if (response.code === "-1") {
      // throw new Error();
      // TODO: seesion expire
      throw new Error("Session Expire");
    } else if (response.code === "0") {
      throw new Error(response.code);
    }
    // TODO: confirm action for code 3 : account deleted or account inactive
    resolve(response);
    yield put(actions.updatePatientCaloriesGoalSuccess(response));
  } catch (error) {
    reject(error);
    yield put(
      actions.updatePatientCaloriesGoalError(
        error instanceof Error
          ? error?.message
          : constants.ERROR_MESSAGES.SOMETHING_WENT_WRONG
      )
    );
  }
};

const updateGoalSaga = function* updateGoalSaga({
  payload: { resolve, reject, payload },
}: {
  payload: any;
}) {
  try {
    const response: common.ResponseGenerator = yield call(
      insightsApis.updateGoal,
      payload
    );
    if (response.code === "-1") {
      // throw new Error();
      // TODO: seesion expire
      throw new Error("Session Expire");
    } else if (response.code === "0") {
      throw new Error(response.code);
    }
    yield put(actions.updateGoalSuccess(payload));
    resolve(response);
  } catch (error) {
    reject(error);
    yield put(
      actions.updateGoalFailed(
        error instanceof Error
          ? error?.message
          : constants.ERROR_MESSAGES.SOMETHING_WENT_WRONG
      )
    );
  }
};

export default function* foodDiarySaga() {
  yield all([
    takeEvery(
      actions.updatePatientCaloriesGoalRequest,
      updatePatientCaloriesGoal
    ),
  ]);
  yield all([takeEvery(actions.updateGoalRequest, updateGoalSaga)]);
}
