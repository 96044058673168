import React from "react";

import Card from "src/components/atoms/Card/Card";
import RangeMeter from "src/components/atoms/ProgressRangeMeter";

import RIGHTICON from "../../../../assets/icons/progress/view-more-icon.svg";
import moment from "moment";

interface ProgressResultDetailsCardProps {
  progressTitle: string;
  progressUnit: string;
  vitalBg: string;
  vitalTextColor: string;
  loggedDate: string;
  handleOnclick?: () => void;
  activeRange?: number;
  startRange?: number;
  endRange?: number;
}

const ProgressResultDetailsCard: React.FC<ProgressResultDetailsCardProps> = ({
  progressTitle,
  progressUnit,
  loggedDate,
  handleOnclick,
  startRange = null,
  endRange = null,
  activeRange = null,
}) => {
  const getStatusColor = (value: number, min: number, max: number): { backgroundColor: string, textColor: string } => {
    if (!value) {
      return {
        backgroundColor: "bg-color-background-secondary", // if no value
        textColor: "text-color-text-ternary",
      };

    } else if (value < min || value > max) {
      return {
        backgroundColor: "bg-color-light-red", // Red for out of range
        textColor: "text-color-dark-red",
      };
    } else if (value === min || value === max) {
      return {
        backgroundColor: "bg-background-light-yellow", // Yellow for min or max
        textColor: "text-color-extra-dark-yellow",
      };
    } else {
      return {
        backgroundColor: "bg-color-background-light-green", // Green for within range
        textColor: "text-color-extra-dark-green",
      };
    }
  };
  const value = Number(activeRange);
  const { backgroundColor, textColor } = getStatusColor(value, Number(startRange), Number(endRange));
  return (
    <div className="w-full h-auto">
      <Card className="!rounded-3xl flex flex-col gap-4 p-4">
        <div className="flex justify-between gap-4 items-center">
          <div className="font-semibold text-base font-poppins text-text-primary w-full">{progressTitle}</div>
          <button onClick={handleOnclick}>
            <img src={RIGHTICON} alt="Right Icon" className="w-6 h-6 cursor-pointer" />
          </button>
        </div>
        <div className="flex justify-between gap-4">
          <div className={` ${backgroundColor} h-16 rounded-2xl flex px-3 flex-col w-full justify-center `}>
            <p className={`${textColor} font-poppins font-semibold text-xl`}>{activeRange ? Number(activeRange) : "--"}</p>
            <p className={`${textColor} font-poppins text-xs `}>
              Unit: <span className={`${textColor} text-xs font-poppins font-semibold`}>{progressUnit}</span>
            </p>
          </div>
          <div className="h-16 rounded-2xl w-full items-center flex">
            <RangeMeter leftRange={startRange ? Number(startRange) : null} rightRange={endRange ? Number(endRange) : null} value={activeRange ? Number(activeRange) : null} />
          </div>
        </div>
        <div className="flex justify-between mt-1">
          <div>
            {loggedDate != "" ? (
              <p className="text-text-ternary text-xs font-poppins">
                Logged on <span className="font-semibold font-poppins">{moment(loggedDate, "YYYY-MM-DD HH:mm:ss").format("DD MMM YYYY")}</span>
              </p>
            ) : ""}
          </div>
          <div>
            <p className="text-text-secondary text-xs font-poppins">
              {activeRange && startRange && endRange ? (
                Number(activeRange) < Number(startRange) || Number(activeRange) > Number(endRange) ? (
                  <span>Borderline within range</span>
                ) : Number(activeRange) >= Number(startRange) && Number(activeRange) <= Number(endRange) ? (
                  <span>Within ideal range</span>
                ) : (
                  <span></span>
                )
              ) : (
                <span></span>
              )}
            </p>
          </div>
        </div>
      </Card>
    </div>
  );
};
export default ProgressResultDetailsCard;
