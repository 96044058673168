import CRYPTO from "crypto-js";
import environment from "../constants/environment";

export const encryptData = (data: any): string => {
  try {
    var truncHexKey = CRYPTO.SHA256(environment.API_ENC_KEY ?? "")
      .toString()
      .substr(0, 32); // hex encode and truncate

    var key = CRYPTO.enc.Utf8.parse(truncHexKey);

    var iv = CRYPTO.enc.Utf8.parse(environment.API_ENV_IV ?? "");

    var ciphertext = CRYPTO.AES.encrypt(JSON.stringify(data), key, {
      iv: iv,
      mode: CRYPTO.mode.CBC,
    });

    return ciphertext.toString();
  } catch (error) {
    console.log("[ERROR ENCRYPTING DATA]: ", error);
    return "";
  }
};

export const decryptData = (cipher: string): string => {
  try {
    var truncHexKey = CRYPTO.SHA256(environment.API_ENC_KEY ?? "")
      .toString()
      .substr(0, 32); // hex encode and truncate

    var key = CRYPTO.enc.Utf8.parse(truncHexKey);

    var iv = CRYPTO.enc.Utf8.parse(environment.API_ENV_IV ?? "");

    var decryptedData = CRYPTO.AES.decrypt(cipher, key, {
      iv: iv,
      mode: CRYPTO.mode.CBC,
    });

    return decryptedData.toString(CRYPTO.enc.Utf8);
  } catch (error) {
    console.log("[ERROR DECRYTING DATA]: ", error);
    return "";
  }
};
