import { put, call, takeEvery, all } from "redux-saga/effects";
import { common } from "../../types";
import * as actions from "../slices/landingSlice";
import LandingApi from "src/api/landing";
import { constants } from "src/constants/common";

const HealthDiarySaga = function* HealthDiarySaga({
  payload: { resolve, reject, payload },
}: {
  payload: any;
}) {
  try {
    const response: common.ResponseGenerator = yield call(
      LandingApi.getMyHealthDiaries,
      payload
    );
    if (response.code === "-1") {
      throw new Error("Session Expire");
    } else if (response.code === "0") {
      throw new Error(response.message);
    }
    yield put(actions.healthDiariesSuccess(response));
    resolve(response);
  } catch (error) {
    yield put(
      actions.healthDiariesError(
        error instanceof Error
          ? error?.message
          : constants.ERROR_MESSAGES.SOMETHING_WENT_WRONG
      )
    );
    reject(error);
  }
};

const HealthInsightsSaga = function* HealthInsightsSaga({
  payload: { resolve, reject, payload, isHealthRefresh },
}: {
  payload: any;
}) {
  try {
    const response: common.ResponseGenerator = yield call(
      LandingApi.getMyHealthInsights,
      payload
    );
    if (response?.code === "-1") {
      throw new Error("Session Expire");
    } else if (response?.code === "0") {
      throw new Error(response.message);
    }
    yield put(actions.healthInsightsSuccess(response));
    resolve(response);
  } catch (error) {
    yield put(
      actions.healthInsightsError(
        error instanceof Error
          ? error?.message
          : constants.ERROR_MESSAGES.SOMETHING_WENT_WRONG
      )
    );
    // reject(error);
  }
};

function* landingSaga() {
  yield all([takeEvery(actions.healthDiariesRequest, HealthDiarySaga)]);
  yield all([takeEvery(actions.healthInsightsRequest, HealthInsightsSaga)]);
}

export default landingSaga;
