import React, { memo, useState } from "react";
import moment from "moment";
import { useSelector } from "react-redux";

import ThreeDotMenu from "src/components/atoms/Menus/ThreeDotMenu";

import SuccessIcon from "../../../assets/icons/success.svg";
import EllipseIcon from "../../../assets/icons/ellipse.svg";
import PencilIcon from "../../../assets/icons/pencil.svg";
import TrashIcon from "../../../assets/icons/trash.svg";

import { RootState } from "src/store";

interface FoodDiaryItemComponentProps {
  item: any;
  index: number;
  isLast?: boolean;
  buttonPressEvents: (
    type: "consume" | "abstain" | "edit" | "delete",
    item: any
  ) => void;
  buttonEnable?: boolean;
}

const FoodDiaryItemComponent: React.FC<FoodDiaryItemComponentProps> = ({
  index,
  item,
  isLast,
  buttonPressEvents,
  buttonEnable = true,
}) => {
  const { dietPlan } = useSelector((state: RootState) => state.foodDiary);

  const handelButtonPress = (
    type: "consume" | "abstain" | "edit" | "delete"
  ) => {
    buttonPressEvents(type, item);
  };

  // const handleButtonPress = (
  //   type: "consume" | "abstain" | "edit" | "delete"
  // ) => {
  //   buttonPressEvents(type, item);
  // };

  const menuItems = [
    {
      label: "Edit",
      icon: <img src={PencilIcon} className="w-4 h-4" alt="edit-icon" />,
      onClick: () => handelButtonPress("edit"),
    },
    {
      label: "Delete",
      icon: <img src={TrashIcon} className="w-4 h-4" alt="delete-icon" />,
      onClick: () => handelButtonPress("delete"),
    },
  ];

  return (
    <div
      className="flex items-center justify-between gap-1 my-2 mx-4"
      key={index}
    >
      <div className="flex items-start flex-1">
        {buttonEnable && moment().isAfter(dietPlan.date) ? (
          <button
            className="mt-2"
            onClick={() =>
              handelButtonPress(item?.is_active === "Y" ? "abstain" : "consume")
            }
          >
            {item.is_active === "Y" ? (
              <img
                src={SuccessIcon}
                className="w-7 h-7"
                alt="success-icon-img"
              />
            ) : (
              <img
                src={EllipseIcon}
                className="w-7 h-7"
                alt="ellipse-icon-img"
              />
            )}
          </button>
        ) : null}
        <div className="flex-1 my-1 ml-1">
          <div className="flex items-end">
            <p className="text-sm text-text-title capitalize self-center">
              {item?.food_name}
            </p>
            {item?.type === "manual" ? (
              <p className="bg-gray-200 text-gray-500 text-xs px-3 py-1 rounded-xl mx-1">
                Manual
              </p>
            ) : null}
          </div>
          <p className="text-xs text-text-subtitle">
            {Number(item?.quantity) +
              " " +
              (item?.unit_name || item?.UNIT_NAME)}
          </p>
        </div>
      </div>
      <div className="flex items-center gap-1 relative">
        <p className="text-sm text-text-subtitle my-1 capitalize z-0">
          {" "}
          {Math.round(Number(item?.calories))} Cal
        </p>
        {buttonEnable && moment().isAfter(dietPlan.date) && (
          <ThreeDotMenu items={menuItems} />
        )}
      </div>
    </div>
  );
};

export default memo(FoodDiaryItemComponent);
