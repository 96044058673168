import React from 'react';

interface IconProps {
    width?: number;
    height?: number;
    color?: string;
    strokeColor?: string;
}

const CloseCircleIcon: React.FC<IconProps> = ({
    width = 24,
    height = 24,
    color = "#292D32",
    strokeColor = "#1A1C1E",
}) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_104_3666)">
                <path
                    opacity="0.4"
                    d="M21.5 12C21.5 17.2467 17.2467 21.5 12 21.5C6.75329 21.5 2.5 17.2467 2.5 12C2.5 6.75329 6.75329 2.5 12 2.5C17.2467 2.5 21.5 6.75329 21.5 12Z"
                    fill={color}
                    stroke={strokeColor}
                />
                <path
                    d="M12.7063 11.6464L12.3528 12L12.7063 12.3535L15.0063 14.6535C15.101 14.7483 15.101 14.9117 15.0063 15.0064C14.955 15.0577 14.8944 15.08 14.8299 15.08C14.7653 15.08 14.7047 15.0577 14.6534 15.0064L12.3534 12.7064L11.9999 12.3529L11.6463 12.7064L9.34631 15.0064C9.29502 15.0577 9.23441 15.08 9.16986 15.08C9.10532 15.08 9.04471 15.0577 8.99342 15.0064C8.89868 14.9117 8.89868 14.7483 8.99342 14.6535L11.2934 12.3535L11.647 12L11.2934 11.6464L8.99342 9.34643C8.89868 9.25169 8.89868 9.08828 8.99342 8.99354C9.08815 8.8988 9.25157 8.8988 9.34631 8.99354L11.6463 11.2935L11.9999 11.6471L12.3534 11.2935L14.6534 8.99354C14.7482 8.8988 14.9116 8.8988 15.0063 8.99354C15.101 9.08828 15.101 9.25169 15.0063 9.34643L12.7063 11.6464Z"
                    fill="#F15223"
                    stroke={strokeColor}
                />
            </g>
            <defs>
                <clipPath id="clip0_104_3666">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default CloseCircleIcon;
