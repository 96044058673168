export const EVENT_NAMES = {
  ADD_EXERCISE: "ADD_EXERCISE", //
  TAP_ON_DATA_MORPH: "TAP_ON_DATA_MORPH", //
  EXERCISE_ADDED: "EXERCISE_ADDED",
  PLAY_EXERCISE_VIDEO: "PLAY_EXERCISE_VIDEO",
  EXERCISE_PLAN_DONE: "EXERCISE_PLAN_DONE",
  ADD_DIET: "ADD_DIET",
  TAP_ADD_ICON: "TAP_ADD_ICON",
  TAP_ADD_FOOD: "TAP_ADD_FOOD",
  DIET_PLAN_FOOD_ITEM_CONSUMED: "DIET_PLAN_FOOD_ITEM_CONSUMED",
  SEARCHED_FOOD_ITEM_CONSUMED: "SEARCHED_FOOD_ITEM_CONSUMED",
};

export const SCREENS = {
  EXERCISE_SCREEN: "EXERCISE_SCREEN",
  DIET_SCREEN: "DIET_SCREEN",
};
