import { all, call, put, takeEvery } from "redux-saga/effects";
import * as actions from "../slices/exerciseSlice";
import { common } from "../../types";
import exerciseApis from "src/api/exercise";
import { constants } from "src/constants/common";

const getGoalAndTargetSaga = function* getGoalAndTargetSaga({
  payload: { payload, resolve, reject },
}: {
  payload: any;
}) {
  try {
    const response: common.ResponseGenerator = yield call(
      exerciseApis.getGoalAndTarget,
      payload
    );
    if (response.code === "-1") {
      // throw new Error();
      // TODO: seesion expire
      throw new Error("Session Expire");
    } else if (response.code === "0") {
      throw new Error(response.code);
    }
    yield put(actions.getGoalAndTargetSuccess(response));
    resolve(response);
  } catch (error) {
    yield put(
      actions.getGoalAndTargetFailed(
        error instanceof Error
          ? error?.message
          : constants.ERROR_MESSAGES.SOMETHING_WENT_WRONG
      )
    );
    reject(error);
  }
};

const fetchGraphRecordsSaga = function* fetchGraphRecordsSaga({
  payload: { payload, resolve, reject },
}: {
  payload: any;
}) {
  try {
    const response: common.ResponseGenerator = yield call(
      exerciseApis.fetchGraphRecords,
      payload
    );
    if (response.code === "-1") {
      // throw new Error();
      // TODO: seesion expire
      throw new Error("Session Expire");
    } else if (response.code === "0") {
      throw new Error(response.code);
    }
    yield put(actions.fetchGraphRecordsSuccess(response));
    resolve(response);
  } catch (error) {
    yield put(
      actions.fetchGraphRecordsFailed(
        error instanceof Error
          ? error?.message
          : constants.ERROR_MESSAGES.SOMETHING_WENT_WRONG
      )
    );
    reject(error);
  }
};

export default function* exerciseSaga() {
  yield all([takeEvery(actions.getGoalAndTargetRequest, getGoalAndTargetSaga)]);
  yield all([
    takeEvery(actions.fetchGraphRecordsRequest, fetchGraphRecordsSaga),
  ]);
}
