import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
} from "chart.js";
import annotationPlugin from "chartjs-plugin-annotation";
import Card from "src/components/atoms/Card/Card";
import { useSelector } from "react-redux";
import { RootState } from "src/store";
import classNames from "classnames";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  annotationPlugin // Register the annotation plugin
);

interface BarChartProps {
  className?: string;
  data: Data;
  goalValue: number;
}

type Data = {
  x: Array<string>;
  y: Array<number>;
};

const BarChart: React.FC<BarChartProps> = ({ className, data, goalValue }) => {
  const theme = useSelector((state: RootState) => state.theme);

  const getIntervalValue = (): number => {
    const maxConsumed: number = Math.max(...data?.y);
    const y: number = Math.ceil(
      Math.max(maxConsumed, isNaN(goalValue) ? 0 : goalValue) / 7
    );
    const newY: number = Math.ceil(
      (Math.max(maxConsumed, isNaN(goalValue) ? 0 : goalValue) + 2 * y) / 7
    );
    return newY;
  };

  const getYMaxValue = (): number => {
    const maxConsumed: number = Math.max(...data?.y);

    // Choose the maximum value between the highest y and goal
    const maxValue = Math.max(maxConsumed, goalValue);

    // Add some padding (e.g., 10% of the value) to ensure that the Y-axis has some space
    const padding = maxValue * 0.4;

    // Return the maximum value, including padding
    return Math.ceil(maxValue + padding);
  };

  // Data mapping for bar chart
  const dataNew = {
    labels: data?.x,
    datasets: [
      {
        // label: "Achieved Calories",
        data: data?.y,
        backgroundColor: theme.colors["--color-graph-lines"],
        borderColor: theme.colors["--color-graph-lines"],
        borderWidth: 1,
        barThickness: 10, // Set a fixed bar width (e.g., 20 pixels)
      },
    ],
  };

  // Options for bar chart
  const options: ChartOptions<"bar"> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false, // Hide default legend
      },
      tooltip: {
        enabled: true,
        displayColors: false, // Remove the color square
        callbacks: {
          label: function (context) {
            const value = context.raw as number;
            // Custom message based on your requirement
            // return value > 0
            //   ? `Calories Achieved: ${value.toFixed(0)}`
            //   : "No data available";
            return value.toFixed(0);
          },
          title: function (context) {
            // Custom title for tooltip (e.g., label of the bar)
            // return `Day: ${context[0]?.label}`;
            return "";
          },
        },
      },
      annotation: {
        annotations: {
          goalLine: {
            type: "line",
            yMin: goalValue,
            yMax: goalValue,
            borderColor: theme.colors["--color-graph-lines"],
            borderWidth: 1,
            borderDash: [6, 6], // Dotted line
            label: {
              // @ts-ignore
              enabled: true,
              content: `Goal: ${goalValue}`,
              position: "right",
              backgroundColor: theme.colors["--color-graph-marker"],
              font: {
                size: 12,
              },
              xAdjust: 10, // Adjust label position to the right
            },
          },
        },
      },
    },
    scales: {
      x: {
        display: true,
        grid: {
          color: theme.colors["--color-graph-grid"],
          drawOnChartArea: false,
          drawTicks: false, // Remove the tick marks extending the grid lines
        },
        ticks: {
          font: {
            size: 11,
          },
          color: theme.colors["--color-graph-text"],
        },
      },
      y: {
        position: "right", // Right-aligned Y-axis
        grid: {
          color: theme.colors["--color-graph-grid"],
          // drawBorder: false, // Remove the border line
          drawTicks: false, // Remove the tick marks extending the grid lines
        },
        ticks: {
          font: {
            size: 12,
          },
          color: theme.colors["--color-graph-text"],
          stepSize: 1 * getIntervalValue(), // Adjust based on your intervals
        },
        suggestedMax: getYMaxValue(), // Dynamically set the max value
        beginAtZero: true,
      },
    },
  };

  return (
    <Card
      className={classNames(
        "w-full h-72 p-4 bg-gray-100 rounded shadow-md",
        className
      )}
    >
      {/* @ts-ignore */}
      <Bar data={dataNew} options={options} />
    </Card>
  );
};

export default BarChart;
