import { bindQueryParams } from "src/helpers/common";
import { methods, request } from "./base";

const LandingApi = {
  getMyHealthDiaries: async (payload: object) =>
    await request({
      method: methods.POST,
      route: "/goal_readings/my_health_dairy",
      priv: true,
      payload,
    }),

  getMyHealthInsights: (query?: any) => {
    let route = "/goal_readings/my_health_insights";
    if (query && query?.homepage) {
      route = bindQueryParams("/goal_readings/my_health_insights", query);
    }
    return request({
      route,
      method: methods.GET,
      priv: true,
      //   encrypt: false,
    });
  },
};
export default LandingApi;
