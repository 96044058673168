import React from "react";

interface Props {
  color?: string;
}

const PolygonIcon: React.FC<Props> = ({ color = "#760FB2" }) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 2.3094C18.4752 0.880338 21.5248 0.880338 24 2.3094L33.3205 7.6906C35.7957 9.11966 37.3205 11.7607 37.3205 14.6188V25.3812C37.3205 28.2393 35.7957 30.8803 33.3205 32.3094L24 37.6906C21.5248 39.1197 18.4752 39.1197 16 37.6906L6.67949 32.3094C4.20428 30.8803 2.67949 28.2393 2.67949 25.3812V14.6188C2.67949 11.7607 4.20428 9.11966 6.67949 7.6906L16 2.3094Z"
        fill={color}
      />
      <path
        d="M19.3289 27.76V21.648H13.2169V19.296H19.3289V13.2H21.6809V19.296H27.7769V21.648H21.6809V27.76H19.3289Z"
        fill="white"
      />
    </svg>
  );
};

export default PolygonIcon;
