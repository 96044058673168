import moment from "moment";

export const dateConverstion = (
  date: any,
  dateType: string = "YYYY-MM-DD",
  format: string = "YYYY-MM-DD",
  addTime: boolean = false
) => {
  if (addTime) {
    const currentTime = moment().format("hh:mm:ss");
    const customDate = `${date} ${currentTime}`;
    return moment(customDate, dateType).format(format);
  }
  return moment(date, dateType).format(format);
};
