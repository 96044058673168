import React, { useEffect, useMemo, useState } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

import ProgressCard from "../../components/atoms/ProgressCard/ProgressCard";
import MealsCards from "../../components/molecules/diet/MealsCards/MealsCards";
import ExpandableDivs from "../../components/atoms/Layout/ExpandingLayout";
import Calendar from "../../components/atoms/Calendar";
import SpinnerLoader from "src/components/atoms/Loader/SpinnerLoader";

import { RootState } from "../../store";
import {
  getDietPlanByDateRequest,
  getFoodConsumptionByDateRequest,
  getMealsDetailsRequest,
} from "src/store/slices/foodDiarySlice";
import { Header } from "src/components/molecules/Header";
import sendMessageToParent from "src/helpers/parentMessageHandler";
import { LOCAL_STORAGE } from "src/constants/common";
import { trackEvent } from "src/analytics";
import { EVENT_NAMES, SCREENS } from "src/analytics/events";

const DietPage: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Extract date and mealName from URL
  const queryParams = new URLSearchParams(location.search);
  const dateFromUrl = queryParams.get("date");
  const mealName = queryParams.get("mealName");

  const parsedDate = moment(dateFromUrl, "YYYY-MM-DD", true).isValid()
    ? moment(dateFromUrl, "YYYY-MM-DD").toDate()
    : new Date();

  const [selectedDate, setSelectedDate] = useState<Date>(parsedDate);
  const [isFetchingData, setIsFetchingData] = useState(false);

  const { mealsDetails, dietPlan } = useSelector(
    (state: RootState) => state.foodDiary
  );

  useEffect(() => {
    if (selectedDate) {
      setIsFetchingData(true);
      new Promise((resolve, reject) => {
        dispatch(
          getMealsDetailsRequest({
            resolve,
            reject,
          })
        );
      }).finally(() => {
        setIsFetchingData(false);
      });
      getFoodItemByDate();
    }
  }, [selectedDate]);

  const getFoodItemByDate = () => {
    setIsFetchingData(true);
    const payload = {
      date: moment(selectedDate ?? new Date()).format("YYYY-MM-DD"),
    };
    new Promise((resolve, reject) => {
      dispatch(
        getDietPlanByDateRequest({
          payload,
          resolve,
          reject,
        })
      );
    })
      .then((res: any) => {
        if (Number(res?.code) === 1 || Number(res?.code) === 2) {
          setIsFetchingData(false);
          getFoodConsumptionByDate();
        } else {
          setIsFetchingData(false);
        }
      })
      .catch(() => {
        setIsFetchingData(false);
      })
      .finally(() => {
        setIsFetchingData(false);
      });
  };

  const getFoodConsumptionByDate = () => {
    setIsFetchingData(true);
    const payload = {
      date: moment(selectedDate ?? new Date()).format("YYYY-MM-DD"),
    };
    new Promise((resolve, reject) => {
      dispatch(
        getFoodConsumptionByDateRequest({
          payload,
          resolve,
          reject,
        })
      );
    }).finally(() => {
      setIsFetchingData(false);
    });
  };

  // Scroll to the section specified by mealName or scroll to top after data is loaded
  useEffect(() => {
    if (!isFetchingData && dietPlan?.meals) {
      if (mealName) {
        const section = document.getElementById(mealName);
        if (section) {
          section.scrollIntoView({ behavior: "smooth" });
        } else {
          console.warn(`Section with id "${mealName}" not found`);
        }
      } else {
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
    }
  }, [mealName, isFetchingData, dietPlan]);

  const calories = useMemo(() => {
    const tempTotalCalories = dietPlan?.total_calories ?? 1200;
    return {
      totalCalories: Math.round(tempTotalCalories),
      totalConsumedCalories: Math.round(dietPlan?.total_consume_calories ?? 0),
    };
  }, [dietPlan]);

  const handleDateSelect = (date: Date) => {
    if (!moment(date).isSame(selectedDate, "day")) {
      setSelectedDate(date);
      navigate(`/diet?date=${moment(date).format("YYYY-MM-DD")}`);
    }
  };

  const handleMonthChange = (monthData: { month: number; year: number }) => {
    console.log(`Changed to: ${monthData.month}/${monthData.year}`);
  };

  const onPressBack = async () => {
    const queryParams = await localStorage.getItem(LOCAL_STORAGE.QUERY_PARAMS);
    if (queryParams) {
      const parsedParams = JSON.parse(queryParams);
      if (parsedParams?.moduleName.toLowerCase() === "diet") {
        sendMessageToParent("NAVIGATE_BACK");
      } else {
        navigate("/");
      }
    } else {
      navigate("/");
    }
  };

  const handleClick = () => {
    trackEvent(EVENT_NAMES.TAP_ON_DATA_MORPH, {
      date: selectedDate,
    });
    navigate(
      `/diet/insights?date=${moment(selectedDate ?? new Date()).format(
        "YYYY-MM-DD"
      )}`
    );
  };

  return (
    <div className="bg-background h-full">
      <ExpandableDivs
        showExpandBtn={false}
        component1={
          <div>
            <Header
              title="Diet"
              backButton={true}
              onClickOfBackBtn={onPressBack}
            />
            <Calendar
              selectedDate={selectedDate}
              onSelect={handleDateSelect}
              onChangeMonth={handleMonthChange}
            />
            <ProgressCard
              achievedValue={calories?.totalConsumedCalories}
              goalValue={calories?.totalCalories}
              subText="Calories consumed today!"
              disabled={!moment().isAfter(dietPlan.date)}
              onClick={handleClick}
            />
          </div>
        }
        component2={
          isFetchingData ? (
            <div className="h-full flex items-center justify-center">
              <SpinnerLoader />
            </div>
          ) : (
            <MealsCards data={dietPlan?.meals} />
          )
        }
      />
    </div>
  );
};

export default DietPage;
