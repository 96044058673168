import React from "react";
import { BottomSheet } from "react-spring-bottom-sheet";

import "react-spring-bottom-sheet/dist/style.css";

interface BottomSheetSelectProps {
  open: boolean;
  close: () => void;
  children: React.ReactNode;
  className?: string;
}

const BottomSheetSelect: React.FC<BottomSheetSelectProps> = ({
  open,
  close,
  children,
  className,
}) => {
  return (
    <BottomSheet
      open={open}
      onDismiss={close}
      className={className}
      scrollLocking={false}
    >
      {children}
    </BottomSheet>
  );
};

export default BottomSheetSelect;
