import { all, call, put, takeEvery } from "redux-saga/effects";
import * as actions from "../slices/authSlice";

import commonApis from "src/api/common";

import { common } from "src/types/index";
import { constants } from "src/constants/common";

const AuthenticateUserRequest = function* AuthenticateUserRequest({
  payload: { resolve, reject, payload },
}: {
  payload: any;
}) {
  try {
    const response: common.ResponseGenerator = yield call(
      commonApis.authenticateUser,
      payload
    );
    if (Number(response.code) === -1) {
      throw new Error("Session Expire");
    } else if (Number(response.code) === 0) {
      throw new Error(response.message);
    }
    yield put(actions.authenticateUserSuccess(response));
    resolve(response);
  } catch (error) {
    yield put(
      actions.authenticateUserError(
        error instanceof Error
          ? error?.message
          : constants.ERROR_MESSAGES.SOMETHING_WENT_WRONG
      )
    );
    reject(error);
  }
};

export default function* foodDiarySaga() {
  yield all([
    takeEvery(actions.authenticateUserRequest, AuthenticateUserRequest),
  ]);
}
