// src/store/slices/dietSlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface LoaderState {
  visible: boolean;
}

// Initial state for the diet slice
const initialState: LoaderState = {
  visible: false,
};

// Define the diet slice
const loaderSlice = createSlice({
  name: "loader",
  initialState,
  reducers: {
    showLoader: (state) => {
      state.visible = true;
    },
    hideLoader: (state) => {
      state.visible = false;
    },
  },
});

// Export actions and reducer
export const { showLoader, hideLoader } = loaderSlice.actions;

export default loaderSlice.reducer;
