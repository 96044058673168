import classNames from "classnames";
import React, { ReactNode } from "react";

interface Props {
  children: ReactNode;
  className?: string;
}

const Card: React.FC<Props> = ({ className, children }) => {
  return (
    <div className={classNames("shadow-lg rounded-lg p-2 bg-white", className)}>
      {children}
    </div>
  );
};

export default Card;
