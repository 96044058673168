import React from "react";

import Card from "src/components/atoms/Card/Card";

import NoExerciseIcon from "src/assets/icons/no-exercise.svg";
import Button from "src/components/atoms/Button/Button";

interface NoExerciseCardProps {
  onClick: VoidFunction;
  isButtonDisabled: boolean;
}

const NoExerciseCard: React.FC<NoExerciseCardProps> = ({
  onClick,
  isButtonDisabled,
}) => {
  return (
    <Card className="p-0 mb-2 bg-background rounded-xl">
      <div className=" flex justify-center p-4 items-center flex-col gap-2">
        <img src={NoExerciseIcon} alt="No Exercise Icon" />
        <div className="font-bold text-text-secodary">0 Exercise(s) done</div>
        <div className="text-text-subtitle text-sm">
          Start working out and track your progress here
        </div>
        <Button
          label="Add"
          className="w-full"
          onClick={onClick}
          disabled={isButtonDisabled}
        />
      </div>
    </Card>
  );
};

export default NoExerciseCard;
