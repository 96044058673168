import { bindQueryParams } from "src/helpers/common";
import { methods, request } from "./base";

const dietApis = {
  getMealsDetails: () => {
    const route = "/diet/fetch_meal_detail";
    return request({
      route,
      method: methods.GET,
      priv: true,
      encrypt: false,
    });
  },
  getDietPlanByDate: (payload?: object) => {
    const route = "/diet/patient_diet_plan_food_by_date?is_encrypted=true";
    return request({
      route,
      method: methods.POST,
      priv: true,
      payload,
    });
  },
  getFoodConsumptionByDate: (payload: object) => {
    const route = "/diet/patient_food_consumption_by_date?is_encrypted=true";
    return request({
      route,
      method: methods.POST,
      priv: true,
      payload,
    });
  },
  logFoodItem: (payload: object) => {
    const route = "/diet/patient_food_item_update?is_encrypted=true";
    return request({
      route,
      method: methods.POST,
      priv: true,
      payload,
    });
  },
  getFoodItems: (payload?: object) => {
    const route = "/diet/get_food_items?is_encrypted=true";
    return request({
      route,
      method: methods.POST,
      priv: true,
      payload,
    });
  },
  getFoodDiaryRecentSearchItems: (payload?: any) => {
    const route = bindQueryParams(
      "/search_history/search-query",
      payload?.queryParams
    );
    return request({
      route,
      method: methods.GET,
      priv: true,
    });
  },
  updateRecentSearchItem: (payload: object) => {
    const route = "/search_history/store-search-query";
    return request({
      route,
      method: methods.POST,
      priv: true,
      payload,
    });
  },
  foodNutritionsDetails: (payload: object) => {
    const route = "/diet/food_item_nutritions?is_encrypted=true";
    return request({
      route,
      method: methods.POST,
      priv: true,
      payload,
    });
  },
  caloriesAdherencePatient: (payload: object) => {
    const route = "/diet/calories_adherence_patient?is_encrypted=true";
    return request({
      route,
      method: methods.POST,
      priv: true,
      payload,
    });
  },
};

export default dietApis;
