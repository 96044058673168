// src/store/slices/foodDiarySlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";
import {
  foodDiaryState,
  IDietPlanMealType,
  IFoodItemType,
  IMealOptionType,
  IMealType,
  MealApiType,
  RecentSearchFoodItem,
} from "../../types/foodDiary";
import { common, foodDiary } from "../../types";
import {
  FOOD_NUTRITIONS_DETAILS,
  MEAL_TYPE_FOR_FREE_USER,
  MEAL_TYPE_KEYS,
} from "src/constants/diet";
import { constants } from "src/constants/common";
import { FoodItemsErrorPayload } from "src/types/common";

// Initial state for the foodDiary slice
const initialState: foodDiaryState = {
  isLoading: false,
  dietPlan: {
    error: "",
    isLoading: false,
    data: undefined,
    type: undefined,
    meals: [],
    date: "",
    total_calories: 0,
    total_consume_calories: 0,
    selectedOptions: [],
  },
  mealsDetails: {
    error: "",
    isLoading: false,
    data: [],
  },
  logFoodItem: {
    error: "",
    isLoading: false,
    data: undefined,
  },
  foodItems: {
    error: "",
    isLoading: false,
    data: [],
    title: "",
    isLastPageReached: false,
  },
  recentFoodItems: {
    error: "",
    isLoading: false,
    data: [],
  },
  recentSearchUpdate: {
    error: "",
    isLoading: false,
    data: undefined,
  },
  foodNutritionsDetails: {
    data: undefined,
    error: "",
    isLoading: false,
    nutritionOption: [],
  },
  caloriesAdherence: {
    error: "",
    isLoading: false,
    data: {
      calories_accross_meals: [],
      finalData: [],
      totalCaloriesTarget: "0",
      macronutrition_analysis: {
        carbs_achieved: 0,
        carbs_target: 0,
        fats_achieved: 0,
        fats_target: 0,
        protein_achieved: 0,
        protein_target: 0,
      },
      totalAchievedCalories: 0,
    },
  },
};

// Define the foodDiary slice
const foodDiarySlice = createSlice({
  name: "foodDiary",
  initialState,
  reducers: {
    getMealsDetailsRequest: (
      state: foodDiary.foodDiaryState,
      _action: PayloadAction<any>
    ) => {
      state.mealsDetails.isLoading = true;
      state.mealsDetails.error = "";
    },
    getMealsDetailsSuccess: (
      state: foodDiary.foodDiaryState,
      action: PayloadAction<common.ResponseGenerator>
    ) => {
      const tempMealData: IMealType[] = (action.payload.data ?? []).map(
        (item: MealApiType) => {
          const key =
            MEAL_TYPE_KEYS.find((it) => it.mealName === item.meal_type)?.key ??
            "";
          return {
            ...item,
            start_time: moment(item.default_time, "HH:mm:ss").format(
              "HH:mm:ss"
            ),
            end_time: moment(item.default_time, "HH:mm:ss")
              .add(1, "hour")
              .format("HH:mm:ss"),
            key: key,
            totalCalories: 0,
            default_cal: 0,
            totalConsumeCalories: 0,
          };
        }
      );
      state.mealsDetails.isLoading = false;
      state.mealsDetails.data = tempMealData;
    },
    getMealsDetailsError: (
      state: foodDiary.foodDiaryState,
      { payload: error }: PayloadAction<string>
    ) => {
      state.mealsDetails.isLoading = false;
      state.mealsDetails.error = error;
    },

    getDietPlanByDateRequest: (
      state: foodDiary.foodDiaryState,
      _action: PayloadAction<any>
    ) => {
      state.dietPlan.isLoading = true;
      state.dietPlan.error = "";
    },
    getDietPlanByDateSuccess: (
      state: foodDiary.foodDiaryState,
      action: PayloadAction<common.ResponseGenerator>
    ) => {
      state.dietPlan.isLoading = false;
      state.dietPlan.date = (
        action.payload as unknown as { date: string }
      ).date;
      state.dietPlan.data = action.payload?.data;
      if (Number(action.payload.code) === 1) {
        const tempMealData: IMealType[] = (
          action.payload.data.meals_data ?? []
        ).map((item: IDietPlanMealType) => ({
          ...item,
          key: MEAL_TYPE_KEYS.find((it) => it.mealName === item.meal_type)?.key,
          totalCalories: 0,
          totalConsumeCalories: 0,
        }));
        const tempSelectedOptions = tempMealData.map((meal) => {
          const selectedOption =
            (meal?.options ?? []).find((option) =>
              option?.data?.some((item) => item.is_active === "Y")
            )?.option || "1";
          return {
            meal_types_id: meal.meal_types_id,
            option_id: [selectedOption],
          };
        });

        let meal_total_calories = Number(
          action.payload.data?.total_calories ?? 0
        );
        let meal_consumed_total_calories = 0;

        const modifiedMealArray: IMealType[] = tempMealData.map((item) => {
          const selectedOption = (item?.options ?? []).find((option) =>
            tempSelectedOptions.some(
              (opt) =>
                opt.meal_types_id === item.meal_types_id &&
                opt.option_id.includes(option.option.toString())
            )
          );

          const option_total_calories =
            Number(selectedOption?.totalCalories) || 0;
          const option_consumed_total_calories =
            selectedOption?.data?.reduce(
              (total, i) =>
                total + (i.is_active === "Y" ? Number(i.calories ?? 0) : 0),
              0
            ) || 0;

          meal_consumed_total_calories += option_consumed_total_calories;

          const modifiedOptionsArray: IMealOptionType[] = (
            item?.options ?? []
          ).map((opt) => ({
            ...opt,
            data: opt.data.map((foodItem) => ({
              ...foodItem,
              type: foodItem?.type || "diet_plan",
            })),
          }));

          return {
            ...item,
            options: modifiedOptionsArray,
            totalConsumeCalories: option_consumed_total_calories,
            totalCalories: option_total_calories,
          };
        });
        state.dietPlan.meals = modifiedMealArray ?? [];
        state.dietPlan.total_calories = meal_total_calories;
        state.dietPlan.total_consume_calories = meal_consumed_total_calories;
        state.dietPlan.selectedOptions = [...tempSelectedOptions];
        state.dietPlan.type = "dietPlan";
      } else {
        const tempApiData = action.payload.data;
        state.dietPlan.type = "defaultCalories";

        const modifiedMealArray = state.mealsDetails.data
          ?.filter((item: any) => MEAL_TYPE_FOR_FREE_USER.includes(item.key))
          .map((meal: any) => {
            return {
              ...meal,
              default_cal:
                tempApiData[
                  `default_${
                    meal.key === "evening_snack" ? "snacks" : meal.key
                  }_cal`
                ] || 0,
              meal_type:
                meal.key === "evening_snack" ? "Snack" : meal.meal_type,
            };
          });
        state.dietPlan.meals = [...(modifiedMealArray ?? [])];
        state.dietPlan.total_calories = Number(
          action.payload.data?.default_calories ?? 0
        );
        state.dietPlan.total_consume_calories = modifiedMealArray.reduce(
          (total: any, i: any) => total + Number(i.totalConsumeCalories ?? 0),
          0
        );
      }
    },
    getDietPlanByDateError: (
      state: foodDiary.foodDiaryState,
      { payload: error }: PayloadAction<string>
    ) => {
      state.dietPlan.isLoading = false;
      state.dietPlan.error = error;
    },

    getFoodConsumptionByDateRequest: (
      state: foodDiary.foodDiaryState,
      _action: PayloadAction<any>
    ) => {
      state.dietPlan.isLoading = true;
      state.dietPlan.error = "";
    },
    getFoodConsumptionByDateSuccess: (
      state: foodDiary.foodDiaryState,
      action: PayloadAction<common.ResponseGenerator>
    ) => {
      const dietplanItems: IFoodItemType[] =
        action.payload.data?.diet_plan ?? [];
      const manualItems: IFoodItemType[] = action.payload.data?.manual ?? [];

      if (state.dietPlan?.type === "defaultCalories") {
        const mealsMap: Map<any, any> = new Map();
        manualItems.forEach((item) => {
          const meal = mealsMap.get(item.meal_types_id) || {
            hide_meal: "N",
            ...state.dietPlan.meals.find(
              (meal) => meal.meal_types_id === item.meal_types_id
            ),
            totalConsumeCalories: 0,
            data: [],
          };
          meal.data.push(item);
          meal.totalConsumeCalories = Math.round(
            meal.data
              .filter((i: any) => i?.is_active === "Y")
              .reduce((total: any, i: any) => total + Number(i.calories), 0)
          );
          mealsMap.set(item.meal_types_id, meal);
        });

        state.dietPlan.meals = state.dietPlan.meals.map(
          (meal) =>
            mealsMap.get(meal.meal_types_id) || {
              // @ts-ignore
              hide_meal: "N",
              // @ts-ignore
              totalCalories: 0,
              data: [],
              ...meal,
            }
        );

        state.dietPlan.total_consume_calories = state.dietPlan.meals.reduce(
          (total, i) => total + Number(i.totalConsumeCalories ?? 0),
          0
        );
      } else {
        let mealData: IMealType[] = [...state.dietPlan.meals];
        if (dietplanItems.length) {
          mealData.forEach((meal) => {
            meal.options?.forEach((option) => {
              dietplanItems.forEach((item) => {
                if (
                  item.meal_types_id === meal.meal_types_id &&
                  Number((item as any)?.option ?? item?.option_no) ===
                    Number(option.option)
                ) {
                  const itemIndex = option.data.findIndex(
                    (it) => it.food_item_id === item.food_item_id
                  );
                  itemIndex > -1
                    ? (option.data[itemIndex] = { ...item })
                    : option.data.push(item);
                }
              });
            });
          });

          mealData = mealData.map((meal) => ({
            ...meal,
            options: (meal?.options ?? []).map((otp) => ({
              ...otp,
              totalCalories: otp.data.reduce(
                (total, i) => total + Number(i.calories),
                0
              ),
            })),
          }));
        }

        if (manualItems.length) {
          let selectedOptions = [...state.dietPlan.selectedOptions];
          state.dietPlan.meals = mealData.map((meal) => {
            const foodItemByMeal = manualItems.filter(
              (item) => item.meal_types_id === meal.meal_types_id
            );
            if (foodItemByMeal.length) {
              const totalCalories = foodItemByMeal.reduce(
                (total, i) => total + Number(i.calories),
                0
              );
              const findOptionIndex = selectedOptions.findIndex(
                (opt) => opt.meal_types_id === meal.meal_types_id
              );
              if (
                findOptionIndex > -1 &&
                !selectedOptions[findOptionIndex].option_id.includes("manual")
              ) {
                selectedOptions[findOptionIndex].option_id.push("manual");
              } else {
                selectedOptions.push({
                  meal_types_id: meal.meal_types_id,
                  option_id: ["manual"],
                });
              }
              return {
                ...meal,
                options: [
                  ...(meal?.options ?? []).filter(
                    (opt) => opt.option !== "manual"
                  ),
                  { option: "manual", totalCalories, data: foodItemByMeal },
                ],
              };
            }
            return {
              ...meal,
              options: (meal?.options ?? []).filter(
                (item) => item.option !== "manual"
              ),
            };
          });
          state.dietPlan.selectedOptions = selectedOptions;
        } else {
          state.dietPlan.selectedOptions = state.dietPlan.selectedOptions.map(
            (item) => ({
              ...item,
              option_id: item.option_id.filter((it) => it !== "manual"),
            })
          );
          state.dietPlan.meals = mealData.map((item) => ({
            ...item,
            options: (item?.options ?? []).filter(
              (it) => it.option !== "manual"
            ),
          }));
        }

        let meal_consumed_total_calories = 0;
        const tempSelectedOptions = state.dietPlan.meals.map((meal) => {
          const tempDataOfMeal = (meal?.options ?? []).filter(
            (item) => item?.option !== "manual"
          );
          let selectedOption = tempDataOfMeal[0]?.option;
          for (const option of tempDataOfMeal) {
            if (option.data.some((item) => item.is_active === "Y")) {
              selectedOption = option?.option;
              break;
            }
          }
          const manualItemFound = state.dietPlan.selectedOptions
            .find((item) => item.meal_types_id === meal.meal_types_id)
            ?.option_id.find((otp) => otp === "manual");
          return {
            meal_types_id: meal.meal_types_id,
            option_id: manualItemFound
              ? ["manual", selectedOption]
              : [selectedOption],
          };
        });
        state.dietPlan.selectedOptions = tempSelectedOptions;

        state.dietPlan.meals = state.dietPlan.meals.map((meal) => {
          const selectedMealOption = tempSelectedOptions.find(
            (item) => item.meal_types_id === meal.meal_types_id
          );
          if (selectedMealOption) {
            const options = (meal?.options ?? []).filter((option) =>
              selectedMealOption.option_id.includes(option.option)
            );
            const total_calories = options.reduce(
              (total, option) => total + Number(option.totalCalories),
              0
            );
            const total_consume_calories = options.reduce(
              (totalConsume, option) =>
                totalConsume +
                option.data.reduce(
                  (total, item) =>
                    total +
                    (item.is_active === "Y" ? Number(item.calories) : 0),
                  0
                ),
              0
            );
            meal_consumed_total_calories += Number(total_consume_calories);
            return {
              ...meal,
              totalCalories: total_calories,
              totalConsumeCalories: total_consume_calories,
            };
          }
          return meal;
        });

        state.dietPlan.total_consume_calories = meal_consumed_total_calories;
      }

      state.dietPlan.isLoading = false;
      state.dietPlan.error = "";
    },

    getFoodConsumptionByDateError: (
      state: foodDiary.foodDiaryState,
      { payload: error }: PayloadAction<string>
    ) => {
      state.dietPlan.isLoading = false;
      state.dietPlan.error = error;
    },

    updateSelectedOption: (
      state: foodDiary.foodDiaryState,
      _action: PayloadAction<any>
    ) => {
      // Copy selected options and update based on payload
      const tempSelectedOptions = state.dietPlan.selectedOptions.map(
        (option) => {
          if (option.meal_types_id === _action.payload.meal_types_id) {
            const hasManual = option.option_id.includes("manual");
            option.option_id = hasManual
              ? option.option_id.map((id) =>
                  id === "manual" ? id : _action.payload.option_id.toString()
                )
              : [_action.payload.option_id.toString()];
          }
          return option;
        }
      );

      let meal_consumed_total_calories = 0;

      // Map through meals and update calories based on selected options
      const modifiedMealArray = state.dietPlan.meals.map((meal) => {
        const selectedMealOption = tempSelectedOptions.find(
          (item) => item.meal_types_id === meal.meal_types_id
        );

        if (selectedMealOption) {
          const mealOptions = (meal?.options ?? []).filter((option) =>
            selectedMealOption.option_id.includes(option.option)
          );

          const total_calories = mealOptions.reduce(
            (total, option) => total + Number(option.totalCalories),
            0
          );

          const total_consume_calories = mealOptions.reduce(
            (totalConsume, option) =>
              totalConsume +
              option.data.reduce(
                (total, item) =>
                  total + (item.is_active === "Y" ? Number(item.calories) : 0),
                0
              ),
            0
          );

          meal_consumed_total_calories += total_consume_calories;

          return {
            ...meal,
            totalCalories: total_calories,
            totalConsumeCalories: total_consume_calories,
          };
        }

        return meal;
      });

      state.dietPlan.meals = modifiedMealArray;
      state.dietPlan.total_consume_calories = meal_consumed_total_calories;
      state.dietPlan.selectedOptions = tempSelectedOptions;
      state.dietPlan.type = "dietPlan";
    },

    logFoodItemRequest: (
      state: foodDiary.foodDiaryState,
      action: PayloadAction<any>
    ) => {
      state.logFoodItem.isLoading = true;
      state.logFoodItem.error = "";
    },
    logFoodItemSuccess: (
      state: foodDiary.foodDiaryState,
      action: PayloadAction<common.ResponseGenerator>
    ) => {
      state.logFoodItem.data = action.payload.data;
      const actionType = action.payload.type ?? "";
      const acceptedTypes = ["consume", "abstain", "delete"];

      if (action.payload.code === 1) {
        const data = action.payload.data;
        const checkIsManual = data?.type === "manual";
        const assignOption = checkIsManual
          ? "manual"
          : data?.option_no?.toString();

        let tempMealData = [...state.dietPlan.meals];
        let tempSelectedOptions = [...state.dietPlan.selectedOptions];
        const mealIndex = tempMealData.findIndex(
          (meal) => meal.meal_types_id === data.meal_types_id
        );
        let mealDetails: IMealType | undefined =
          mealIndex > -1 ? { ...tempMealData[mealIndex] } : undefined;

        // Logic for paid users
        if (
          state.dietPlan.data?.health_coach_id &&
          acceptedTypes.includes(actionType)
        ) {
          if (
            ["consume", "abstain"].includes(actionType) &&
            mealIndex > -1 &&
            !!mealDetails
          ) {
            const selectedOptionIndex = (mealDetails?.options ?? []).findIndex(
              (otp) => otp.option === assignOption
            );
            if (selectedOptionIndex > -1) {
              const itemIndex = (mealDetails?.options ?? [])[
                selectedOptionIndex
              ].data.findIndex(
                (item) =>
                  Number(item.food_item_id) === Number(data.food_item_id)
              );
              if (itemIndex > -1) {
                (mealDetails?.options ?? [])[selectedOptionIndex].data[
                  itemIndex
                ] = data;
              }
            }
            tempMealData[mealIndex] = mealDetails;
          } else if (actionType === "delete") {
            const selectedOptionIndex = (mealDetails?.options ?? []).findIndex(
              (otp) => otp.option === assignOption
            );
            if (selectedOptionIndex > -1) {
              const filterItem = (mealDetails?.options ?? [])[
                selectedOptionIndex
              ].data.filter(
                (item) =>
                  Number(item.food_item_id) !== Number(data.food_item_id)
              );
              if (filterItem.length) {
                (mealDetails?.options ?? [])[selectedOptionIndex].data =
                  filterItem;
              } else {
                const findOptionIndex = tempSelectedOptions.findIndex(
                  (item) => item.meal_types_id === data.meal_types_id
                );
                if (
                  findOptionIndex > -1 &&
                  tempSelectedOptions[findOptionIndex].option_id.includes(
                    "manual"
                  )
                ) {
                  tempSelectedOptions[findOptionIndex].option_id =
                    tempSelectedOptions[findOptionIndex].option_id.filter(
                      (item) => item !== "manual"
                    );
                  state.dietPlan.selectedOptions = tempSelectedOptions;
                  if (!!mealDetails) {
                    mealDetails = {
                      ...mealDetails,
                      options: (mealDetails?.options ?? []).filter(
                        (item) => item.option !== "manual"
                      ),
                    };
                  }
                }
              }
              if (!!mealDetails) tempMealData[mealIndex] = mealDetails;
            }
          }

          let meal_consumed_total_calories = 0;
          const updatedMealData = tempMealData.map((meal) => {
            const selectedMealOption = tempSelectedOptions.find(
              (item) => item.meal_types_id === meal.meal_types_id
            );
            let total_calories = 0;
            let total_consume_calories = 0;

            if (selectedMealOption) {
              const options = (meal?.options ?? []).filter((option) =>
                selectedMealOption.option_id.includes(option.option)
              );
              if (options.length) {
                total_calories = options.reduce(
                  (total, option) => total + Number(option.totalCalories),
                  0
                );
                total_consume_calories = options.reduce(
                  (totalConsume, option) =>
                    totalConsume +
                    option.data.reduce(
                      (total, item) =>
                        total +
                        (item.is_active === "Y" ? Number(item.calories) : 0),
                      0
                    ),
                  0
                );
                meal_consumed_total_calories += total_consume_calories;
              }
            }

            return {
              ...meal,
              totalCalories: total_calories,
              totalConsumeCalories: total_consume_calories,
            };
          });

          state.dietPlan.meals = updatedMealData;
          state.dietPlan.total_consume_calories = meal_consumed_total_calories;
        }
        // Logic for free users
        else if (acceptedTypes.includes(actionType)) {
          if (
            ["consume", "abstain"].includes(actionType) &&
            mealIndex > -1 &&
            !!mealDetails
          ) {
            const findItemIndex = (mealDetails?.data ?? []).findIndex(
              (item) => Number(item.food_item_id) === Number(data.food_item_id)
            );
            if (findItemIndex > -1) {
              (mealDetails?.data ?? [])[findItemIndex] = data;
            }
            tempMealData[mealIndex] = mealDetails;
          } else if (actionType === "delete" && !!mealDetails) {
            mealDetails.data = (mealDetails?.data ?? []).filter(
              (item) => Number(item.food_item_id) !== Number(data.food_item_id)
            );
            tempMealData[mealIndex] = mealDetails;
          }

          let meal_consumed_total_calories = 0;
          let meal_total_calories = Number(state.dietPlan.total_calories);

          const updatedMealData: IMealType[] = tempMealData.map((meal) => {
            let total_calories = meal.default_cal;
            let total_consume_calories = 0;

            const consumedItems = (meal?.data ?? []).filter(
              (item) => item.is_active === "Y"
            );
            if (consumedItems.length) {
              total_consume_calories = consumedItems.reduce(
                (total, item) => total + Number(item.calories),
                0
              );
              meal_consumed_total_calories += total_consume_calories;
            }

            return {
              ...meal,
              totalCalories: total_calories ?? 0,
              totalConsumeCalories: total_consume_calories,
            };
          });

          state.dietPlan.meals = updatedMealData;
          state.dietPlan.total_consume_calories = meal_consumed_total_calories;
          state.dietPlan.total_calories = meal_total_calories;
        }
      }
      state.logFoodItem.isLoading = false;
    },
    logFoodItemError: (
      state: foodDiary.foodDiaryState,
      { payload: error }: PayloadAction<string>
    ) => {
      state.logFoodItem.isLoading = false;
      state.logFoodItem.error = error;
    },

    getFoodItemsRequest: (
      state: foodDiary.foodDiaryState,
      _action: PayloadAction<any>
    ) => {
      state.foodItems.isLoading = true;
      state.foodItems.error = "";
      if (
        Number(_action.payload.payload.page) === 1 &&
        state.foodItems.isLastPageReached
      ) {
        state.foodItems.isLastPageReached = false;
      }
    },
    getFoodItemsSuccess: (
      state: foodDiary.foodDiaryState,
      action: PayloadAction<common.ResponseGenerator>
    ) => {
      state.foodItems.isLoading = false;

      if (Number(action.payload?.page) === 1) {
        if (action.payload.code === 1 && action.payload.data?.length > 0) {
          state.foodItems.title =
            constants.SEARCH_FOOD_ITEM_TITLE.SEARCH_RESULT;
          state.foodItems.data = [...action.payload.data];
          state.foodItems.isLastPageReached = action.payload.data.length < 20;
        } else if (
          !action.payload?.food_name ||
          action.payload?.food_name.length === 0
        ) {
          state.foodItems.data = state.recentFoodItems.data;
          state.foodItems.title =
            state.recentFoodItems.data?.length > 0
              ? constants.SEARCH_FOOD_ITEM_TITLE.RECENT_SEARCH
              : "";
        } else {
          state.foodItems.title =
            constants.SEARCH_FOOD_ITEM_TITLE.SEARCH_RESULT;
          state.foodItems.data = [];
        }
      } else if (Number(action.payload?.page) > 1) {
        if (
          (action.payload.data ?? []).length < 20 ||
          Number(action.payload.code) !== 1
        ) {
          state.foodItems.isLastPageReached = true;
        } else {
          state.foodItems.data = [
            ...state.foodItems.data,
            ...action.payload.data,
          ];
        }
      }
    },
    getFoodItemsError: (
      state: foodDiary.foodDiaryState,
      { payload }: PayloadAction<FoodItemsErrorPayload>
    ) => {
      if (payload?.page === 1) {
        state.foodItems.isLoading = false;
        state.foodItems.title = constants.SEARCH_FOOD_ITEM_TITLE.SEARCH_RESULT;
        state.foodItems.data = [];
        state.foodItems.isLastPageReached = true;
        state.foodItems.error = payload?.error;
      } else {
        state.foodItems.isLoading = false;
        state.foodItems.title = constants.SEARCH_FOOD_ITEM_TITLE.SEARCH_RESULT;
        // state.foodItems.data = [];
        state.foodItems.isLastPageReached = true;
        state.foodItems.error = payload?.error;
      }
    },
    foodDiaryRecentSearchItemRequest: (
      state: foodDiary.foodDiaryState,
      action: PayloadAction<any>
    ) => {
      state.recentFoodItems.isLoading = true;
    },
    foodDiaryRecentSearchItemSuccess: (
      state: foodDiary.foodDiaryState,
      action: PayloadAction<common.ResponseGenerator>
    ) => {
      const tempRecentSearchItem = action.payload.data.data.map(
        (item: RecentSearchFoodItem) => {
          return JSON.parse(item.search_query);
        }
      );
      state.recentFoodItems.data = tempRecentSearchItem;
      state.foodItems.data = tempRecentSearchItem;
      state.foodItems.title = constants.SEARCH_FOOD_ITEM_TITLE.RECENT_SEARCH;
      state.recentFoodItems.isLoading = false;
    },
    foodDiaryRecentSearchItemError: (
      state: foodDiary.foodDiaryState,
      { payload: error }: PayloadAction<string>
    ) => {
      state.recentFoodItems.isLoading = false;
      state.recentFoodItems.error = error;
    },

    onClearSearchUpdateFoodItem: (state: foodDiary.foodDiaryState) => {
      state.foodItems.data = [...(state.recentFoodItems?.data ?? [])];
      state.foodItems.title = state.recentFoodItems.data?.length
        ? constants.SEARCH_FOOD_ITEM_TITLE.RECENT_SEARCH
        : "";
      state.foodItems.isLastPageReached = false;
    },

    updateRecentSearchRequest: (
      state: foodDiary.foodDiaryState,
      action: PayloadAction<any>
    ) => {
      state.recentSearchUpdate.isLoading = true;
    },
    updateRecentSearchSuccess: (
      state: foodDiary.foodDiaryState,
      action: PayloadAction<common.ResponseGenerator>
    ) => {
      state.recentSearchUpdate.data = action.payload.data;
      state.recentSearchUpdate.isLoading = false;
    },
    updateRecentSearchError: (
      state: foodDiary.foodDiaryState,
      { payload: error }: PayloadAction<string>
    ) => {
      state.recentSearchUpdate.isLoading = false;
      state.recentSearchUpdate.error = error;
    },

    getFoodNutritionsDetailsRequest: (
      state: foodDiary.foodDiaryState,
      action: PayloadAction<any>
    ) => {
      state.foodNutritionsDetails.isLoading = true;
    },
    getFoodNutritionsDetailsSuccess: (
      state: foodDiary.foodDiaryState,
      action: PayloadAction<common.ResponseGenerator>
    ) => {
      state.foodNutritionsDetails.data = action.payload.data;
      const nutritionsOption = FOOD_NUTRITIONS_DETAILS.map((item) => {
        return {
          ...item,
          value: action.payload.data?.[item.key],
        };
      });
      state.foodNutritionsDetails.nutritionOption = nutritionsOption;
      state.foodNutritionsDetails.isLoading = false;
    },
    getFoodNutritionsDetailsError: (
      state: foodDiary.foodDiaryState,
      { payload: error }: PayloadAction<string>
    ) => {
      state.foodNutritionsDetails.isLoading = false;
      state.foodNutritionsDetails.error = error;
    },

    caloriesAdherenceRequest: (
      state: foodDiary.foodDiaryState,
      action: PayloadAction<any>
    ) => {
      state.caloriesAdherence.isLoading = true;
      state.caloriesAdherence.error = "";
    },
    caloriesAdherenceSuccess: (
      state: foodDiary.foodDiaryState,
      action: PayloadAction<common.ResponseGenerator>
    ) => {
      state.caloriesAdherence.data = action.payload.data;
      state.caloriesAdherence.isLoading = false;
    },
    caloriesAdherenceError: (
      state: foodDiary.foodDiaryState,
      { payload: error }: PayloadAction<string>
    ) => {
      state.caloriesAdherence.isLoading = false;
      state.caloriesAdherence.error = error;
    },
  },
});

// Export actions and reducer
export const {
  getMealsDetailsRequest,
  getMealsDetailsSuccess,
  getMealsDetailsError,

  getDietPlanByDateRequest,
  getDietPlanByDateSuccess,
  getDietPlanByDateError,

  getFoodConsumptionByDateRequest,
  getFoodConsumptionByDateSuccess,
  getFoodConsumptionByDateError,

  updateSelectedOption,

  logFoodItemRequest,
  logFoodItemSuccess,
  logFoodItemError,

  getFoodItemsRequest,
  getFoodItemsSuccess,
  getFoodItemsError,
  onClearSearchUpdateFoodItem,

  //diet search history
  foodDiaryRecentSearchItemRequest,
  foodDiaryRecentSearchItemSuccess,
  foodDiaryRecentSearchItemError,

  updateRecentSearchRequest,
  updateRecentSearchSuccess,
  updateRecentSearchError,

  // getFoodNutritionsDetailsRequest
  getFoodNutritionsDetailsRequest,
  getFoodNutritionsDetailsSuccess,
  getFoodNutritionsDetailsError,

  caloriesAdherenceRequest,
  caloriesAdherenceSuccess,
  caloriesAdherenceError,
} = foodDiarySlice.actions;

export default foodDiarySlice.reducer;
