import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { RootState } from "src/store";

import TextInput from "src/components/atoms/TextInput";
import ExpandableDivs from "src/components/atoms/Layout/ExpandingLayout";
import ProgressBackIcon from "src/components/atoms/ProgressBackIcon/ProgressBackIcon";
import ProgressDetailCard from "src/components/molecules/progress/ProgressDetailCard";
import { progressCategoryRequest, setSelectedProgress } from "src/store/slices/progressSlice";
import { hideLoader, showLoader } from "src/store/slices/loaderSlice";

import SearchIcon from "src/assets/icons/common/search-icon.svg";
import { toast } from "react-toastify";
import SpinnerLoader from "src/components/atoms/Loader/SpinnerLoader";

const ProgressPage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showLoader, setshowLoader] = useState<boolean>(false);

  const { allProgress } = useSelector((state: RootState) => state.progress);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    const payload = {
      page: "1",
      version: "v1"
    };

    new Promise((resolve, reject) => {
      setshowLoader(true);
      dispatch(
        progressCategoryRequest({
          payload,
          resolve,
          reject,
        })
      );
    })
      .then((result) => {
        setshowLoader(false);
      })
      .catch((error) => {
        dispatch(hideLoader());
        toast.error(error);
        setshowLoader(false);
      })
      .finally(() => {
        setshowLoader(false);
      });
  };

  const handleOnclickProgress = (selectedProgress: any) => {
    dispatch(setSelectedProgress(selectedProgress));
    navigate("progress-details");
  };

  const handleClickOfSearch = () => {
    navigate("search-patient-log");
  };

  return (
    <div className="h-screen bg-background-light-blue ">
      <ExpandableDivs
        showExpandBtn={false}
        component1={
          <div>
            <ProgressBackIcon onClick={() => navigate("/")} />
          </div>
        }
        component2={
          <>
            <div>
              <div className="text-8 font-playfair font-medium leading-10 px-4 pt-4 pb-3">Your Progress</div>
              <div className="px-4">
                <TextInput
                  placeholder="Search by vital name"
                  readOnly={false}
                  leftIcon={<img src={SearchIcon} alt="Search Icon" className="w-5 h-5 rounded-2.5" />}
                  onClick={handleClickOfSearch}
                />
              </div>
            </div>
            {!showLoader ?
              (
                <div className="p-4 flex flex-1 flex-col gap-4 overflow-y-auto">
                  {allProgress && allProgress.length > 0 ? (
                    <React.Fragment>
                      {allProgress.map((progress, index) => (
                        <ProgressDetailCard
                          key={index}
                          image={progress.icon_url}
                          progressTitle={progress.category_name}
                          progressSubText={progress.categoryDescription}
                          vitalsLogged={progress.loggedVitals}
                          totalVitals={progress.totalVitals}
                          vitalLogged={`${progress.loggedVitals}/${progress.totalVitals}`}
                          handleOnclick={() => handleOnclickProgress(progress)}
                        />
                      ))}
                    </React.Fragment>
                  ) : (
                    <div className="flex justify-center items-center p-4 text-text-primary">
                      No progress data available
                    </div>
                  )}
                </div>
              ) : (
                <div className="flex justify-center items-center p-4">
                  <SpinnerLoader />
                </div>
              )}
          </>
        }
      />
    </div>
  );
};
export default ProgressPage;