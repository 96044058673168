import { THEME_COLORS } from "../theme/colors";

export const setThemeColorsFromURL = (platform = "default") => {
  const colors = THEME_COLORS[platform] || THEME_COLORS.default;

  Object.entries(colors).forEach(([key, value]) => {
    document.documentElement.style.setProperty(key, value);
  });

  return colors;
};
