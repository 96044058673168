function getMobilePlatform() {
  const userAgent = navigator.userAgent;

  // Check for iOS
  if (/iPad|iPhone|iPod/.test(userAgent)) {
    return "iOS";
  }

  // Check for Android
  if (/android/i.test(userAgent)) {
    return "Android";
  }

  return "Other";
}
export default getMobilePlatform;
