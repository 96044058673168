import React from "react";

type LoaderProps = {
  className?: string;
  size?: number;
  tickness?: number;
};

const SpinnerLoader: React.FC<LoaderProps> = ({
  className = "",
  size = 40,
  tickness = 4,
}) => {
  return (
    <div className={`flex items-center justify-center ${className}`}>
      <div
        className={`animate-spin rounded-full border-t-transparent border-primary w-10 h-10`}
        style={{ width: size, height: size, borderWidth: tickness }}
      ></div>
    </div>
  );
};

export default SpinnerLoader;
