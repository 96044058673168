import ExerciseSection from "../ExerciseSection";

const AssessmentLandingCard = ({ exerciseData }: any) => {
  const redirects = {
    sendToExerciseScreen: () => {
      console.log("Navigating to Exercise Screen...");
    },
  };

  return <ExerciseSection exerciseData={exerciseData} />;
};

export default AssessmentLandingCard;
