// import {FlatList, StyleSheet, Text, TouchableOpacity, View} from 'react-native';
import React, { memo, useMemo } from "react";
import classNames from "classnames";
import { useSelector } from "react-redux";

import { IMealOptionType } from "../../../../types/foodDiary";
import { RootState } from "src/store";

interface DietOptionProps {
  optionData: IMealOptionType[];
  onPressOption?: (option: string) => void;
  meal_type_id: string;
}

const DietOption: React.FC<DietOptionProps> = ({
  optionData,
  onPressOption = (option: string) => {},
  meal_type_id,
}) => {
  const { dietPlan } = useSelector((state: RootState) => state.foodDiary);

  const optionArray = useMemo(() => {
    return customSort(
      optionData?.map((item) => item.option),
      dietPlan.selectedOptions.find(
        (item) => item.meal_types_id === meal_type_id
      )?.option_id ?? []
    );
  }, [optionData, dietPlan]);

  function customSort(arr: string[], selected: string[] = []) {
    let result = [];
    if (arr.includes("manual")) {
      result.push("manual");
      arr = arr.filter((item) => item !== "manual");
    }
    selected.forEach((sel) => {
      if (arr.includes(sel)) {
        result.push(sel);
        arr = arr.filter((item) => item !== sel);
      }
    });
    result = result.concat(arr);
    return result;
  }

  if (!optionArray?.length) return null;
  else
    return (
      <div className="flex items-center gap-2 px-4">
        {optionArray?.map((item: any) => {
          const isOptionSelected = (dietPlan.selectedOptions ?? []).some(
            (opt) =>
              opt.option_id.includes(item) && meal_type_id === opt.meal_types_id
          );
          return (
            <button
              className={classNames(
                "w-16 h-7 rounded-lg flex items-center justify-center border mt-4 my-2",
                isOptionSelected
                  ? "bg-background-primary text-primary border-primary"
                  : "bg-white text-gray-600"
              )}
              disabled={isOptionSelected}
              onClick={() => onPressOption(item)}
            >
              <p className="text-xs">
                {item === "manual" ? "Manual" : "Option " + item}
              </p>
            </button>
          );
        })}
      </div>
    );
};

export default memo(DietOption);
