import { useEffect, useState } from "react";

/**
 * Custom hook for debouncing a value.
 * @param value - The value to debounce.
 * @param delay - The debounce delay in milliseconds.
 * @returns - The debounced value.
 */
const useDebounce = <T>(value: T, delay: number): T => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(timer); // Cleanup the timer on every change
    };
  }, [value, delay]);

  return debouncedValue;
};

export default useDebounce;
