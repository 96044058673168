import React, { ReactElement, useState } from "react";

interface Props {
  component1: ReactElement;
  component2: ReactElement;
  showExpandBtn?: boolean;
  component2ZIndex?: number;
}

const ExpandableDivs: React.FC<Props> = ({
  component1,
  component2,
  showExpandBtn = false,
  component2ZIndex = 0,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className="h-screen flex flex-col">
      {/* First Div */}
      <div
        className={`z-1 flex flex-col justify-between transition-all duration-300 ${
          isExpanded ? "h-2/5" : "h-auto"
        }`}
      >
        {React.cloneElement(component1)}
        {showExpandBtn && (
          <button
            className="w-full py-1 align-bottom"
            onClick={() => setIsExpanded(!isExpanded)}
          >
            <i
              className={`text-text-subtitle fa-solid fa-chevron-${
                isExpanded ? "up" : "down"
              }`}
            ></i>
          </button>
        )}
      </div>

      {/* Second Div */}
      <div className={`z-[${component2ZIndex}] flex-1 overflow-y-auto mt-2 `}>
        {React.cloneElement(component2)}
      </div>
    </div>
  );
};

export default ExpandableDivs;
