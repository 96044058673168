import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { AppDispatch, RootState } from "src/store";
import { useDispatch } from "react-redux";
import moment from "moment";

import Card from "src/components/atoms/Card/Card";
import Button from "src/components/atoms/Button/Button";
import ExpandableDivs from "src/components/atoms/Layout/ExpandingLayout";
import ProgressBackIcon from "src/components/atoms/ProgressBackIcon/ProgressBackIcon";
import ProgressResultDetailsCard from "src/components/molecules/progress/ProgressResultDetailsCard";
import { setSelectedDetailsMasterId, setUpdatedValue, updatePatientReadingsRequest } from "src/store/slices/progressSlice";
import { RightArrowIcon } from "src/components/atoms/Icons/RightArrowIcon";
import { ProgressType } from "src/types/progress";
import { hideLoader, showLoader } from "src/store/slices/loaderSlice";
import { toast } from "react-toastify";
import BottomSheetSelect from "src/components/atoms/BottomSheetSelect";
import TextInputAnimated from "src/components/atoms/TextInputAnimated";

const ProgressResultDetailsPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const { selectedProgress } = useSelector((state: RootState) => state.progress);
  const theme = useSelector((state: RootState) => state.theme);
  const [loggedVitals, setLoggedVitals] = useState<Record<string, boolean>>({});
  const [readingData, setReadingData] = useState({
    id: "",
    readingValue: "",
    unit: ""
  });

  const handleNextOfSelectedProgress = (selectedMasterId: string) => {
    dispatch(setSelectedDetailsMasterId({ selectedProgressMasterId: selectedMasterId }));
    navigate("recorded-data");
  };

  const handleOpenBottomSheet = (id: string) => {
    setLoggedVitals((prev) => ({ ...prev, [id]: true }));
  };

  const handleCloseBottomSheet = (id: string) => {
    setLoggedVitals((prev) => ({ ...prev, [id]: false }));
  };

  const handleChangeOfValue = (e: React.ChangeEvent<HTMLInputElement>, id: string, unit: string) => {
    setReadingData({
      id,
      readingValue: e.target.value,
      unit,
    });
  };

  const onclickOfUpdateValue = async () => {
    const payload = {
      reading_datetime: moment().format("YYYY-MM-DD HH:mm:ss"),
      reading_id: readingData.id,
      reading_value: readingData.readingValue,
      weight_unit: readingData.unit,
    };
    await new Promise((resolve, reject) => {
      dispatch(showLoader())
      dispatch(
        updatePatientReadingsRequest({
          payload,
          resolve,
          reject,
        })
      );
    })
      .then((result: any) => {
        if (result.code == 1) {
          dispatch(hideLoader())
          dispatch(setUpdatedValue(
            {
              master_id: readingData.id,
              value: Number(readingData.readingValue),
              date: moment().format("YYYY-MM-DD HH:mm:ss")
            }
          ))
        }
      })
      .catch((error) => {
        toast.error(error)
        dispatch(hideLoader())
      })
      .finally(() => {
        dispatch(hideLoader())
      });
  }

  return (
    <div className=" bg-background-light-blue h-full">
      <ExpandableDivs
        showExpandBtn={false}
        component1={
          <div>
            <ProgressBackIcon onClick={() => navigate("/progress")} />
          </div>
        }
        component2={
          <div>
            <section className="p-6 flex flex-col justify-center items-center gap-4">
              <Card className="flex !rounded-4xl ">
                <img src={selectedProgress?.icon_url} alt={selectedProgress?.category_name} className="w-23 h-23 object-cover" />
              </Card>
              <div className="flex flex-col gap-1.5 justify-center items-center">
                <p className="font-semibold text-base font-poppins text-text-primary">{selectedProgress?.category_name}</p>
                <p className="text-sm text-text-secodary flex font-poppins justify-center text-center">
                  {selectedProgress?.categoryDescription}
                </p>
              </div>
            </section>


            <section className="p-6 flex flex-col gap-4 overflow-x-hidden">
              {selectedProgress && selectedProgress?.data?.length > 0 &&
                selectedProgress.data.map((categoryReading: ProgressType, index: number) => (
                  <React.Fragment key={index}>
                    {categoryReading.reading_value > 0 ? (
                      <ProgressResultDetailsCard
                        progressTitle={categoryReading.reading_name}
                        progressUnit={categoryReading.unit}
                        vitalBg={categoryReading.color_code}
                        vitalTextColor="text-color-extra-dark-green"
                        loggedDate={categoryReading.reading_datetime}
                        handleOnclick={() => handleNextOfSelectedProgress(categoryReading.readings_master_id)}
                        startRange={categoryReading.minLimit}
                        endRange={categoryReading.maxLimit}
                        activeRange={categoryReading.reading_value}
                      />
                    ) : (
                      <>
                        <section className="">
                          <Card className="p-4 !rounded-3xl flex flex-col gap-4">
                            <div className="flex justify-between">
                              <div className="font-semibold text-base font-poppins text-text-primary">
                                {categoryReading.reading_name}
                              </div>
                              <div className="bg-color-light-red text-color-dark-red rounded-full font-poppins text-xs py-1 px-3">
                                Not Logged
                              </div>
                            </div>
                            <div>
                              <Button
                                label="Log Your Vital"
                                type="button"
                                variant="ternary"
                                className="w-full"
                                onClick={() => handleOpenBottomSheet(categoryReading.readings_master_id)}
                              >
                                <RightArrowIcon color={theme.colors["--color-dark-blue"]} />
                              </Button>
                            </div>
                          </Card>
                        </section>

                        {loggedVitals[categoryReading.readings_master_id] && (
                          <BottomSheetSelect
                            open={loggedVitals[categoryReading.readings_master_id]}
                            close={() => handleCloseBottomSheet(categoryReading.readings_master_id)}
                          >
                            <section className="p-4">
                              <h1 className="text-xl font-bold text-text-secodary">
                                {categoryReading.reading_name}
                              </h1>
                            </section>
                            <hr className="h-0.5 bg-background" />
                            <section className="p-4 flex flex-col gap-3">
                              <div className="flex gap-2">
                                <TextInputAnimated
                                  id="Reading Value"
                                  label="Reading Value"
                                  value={null}
                                  required
                                  readonly={false}
                                  onChange={(e) => handleChangeOfValue(e, categoryReading.readings_master_id, categoryReading.unit)}
                                />
                                {categoryReading.unit && (
                                  <div className="flex items-center justify-center bg-color-extra-light-gray font-poppins text-text-secodary p-2 rounded-md">
                                    {categoryReading.unit}
                                  </div>
                                )}
                              </div>
                              <Button
                                variant="primary"
                                label="Update"
                                name="Update"
                                className="w-full"
                                onClick={onclickOfUpdateValue}
                              />
                            </section>
                          </BottomSheetSelect>
                        )}
                      </>
                    )}
                  </React.Fragment>
                ))}
            </section>
          </div>
        }
      />
    </div>
  );
};
export default ProgressResultDetailsPage;