export const insightsFilterOptions = [
  //! old food diary D
  {
    label: "D",
    value: "1D",
    ticks: 7,
  },
  {
    label: "W",
    value: "7D",
    ticks: 7,
  },
  //! old food diary 30D
  {
    label: "M",
    value: "1M",
    // value: '30D',
    ticks: 5,
  },
  {
    label: "6M",
    value: "6M",
    ticks: 6,
  },
  {
    label: "Y",
    value: "1Y",
    ticks: 12,
  },
];
