import React from "react";

import Card from "src/components/atoms/Card/Card";
import ProgressIcon from "src/components/atoms/Icons/ProgressIcon";

import RIGHTICON from "../../../../assets/icons/progress/view-more-icon.svg";

interface Props {
  image: string;
  progressTitle: string;
  progressSubText: string;
  vitalsLogged?: number;
  totalVitals?: number;
  vitalLogged: number | string;
  handleOnclick?: () => void;
}

const ProgressDetailCard: React.FC<Props> = ({ image, progressTitle, vitalsLogged = 0, totalVitals = 0, progressSubText, vitalLogged, handleOnclick }) => {

  const getBackgroundColor = (vitalsLogged: number, totalVitals: number): { bgColor: string; textColor: string } => {
    if (totalVitals === 0) return { bgColor: "bg-gray-300", textColor: "text-text-primary" }; // Default gray 
    const percentageLogged = (vitalsLogged / totalVitals) * 100;
    if (percentageLogged < 20) {
      return { bgColor: "bg-color-light-red", textColor: "text-color-dark-red" }; // Red background for less than 20%
    } else if (percentageLogged >= 20 && percentageLogged <= 80) {
      return { bgColor: "bg-background-light-yellow", textColor: "text-color-dark-yellow" }; // Yellow background for between 20% and 80%
    } else {
      return { bgColor: "bg-color-background-light-green", textColor: "text-color-dark-green" }; // Green background for more than 80%
    }
  };
  const { bgColor, textColor } = getBackgroundColor(vitalsLogged, totalVitals);
  return (
    <Card className="p-4 bg-background !rounded-3xl">
      <div className="flex flex-col gap-4">
        <div className="flex gap-3 flex-shrink-0">
          <ProgressIcon bgColor="bg-background-ternary" image={image} className="rounded-4.5 w-18 h-18" />
          <div className="flex flex-col gap-1">
            <div className="font-semibold font-poppins text-text-primary text-base">{progressTitle}</div>
            <div className="text-sm text-text-secodary font-poppins">{progressSubText}</div>
          </div>
        </div>
        <div className="flex justify-between items-center">
          <div className={`${bgColor} rounded-3xl px-3 py-1`} >
            <div className={`text-xs ${textColor} font-poppins font-medium leading-4`}>{vitalLogged} vitals logged</div>
          </div>
          <button onClick={handleOnclick}>
            <img src={RIGHTICON} alt="Right Icon" className="w-6 h-6 cursor-pointer" />
          </button>
        </div>
      </div>
    </Card>
  )
}

export default ProgressDetailCard
