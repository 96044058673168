import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'src/store';

import TextInput from 'src/components/atoms/TextInput';
import SearchIcon from "src/assets/icons/common/search-icon.svg";
import ExpandableDivs from 'src/components/atoms/Layout/ExpandingLayout';
import CloseCircleIcon from 'src/components/atoms/Icons/CloseCircleIcon';
import { RightArrowIcon } from 'src/components/atoms/Icons/RightArrowIcon';
import ProgressResultDetailsCard from 'src/components/molecules/progress/ProgressResultDetailsCard';
import { ProgressType } from 'src/types/progress';
import { useDispatch } from 'react-redux';
import { setRedirectFromSearch, setSelectedDetailsMasterId } from 'src/store/slices/progressSlice';

export const SearchProgressDetailsPage = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();

    const [searchTerm, setSearchTerm] = useState('');
    const [filteredLogData, setFilteredLogData] = useState<any>();
    const theme = useSelector((state: RootState) => state.theme);
    const { allProgress } = useSelector((state: RootState) => state.progress);

    useEffect(() => {
        if (searchTerm.length > 0) {
            const filteredlog = allProgress?.flatMap((item) =>
                item.data.filter((patientlog) =>
                    patientlog.reading_name.toLowerCase().includes(searchTerm.toLowerCase())
                )
            );
            setFilteredLogData(filteredlog);
        } else {
            setFilteredLogData("");
        }
    }, [searchTerm, allProgress]);

    const handleSearch = (term: string) => {
        setSearchTerm(term);
    };

    const handleClickOfRightIcon = () => {
        setSearchTerm('');
    };

    const onClickOfBack = () => {
        navigate("/progress");
    };

    const handleClickSelectedProgress = (selectedMasterId: string) => {
        dispatch(setSelectedDetailsMasterId({ selectedProgressMasterId: selectedMasterId }));
        dispatch(setRedirectFromSearch(true))
        navigate("/progress/progress-details/recorded-data");
    };


    return (
        <div className="h-screen bg-background-light-blue ">
            <ExpandableDivs
                showExpandBtn={false}
                component1={
                    <div className='flex bg-white  justify-center px-4 items-center w-full'>
                        <button onClick={onClickOfBack}>
                            <RightArrowIcon className="h-7 w-7 rotate-180" color={theme.colors["--color-text-primary"]} />
                        </button>
                        <TextInput
                            className='w-full pl-4'
                            value={searchTerm}
                            onChange={(e) => handleSearch(e.target.value)}
                            placeholder="Search by vital name"
                            readOnly={false}
                            leftIcon={<img src={SearchIcon} alt="Search Icon" className="w-5 h-5" />}
                            rightIcon={<CloseCircleIcon width={20} height={20} />}
                            onClickOfRightIcon={handleClickOfRightIcon}
                        />
                    </div>
                }
                component2={
                    <>
                        <section className="p-6 flex flex-col gap-4">
                            {filteredLogData && filteredLogData.length > 0 ? (
                                filteredLogData && filteredLogData.map((categoryReading: ProgressType, index: number) => (
                                    <ProgressResultDetailsCard
                                        key={index}
                                        progressTitle={categoryReading.reading_name}
                                        progressUnit={categoryReading.unit}
                                        vitalBg={categoryReading.color_code}
                                        vitalTextColor="text-color-extra-dark-green"
                                        loggedDate={categoryReading.reading_datetime}
                                        handleOnclick={() => handleClickSelectedProgress(categoryReading.readings_master_id)}
                                        startRange={categoryReading.minLimit}
                                        endRange={categoryReading.maxLimit}
                                        activeRange={categoryReading.reading_value}
                                    />
                                ))
                            ) : (<h1 className='flex justify-center items-center font-poppins text-sm text-text-secodary'>Search for Vital Information</h1>)}

                        </section>
                    </>
                }
            />
        </div>
    )
}
