import { methods, request } from "./base";

const insightsApis = {
  updatePatientCaloriesGoal: (payload: object) => {
    const route = "/patient_hc/update_patient_calorie_goal";
    return request({
      route,
      method: methods.POST,
      priv: true,
      payload,
    });
  },
  updateGoal: (payload: object) => {
    const route = "/goal_readings/update_goal_value";
    return request({
      route,
      method: methods.POST,
      priv: true,
      payload,
    });
  },
};

export default insightsApis;
