import React, { useState } from "react";

import HomeHealth from "src/components/organisms/landing/HomeHealth";
import LandingHeader from "src/components/molecules/landing/LandingHeader";
import ProgressCard from "src/components/molecules/landing/ProgressCard";

const HomePage: React.FC = () => {
  const [loader, setLoader] = useState(true);
  return (
    <div className="h-full">
      {!loader && (
        <>
          <LandingHeader className="" />
          <ProgressCard className="" />
        </>
      )}
      <HomeHealth setLoaderHomePage={setLoader} />
    </div>
  );
};

export default HomePage;
