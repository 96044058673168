import Lottie from "lottie-react";
import React from "react";
import { getGreetingMsg } from "src/helpers/common";
import HeroAnimation from "src/assets/animations/landing-page/hero-landing.json";
import { useSelector } from "react-redux";
import { RootState } from "src/store";

interface Props {
  className: string;
}
const LandingHeader: React.FC<Props> = ({ className }) => {
  const { user } = useSelector((state: RootState) => state.auth);
  return (
    <div className="mb-12 relative overflow-hidden">
      <div className="z-10 absolute top-12 left-0 right-0 text-center">
        <p>{getGreetingMsg()}</p>
        <p className="relative z-10 text-lg font-bold text-gray-800">
          {user?.data?.name}
        </p>
      </div>

      <img
        src={require("src/assets/images/landing-page/landing-header.png")}
        alt="Landing Header"
        className="object-cover w-full relative"
      />
      <div className="absolute -bottom-5 right-0 left-0">
        <Lottie animationData={HeroAnimation} loop style={{ width: "100%" }} />
      </div>
    </div>
  );
};

export default LandingHeader;
