import { all, call, put, takeEvery } from "redux-saga/effects";
import * as actions from "../slices/foodDiarySlice";
import { common } from "../../types";
import dietApis from "src/api/diet";
import { constants } from "src/constants/common";

const GetMealsDetailsSaga = function* GetMealsDetailsSaga({
  payload: { resolve, reject },
}: {
  payload: any;
}) {
  try {
    const response: common.ResponseGenerator = yield call(
      dietApis.getMealsDetails
    );
    if (Number(response.code) === -1) {
      throw new Error("Session Expire");
    } else if (Number(response.code) === 0) {
      throw new Error(response.message);
    }
    yield put(actions.getMealsDetailsSuccess(response));
    resolve(response);
  } catch (error) {
    yield put(
      actions.getMealsDetailsError(
        error instanceof Error ? error?.message : "Something went wrong!"
      )
    );
    reject(error);
  }
};

const GetDietPlanByDateSaga = function* GetDietPlanByDateSaga({
  payload: { resolve, reject, payload },
}: {
  payload: any;
}) {
  try {
    const response: common.ResponseGenerator = yield call(
      dietApis.getDietPlanByDate,
      payload
    );
    if (Number(response.code) === -1) {
      throw new Error("Session Expire");
    } else if (Number(response.code) === 0) {
      throw new Error(response.message);
    }
    const tempPayload = {
      ...response,
      ...payload,
    };
    yield put(actions.getDietPlanByDateSuccess(tempPayload));
    resolve(tempPayload);
  } catch (error) {
    yield put(
      actions.getDietPlanByDateError(
        error instanceof Error
          ? error?.message
          : constants.ERROR_MESSAGES.SOMETHING_WENT_WRONG
      )
    );
    reject(error);
  }
};

const GetFoodConsumptionByDateSaga = function* GetFoodConsumptionByDateSaga({
  payload: { resolve, reject, payload },
}: {
  payload: any;
}) {
  try {
    const response: common.ResponseGenerator = yield call(
      dietApis.getFoodConsumptionByDate,
      payload
    );
    if (Number(response.code) === -1) {
      throw new Error("Session Expire");
    } else if (Number(response.code) === 0) {
      throw new Error(response.message);
    }
    yield put(actions.getFoodConsumptionByDateSuccess(response));
    resolve(response);
  } catch (error) {
    yield put(
      actions.getFoodConsumptionByDateError(
        error instanceof Error
          ? error?.message
          : constants.ERROR_MESSAGES.SOMETHING_WENT_WRONG
      )
    );
    reject(error);
  }
};

const LogFoodItemSaga = function* LogFoodItemSaga({
  payload: { resolve, reject, payload },
}: {
  payload: any;
}) {
  try {
    const response: common.ResponseGenerator = yield call(
      dietApis.logFoodItem,
      payload.payload
    );
    if (Number(response.code) === -1) {
      throw new Error("Session Expire");
    } else if (Number(response.code) === 0) {
      throw new Error(response.message);
    }
    const tempResponse = {
      ...response,
      type: payload.type,
      data: {
        ...response.data,
        option_no:
          response?.data?.option_no?.toString() ??
          payload?.extraData?.option_no?.toString() ??
          "manual",
        unit_name:
          response?.data?.unit_name ?? payload?.extraData?.unit_name ?? "",
      },
    };
    yield put(actions.logFoodItemSuccess(tempResponse));
    resolve(tempResponse);
  } catch (error) {
    yield put(
      actions.logFoodItemError(
        error instanceof Error
          ? error?.message
          : constants.ERROR_MESSAGES.SOMETHING_WENT_WRONG
      )
    );
    reject(error);
  }
};

const GetFoodItemsSaga = function* GetFoodItemsSaga({
  payload: { resolve, reject, payload },
}: {
  payload: any;
}) {
  try {
    const response: common.ResponseGenerator = yield call(
      dietApis.getFoodItems,
      payload
    );
    if (Number(response.code) === -1) {
      throw new Error("Session Expire");
    } else if (Number(response.code) === 0) {
      throw new Error(response.message);
    }
    const tempResponse = {
      ...response,
      ...payload,
    };
    yield put(actions.getFoodItemsSuccess(tempResponse));
    resolve(tempResponse);
  } catch (error) {
    yield put(
      actions.getFoodItemsError({
        ...payload,
        error:
          error instanceof Error
            ? error?.message
            : constants.ERROR_MESSAGES.SOMETHING_WENT_WRONG,
      })
    );
    // reject(error);
  }
};

const GetRecentSearchItemSaga = function* GetRecentSearchItemSaga({
  payload: { resolve, reject, payload },
}: {
  payload: any;
}) {
  try {
    const response: common.ResponseGenerator = yield call(
      dietApis.getFoodDiaryRecentSearchItems,
      payload
    );
    if (Number(response.code) === -1) {
      throw new Error("Session Expire");
    } else if (Number(response.code) === 0) {
      throw new Error(response.message);
    }

    yield put(actions.foodDiaryRecentSearchItemSuccess(response));
    resolve(response);
  } catch (error) {
    yield put(
      actions.foodDiaryRecentSearchItemError(
        error instanceof Error
          ? error?.message
          : constants.ERROR_MESSAGES.SOMETHING_WENT_WRONG
      )
    );
    reject(error);
  }
};

const UpdateRecentSearchItemSaga = function* UpdateRecentSearchItemSaga({
  payload: { resolve, reject, payload },
}: {
  payload: any;
}) {
  try {
    const response: common.ResponseGenerator = yield call(
      dietApis.updateRecentSearchItem,
      payload
    );
    if (Number(response.code) === -1) {
      throw new Error("Session Expire");
    } else if (Number(response.code) === 0) {
      throw new Error(response.message);
    }

    yield put(actions.updateRecentSearchSuccess(response));
    resolve(response);
  } catch (error) {
    yield put(
      actions.updateRecentSearchError(
        error instanceof Error
          ? error?.message
          : constants.ERROR_MESSAGES.SOMETHING_WENT_WRONG
      )
    );
    reject(error);
  }
};

const FoodNutritionsDetailsSaga = function* FoodNutritionsDetailsSaga({
  payload: { resolve, reject, payload },
}: {
  payload: any;
}) {
  try {
    const response: common.ResponseGenerator = yield call(
      dietApis.foodNutritionsDetails,
      payload
    );
    if (Number(response.code) === -1) {
      throw new Error("Session Expire");
    } else if (Number(response.code) === 0) {
      throw new Error(response.message);
    }

    yield put(actions.getFoodNutritionsDetailsSuccess(response));
    resolve(response);
  } catch (error) {
    yield put(
      actions.getFoodNutritionsDetailsError(
        error instanceof Error
          ? error?.message
          : constants.ERROR_MESSAGES.SOMETHING_WENT_WRONG
      )
    );
    reject(error);
  }
};

const CaloriesAdherencePatientSaga = function* CaloriesAdherencePatientSaga({
  payload: { resolve, reject, payload },
}: {
  payload: any;
}) {
  try {
    const response: common.ResponseGenerator = yield call(
      dietApis.caloriesAdherencePatient,
      payload
    );
    if (Number(response.code) === -1) {
      throw new Error("Session Expire");
    } else if (Number(response.code) === 0) {
      throw new Error(response.message);
    }

    yield put(actions.caloriesAdherenceSuccess(response));
    resolve(response);
  } catch (error) {
    yield put(
      actions.caloriesAdherenceError(
        error instanceof Error
          ? error?.message
          : constants.ERROR_MESSAGES.SOMETHING_WENT_WRONG
      )
    );
    reject(error);
  }
};

export default function* foodDiarySaga() {
  yield all([takeEvery(actions.getMealsDetailsRequest, GetMealsDetailsSaga)]);
  yield all([
    takeEvery(actions.getDietPlanByDateRequest, GetDietPlanByDateSaga),
  ]);
  yield all([
    takeEvery(
      actions.getFoodConsumptionByDateRequest,
      GetFoodConsumptionByDateSaga
    ),
  ]);
  yield all([takeEvery(actions.getFoodItemsRequest, GetFoodItemsSaga)]);
  yield all([
    takeEvery(
      actions.foodDiaryRecentSearchItemRequest,
      GetRecentSearchItemSaga
    ),
  ]);

  yield all([
    takeEvery(actions.updateRecentSearchRequest, UpdateRecentSearchItemSaga),
  ]);
  yield all([
    takeEvery(
      actions.getFoodNutritionsDetailsRequest,
      FoodNutritionsDetailsSaga
    ),
  ]);
  yield all([takeEvery(actions.logFoodItemRequest, LogFoodItemSaga)]);
  yield all([
    takeEvery(actions.caloriesAdherenceRequest, CaloriesAdherencePatientSaga),
  ]);
}
