import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import InfiteLoader from "../../../assets/animations/infinite-loader.gif";

import { LOADER_TEXT } from "../../../constants/common";
import { RootState } from "../../../store";

const InfiniteLoader: React.FC = () => {
  const { visible } = useSelector((state: RootState) => state.loader);
  const [index, setIndex] = useState(0);

  useEffect(() => {
    if (visible) {
      const indices = Array.from(
        { length: LOADER_TEXT.length },
        (_, index) => index
      );
      for (let i = indices.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [indices[i], indices[j]] = [indices[j], indices[i]];
      }
      setIndex(indices[0]);
    }
  }, [visible]);

  if (!visible) return null;

  return (
    <div className="absolute top-0 left-0 bottom-0 right-0 bg-white">
      <div className="flex flex-col items-center justify-center gap-6 h-full">
        <img src={InfiteLoader} className="w-48 h-48" alt="infite-loader-img" />
        <p className="text-base leading-6 font-medium text-black text-center px-6">
          {LOADER_TEXT[index]}
        </p>
      </div>
    </div>
  );
};

export default InfiniteLoader;
