import moEngageUtils from "./moengage";

const trackEvent = (eventName = "", eventData = {}) => {
  try {
    moEngageUtils.trackEvent(eventName, eventData);
  } catch (error) {
    console.error("Error tracking event:", error);
  }
};

const setUserAttributes = (attributes = {}) => {
  try {
    moEngageUtils.setUserAttributes(attributes);
  } catch (error) {
    console.error("Error tracking event:", error);
  }
};

const logout = () => {
  try {
    moEngageUtils.logout();
  } catch (error) {
    console.error("Error logging out of moengage:", error);
  }
};

export const initAnalytics = () => {
  moEngageUtils.initialize();
};

export { trackEvent, setUserAttributes, logout };
