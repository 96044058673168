import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { trackEvent } from "src/analytics";
import { EVENT_NAMES } from "src/analytics/events";
import exerciseApis from "src/api/exercise";
import SpinnerLoader from "src/components/atoms/Loader/SpinnerLoader";
import Modal from "src/components/atoms/Modal/Modal";
import DailyRoutineCard from "src/components/molecules/exercise/DailyRoutineCard";
import NoDailyRoutineCard from "src/components/molecules/exercise/NoDailyRoutineCard";
import { hideLoader, showLoader } from "src/store/slices/loaderSlice";
import RestDay from "src/assets/icons/exercise/rest-day.svg";

interface DailyRoutineScreenPropsType {
  selectedDate: Date;
  fetchGoalAndTarget: () => Promise<void>;
}

const DailyRoutineScreen: React.FC<DailyRoutineScreenPropsType> = (props) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [routineData, setRoutineData] = useState<any>();
  const [isRefreshData, setIsRefreshData] = useState<boolean>(false);
  const [activeRoutine, setActiveRoutine] = useState<number | null>(null);
  const [selectedExercise, setSelectedExercise] = useState<any>();
  const [difficultyModal, setDifficultyModal] = useState<boolean>(false);
  const [selectDifficulty, setSelectDifficulty] = useState<
    "Easy" | "Difficult"
  >("Difficult");
  const dispatch = useDispatch();
  const { selectedDate } = props;

  useEffect(() => {
    fetchPlanDetails();
  }, [selectedDate]);

  useEffect(() => {
    if (isRefreshData) {
      fetchPlanDetails();
    }
  }, [isRefreshData]);

  const fetchPlanDetails = async () => {
    try {
      const payload = {
        plan_date: moment(selectedDate).format("YYYY-MM-DD"),
      };
      const response = await exerciseApis.fetchPlanDetails(payload);
      if (response.code != 1) {
        throw new Error(response.message || "Internal server error");
      }
      setRoutineData(response.data || []);
      if (response.data.exercise_details?.length > 0) {
        setActiveRoutine(response.data.exercise_details[0].routine_no);
      }

      setIsRefreshData(false);
    } catch (error) {
      console.error("Error fetching daily routine:", error);
      setRoutineData([]);
      setLoading(false);
    } finally {
      dispatch(hideLoader());
      setLoading(false);
    }
  };

  const onPressDone = async (exercise: any) => {
    try {
      setLoading(true);
      const payload = {
        patient_exercise_plans_list_rel_id:
          exercise.patient_exercise_plans_list_rel_id,
        done: exercise.done === "Y" ? "N" : "Y",
        reading_time: moment(new Date()).format("HH:mm:ss"),
        routine_no: exercise.routine_no,
        content_master_id: exercise.content_master_id,
        title: exercise.title,
      };
      const response = await exerciseApis.markExerciseDone(payload);
      if (payload.done === "Y") {
        trackEvent(EVENT_NAMES.EXERCISE_PLAN_DONE, {
          exercise_name: payload.title,
          date: selectedDate,
          routine: exercise.routine_no,
        });
        setSelectedExercise(exercise);
        setDifficultyModal(true);
      }
      if (response.code !== "1") {
        throw new Error(response.message || "Internal server error");
      }
      await props.fetchGoalAndTarget();
      setIsRefreshData(true);
    } catch (err) {
      setLoading(false);
    }
  };

  const handleSubmitDifficulty = async (exercise: any) => {
    try {
      setDifficultyModal(false);
      dispatch(showLoader());
      const payload = {
        patient_exercise_plans_list_rel_id:
          exercise.patient_exercise_plans_list_rel_id,
        difficulty: selectDifficulty,
        routine_no: exercise.routine_no,
        content_master_id: exercise.content_master_id,
      };
      const response = await exerciseApis.markDifficulty(payload);
      if (response.code !== "1") {
        throw new Error(response.message || "Internal server error");
      }
      setIsRefreshData(true);
    } catch (err) {
      dispatch(hideLoader());
    }
  };

  return (
    <section className="p-4">
      {!loading ? (
        routineData && routineData.plan_details ? (
          <>
            {routineData?.exercise_details[0]?.rest_day !== "Y" && (
              <>
                <div className="text-text-primary">
                  {routineData.plan_details.exercise_plan_name}
                </div>
                <div className="text-xs text-text-subtitle">
                  Valid from{" "}
                  {moment(routineData.plan_details.start_date).format(
                    "DD MMM YYYY"
                  )}{" "}
                  to{" "}
                  {moment(routineData.plan_details.end_date).format(
                    "DD MMM YYYY"
                  )}{" "}
                </div>
              </>
            )}

            <div className="flex gap-4 p-2 pl-0">
              {(routineData?.exercise_details || []).map(
                (routine: any) =>
                  routine?.rest_day !== "Y" && (
                    <div
                      key={routine.routine_no}
                      className={`cursor-pointer rounded-xl p-2 px-4 text-xs flex items-center ${
                        activeRoutine?.toString() === routine.routine_no
                          ? "text-white bg-text-primary"
                          : "text-text-primary bg-color-white"
                      }`}
                      onClick={() => {
                        setActiveRoutine(routine.routine_no);
                      }}
                    >
                      Routine {routine.routine_no}
                    </div>
                  )
              )}
            </div>

            {(routineData?.exercise_details || []).map(
              (item: any, index: any) => {
                return (
                  <div key={index}>
                    {item?.rest_day === "Y" ? (
                      <div className="flex flex-1 flex-col items-center justify-center space-y-3 mt-5">
                        <p className="text-gray-500 text-lg leading-6 font-normal">
                          Rest Day
                        </p>
                        <img src={RestDay} />
                      </div>
                    ) : (
                      (item?.exercise_details || []).map(
                        (exercise: any, idx: number) => {
                          const exerciseDescription = (
                            htmlContent: string
                          ): string => {
                            return htmlContent.replace(/<\/?[^>]+(>|$)/g, "");
                          };
                          return (
                            activeRoutine === item.routine_no && (
                              <div key={idx}>
                                <div className="flex items-center justify-between mb-2">
                                  <div className="font-medium">
                                    {exercise.title}
                                  </div>
                                  {selectedDate <= new Date() && (
                                    <div
                                      className="px-2 py-2 bg-white rounded-lg flex items-center justify-end gap-4 border border-[background-secondary]"
                                      onClick={() => onPressDone(exercise)}
                                    >
                                      <div
                                        className={`${
                                          exercise.done === "Y"
                                            ? "bg-ternary"
                                            : "bg-gray-300"
                                        } rounded-full w-4 h-4`}
                                      ></div>
                                      <span
                                        className={`text-xs text-theme_color`}
                                      >
                                        Done
                                      </span>
                                    </div>
                                  )}
                                </div>
                                <DailyRoutineCard
                                  videoSrc={exercise.media.media_url}
                                  exercise={exercise}
                                  setSelectedExercise={setSelectedExercise}
                                  exerciseType={exercise.breathing_exercise}
                                  reps={exercise.reps}
                                  sets={exercise.sets}
                                  restPostSets={`${exercise.rest_post_sets} ${exercise.rest_post_sets_unit}`}
                                  rest={`${exercise.rest_post_exercise} ${exercise.rest_post_exercise_unit}`}
                                  description={exerciseDescription(
                                    exercise.description
                                  )}
                                  locked={false}
                                  videoImage={exercise.media.image_url}
                                  difficulty={exercise.difficulty_level}
                                  done={exercise.done}
                                  setDifficultyModal={setDifficultyModal}
                                  setDifficulty={setSelectDifficulty}
                                />
                                <div className="mt-5" />
                              </div>
                            )
                          );
                        }
                      )
                    )}
                  </div>
                );
              }
            )}
          </>
        ) : (
          <NoDailyRoutineCard
            text={"Your personalized exercise routine is on the way..."}
          />
        )
      ) : (
        <div className="flex justify-center h-[40vh]">
          <SpinnerLoader size={35} />
        </div>
      )}
      {difficultyModal && (
        <Modal onClose={() => setDifficultyModal(false)}>
          <div className="p-2 flex flex-col items-center">
            <div className="w-full border-black border-b-[1px] text-center pb-2">
              {selectedExercise.title}
            </div>
            <div className="mt-2 w-[70%] ">
              <div className="w-full text-primary font-bold text-left">
                Was it difficult or easy ?
              </div>
              <div>
                <div
                  className="flex items-center rounded-[5px] bg-color-extra-light-gray w-[94%] p-2 mt-2"
                  onClick={() => setSelectDifficulty("Difficult")}
                >
                  <div
                    className={`w-5 h-5 rounded-full border-2 flex items-center justify-center ${
                      selectDifficulty === "Difficult"
                        ? `border-primary bg-white`
                        : "border-gray-400"
                    }`}
                  >
                    {selectDifficulty === "Difficult" && (
                      <div className="w-2.5 h-2.5 rounded-full bg-primary"></div>
                    )}
                  </div>
                  <div className="text-left ml-2 text-gray-400">Difficult</div>
                </div>
                <div
                  className="flex items-center rounded-[5px] bg-color-extra-light-gray w-[94%] p-2 mt-2"
                  onClick={() => setSelectDifficulty("Easy")}
                >
                  <div
                    className={`w-5 h-5 rounded-full border-2 flex items-center justify-center ${
                      selectDifficulty === "Easy"
                        ? `border-primary bg-white`
                        : "border-gray-300"
                    }`}
                  >
                    <div
                      className={`w-2.5 h-2.5 rounded-full  ${
                        selectDifficulty === "Easy"
                          ? `bg-primary`
                          : "bg-gray-300"
                      }`}
                    ></div>
                  </div>
                  <div className="text-left font-[400] ml-2 text-gray-400">
                    Easy
                  </div>
                </div>
                <div
                  className="w-[94%] bg-primary rounded-[5px] text-white p-2 text-center mt-2"
                  onClick={() => handleSubmitDifficulty(selectedExercise)}
                >
                  Submit
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </section>
  );
};

export default DailyRoutineScreen;
