import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ExerciseVideo } from "src/types/exercise";

import { common, exercise } from "src/types/index";

const initialState: exercise.ExerciseStateType = {
  exerciseVideos: [],
  goalAndTarget: {
    isLoading: false,
    data: null,
    error: "",
  },
  graphDetails: {
    isLoading: false,
    data: null,
    error: "",
  },
};

const exerciseSlice = createSlice({
  name: "exercise",
  initialState,
  reducers: {
    setExerciseVideos: (
      state: exercise.ExerciseStateType,
      action: PayloadAction<ExerciseVideo[]>
    ) => {
      state.exerciseVideos = action.payload;
    },
    getGoalAndTargetRequest: (
      state: exercise.ExerciseStateType,
      action: PayloadAction<any>
    ) => {
      state.goalAndTarget.isLoading = true;
      state.goalAndTarget.data = null;
      state.goalAndTarget.error = "";
    },
    getGoalAndTargetSuccess: (
      state: exercise.ExerciseStateType,
      action: PayloadAction<common.ResponseGenerator>
    ) => {
      state.goalAndTarget.isLoading = false;
      state.goalAndTarget.data = action.payload.data;
      state.goalAndTarget.error = "";

      // state.loading = false;
      // state.today_achieved = action.payload.data.achieved_value;
      // state.today_goal = action.payload.data.goal_value;
    },
    getGoalAndTargetFailed: (
      state: exercise.ExerciseStateType,
      { payload: error }: PayloadAction<string>
    ) => {
      state.goalAndTarget.isLoading = false;
      state.goalAndTarget.data = null;
      state.goalAndTarget.error = error;
    },
    fetchGraphRecordsRequest: (
      state: exercise.ExerciseStateType,
      action: PayloadAction<any>
    ) => {
      state.graphDetails.isLoading = true;
      state.graphDetails.error = "";
      state.graphDetails.data = null;
    },
    fetchGraphRecordsSuccess: (
      state: exercise.ExerciseStateType,
      action: PayloadAction<common.ResponseGenerator>
    ) => {
      state.graphDetails.isLoading = false;
      state.graphDetails.data = action.payload.data;
      state.graphDetails.error = "";
    },
    fetchGraphRecordsFailed: (
      state: exercise.ExerciseStateType,
      { payload: error }: PayloadAction<string>
    ) => {
      state.graphDetails.isLoading = false;
      state.graphDetails.error = error;
      state.graphDetails.data = null;
    },
  },
});

export const {
  setExerciseVideos,

  getGoalAndTargetRequest,
  getGoalAndTargetSuccess,
  getGoalAndTargetFailed,

  fetchGraphRecordsRequest,
  fetchGraphRecordsSuccess,
  fetchGraphRecordsFailed,
} = exerciseSlice.actions;

export default exerciseSlice.reducer;
