import React from "react";
import Picker from "react-mobile-picker";

interface PickerProps {
  selections: { [key: string]: string[] }; // Options for each column
  value: { [key: string]: string }; // Current value of each column
  onChange: (newValue: { [key: string]: string }) => void; // Callback for value change
  className?: string; // Custom styling class
}

const WeelPicker: React.FC<PickerProps> = ({
  selections,
  value,
  onChange,
  className = "",
}) => {
  const handlePickerChange = (newValue: { [key: string]: string }) => {
    onChange(newValue);
  };

  return (
    <div className={`weel-picker ${className}`}>
      <Picker
        value={value}
        onChange={handlePickerChange}
        wheelMode="natural"
        itemHeight={50}
      >
        {Object.keys(selections).map((columnName) => (
          <Picker.Column key={columnName} name={columnName}>
            {selections[columnName].map((option) => (
              <Picker.Item
                key={option}
                value={option}
                className={`${
                  value[columnName] === option
                    ? "text-text-primary text-2xl font-medium"
                    : "text-text-subtitle text-xl font-thin"
                }`}
              >
                {option}
              </Picker.Item>
            ))}
          </Picker.Column>
        ))}
      </Picker>
    </div>
  );
};

export default WeelPicker;
