import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  progressRedingsResponse,
  ProgressResponce,
  ProgressState,
  ReadingUpdateResponse,
  selectedProgress,
  selectedProgressMasterId,
  updatedValue,
} from "src/types/progress";

const progressInitialState: ProgressState = {
  isLoading: false,
  error: "",
  allProgress: [],
  progressReadings: [],
  selectedProgress: undefined,
  selectedProgressMasterId: "",
  ReadingUpdateResponseData: null,
  redirectFromSearch: false,
};

const progressSlice = createSlice({
  name: "progress",
  initialState: progressInitialState,
  reducers: {
    setRedirectFromSearch(state, action: PayloadAction<boolean>) {
      state.redirectFromSearch = action.payload;
    },
    setUpdatedValue(state, action: PayloadAction<updatedValue>) {
      if (state.selectedProgress && state.selectedProgress.data) {
        state.selectedProgress.data = state.selectedProgress.data.map((progress) =>
          progress.readings_master_id === action.payload.master_id
            ? { ...progress, reading_value: action.payload.value, reading_datetime: action.payload.date }
            : progress
        );
      }
    },
    setSelectedProgress: (state, action: PayloadAction<selectedProgress>) => {
      state.selectedProgress = action.payload;
    },
    setSelectedDetailsMasterId: (state, action: PayloadAction<selectedProgressMasterId>) => {
      state.selectedProgressMasterId = action.payload.selectedProgressMasterId;
    },
    progressCategoryRequest: (state: ProgressState, action: PayloadAction<any>) => {
      state.isLoading = true;
      state.error = "";
    },
    progressCategorySuccess: (state: ProgressState, action: PayloadAction<ProgressResponce>) => {
      state.allProgress = action.payload.data;
      state.isLoading = true;
    },
    progressCategoryError: (state: any, { payload: error }: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = error;
    },
    progressRedingLogsRequest: (state: ProgressState, action: PayloadAction<any>) => {
      state.isLoading = action.payload;
      state.error = "";
    },
    progressRedingLogsSuccess: (state: ProgressState, action: PayloadAction<progressRedingsResponse>) => {
      state.isLoading = true;
      state.progressReadings = action.payload.data;
    },
    progressRedingLogsError: (state: any, { payload: error }: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = error;
    },
    updatePatientReadingsRequest: (state: ProgressState, action: PayloadAction<any>) => {
      state.isLoading = true;
      state.error = "";
    },
    updatePatientReadingsSuccess: (state: ProgressState, action: PayloadAction<ReadingUpdateResponse>) => {
      state.isLoading = true;
      state.ReadingUpdateResponseData = action.payload.data;
    },
    updatePatientReadingsError: (state: any, { payload: error }: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = error;
    },
  },
});

export default progressSlice.reducer;

export const {
  setRedirectFromSearch,
  setUpdatedValue,
  progressCategoryRequest,
  progressCategorySuccess,
  progressCategoryError,
  setSelectedProgress,
  setSelectedDetailsMasterId,
  progressRedingLogsRequest,
  progressRedingLogsSuccess,
  progressRedingLogsError,
  updatePatientReadingsRequest,
  updatePatientReadingsSuccess,
  updatePatientReadingsError,
} = progressSlice.actions;
