import React from 'react';

import Card from '../../../atoms/Card/Card';

type ExerciseDescriptionCardProps = {
    icon: string;
    title: string;
    date: string;
    duration: string;
    intensity: string;
};

const ExerciseDescriptionCard: React.FC<ExerciseDescriptionCardProps> = (props) => {

    const { icon, title, date, duration, intensity } = props;

    return (
        <Card className='flex flex-row gap-3'>
            <div className='flex items-start'>
                <img src={icon} alt="Exercise Icon" className='w-9 bg-background-secondary rounded-full justify-center items-center' />
            </div>
            <div className='flex flex-col'>
                <h1 className='font-bold text-text-primary text-l'>{title}</h1>
                <p className='text-text-subtitle text-sm'>{date}</p>
                <p className='text-sm text-text-subtitle'>Duration: <span>{duration}</span></p>
                <p className='text-sm text-text-subtitle'>Intensity: <span>{intensity}</span></p>
            </div>
        </Card>
    );
};

export default ExerciseDescriptionCard;