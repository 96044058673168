export const MEAL_TYPE_KEYS = [
  {
    mealName: "Breakfast",
    key: "breakfast",
  },
  {
    mealName: "Morning Snack",
    key: "morning_snack",
  },
  {
    mealName: "Lunch",
    key: "lunch",
  },
  {
    mealName: "Evening Snack",
    key: "evening_snack",
  },
  {
    mealName: "Dinner",
    key: "dinner",
  },
];

export const MEAL_TYPE_FOR_FREE_USER = [
  "breakfast",
  "lunch",
  "evening_snack",
  "dinner",
];

export const FOOD_NUTRITIONS_DETAILS = [
  {
    key: "protein",
    title: "Protein",
    value: "0",
  },
  {
    key: "fats",
    title: "Fats",
    value: "0",
  },
  {
    key: "carbs",
    title: "Carbs",
    value: "0",
  },
  {
    key: "fiber",
    title: "Fibers",
    value: "0",
  },
];
