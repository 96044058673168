import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import HomePage from "./HomePage";
import DietPage from "./DietPage";
import ExercisePage from "./ExercisePage";
import InvalidPage from "./InvalidPage";
import SearchFoodPage from "./SearchFoodPage";
import SearchFoodResultPage from "./SearchFoodResultPage";
import ExerciseMoreVideoPage from "./ExerciseMoreVideoPage";
import ProgressResultDetailsPage from "./ProgressDetailsPage";
import ProgressRecordedDataPage from "./ProgressRecordedDataPage";
import ProgressPage from "./ProgressPage";
import DietInsightsPage from "./DietInsightsPage";
import ExerciseInsightsPage from "./ExerciseInsightsPage";
import AuthUser from "src/components/organisms/AuthUser";

import InfiniteLoader from "../components/atoms/Loader/InfiniteLoader";

import "../styles/main.css";
import "react-circular-progressbar/dist/styles.css";
import "react-toastify/dist/ReactToastify.css";
import { SearchProgressDetailsPage } from "./SearchProgressDetailsPage";

const AppRoutes: React.FC = () => {
  return (
    <div className="">
      <div className="relative max-w-lg mx-auto h-screen">
        <Router>
          <Routes>
            <Route path="/" element={<AuthUser component={HomePage} />} />
            <Route path="/diet" element={<AuthUser component={DietPage} />} />
            <Route
              path="/diet/search-food"
              element={<AuthUser component={SearchFoodPage} />}
            />
            <Route
              path="/diet/search-food-result"
              element={<AuthUser component={SearchFoodResultPage} />}
            />
            <Route
              path="/diet/insights"
              element={<AuthUser component={DietInsightsPage} />}
            />
            <Route
              path="/exercise"
              element={<AuthUser component={ExercisePage} />}
            />
            <Route
              path="/exercise/insights"
              element={<AuthUser component={ExerciseInsightsPage} />}
            />
            <Route
              path="/exercise/more-videos"
              element={<AuthUser component={ExerciseMoreVideoPage} />}
            />
            <Route
              path="/progress"
              element={<AuthUser component={ProgressPage} />}
            />
            <Route
              path="/progress/progress-details"
              element={<AuthUser component={ProgressResultDetailsPage} />}
            />
            <Route
              path="/progress/progress-details/recorded-data"
              element={<AuthUser component={ProgressRecordedDataPage} />}
            />
            <Route
              path="/progress/search-patient-log"
              element={<AuthUser component={SearchProgressDetailsPage} />}
            />

            <Route path="*" Component={InvalidPage} />
          </Routes>
        </Router>
        <InfiniteLoader />
        <ToastContainer />
      </div>
    </div>
  );
};

export default AppRoutes;
