import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Card from "src/components/atoms/Card/Card";
import CommonMealHeaderCard from "./CommonMealHeaderCard";
import FoodItems from "./FoodItems";
import BottomSheetSelect from "src/components/atoms/BottomSheetSelect";
import Button from "src/components/atoms/Button/Button";

import BreakfastIcon from "../../../../assets/icons/meals/breakfast.svg";
import LunchIcon from "../../../../assets/icons/meals/lunch.svg";
import SnacksIcon from "../../../../assets/icons/meals/snacks.svg";
import DinnerIcon from "../../../../assets/icons/meals/dinner.svg";

import { logFoodItemRequest } from "src/store/slices/foodDiarySlice";
import { IMealType } from "src/types/foodDiary";
import { RootState } from "src/store";
import { dateConverstion } from "src/helpers/date";
import { bindQueryParams } from "src/helpers/common";

interface Props {
  cardData: IMealType;
}

const FoodDiaryMealCard: React.FC<Props> = ({ cardData }) => {
  const [openDeleteBottomSheet, setOpenDeleteBottomSheet] = useState<any>(null);
  const { user } = useSelector((state: RootState) => state.auth);
  const { dietPlan } = useSelector((state: RootState) => state.foodDiary);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //   const {hideLoader, showLoader} = useLoader();

  const mealMessageAndIcon = useMemo(() => {
    switch (cardData.key) {
      case "breakfast":
        return {
          message: "Breakfast is a passport to morning.",
          icon: BreakfastIcon,
        };
      case "dinner":
        return {
          message: "Dinner is a passport to better sleep.",
          icon: DinnerIcon,
        };
      case "lunch":
        return {
          message: "Lunch is a passport to noon.",
          icon: LunchIcon,
        };
      case "morning_snack":
        return {
          message: "Morning Snack is a passport to noon.",
          icon: LunchIcon,
        };
      case "evening_snack":
        return {
          message: "Evening Snack is a passport to noon.",
          icon: LunchIcon,
        };
      default:
        return {
          message: "Snacks is a passport to evening.",
          icon: SnacksIcon,
        };
    }
  }, [cardData]);

  const buttonPressEvents = (
    type: "consume" | "abstain" | "edit" | "delete" | "add",
    item: any
  ) => {
    if (type === "add") {
      const payload = {
        mealTypeId: item.meal_types_id,
        mealName: item.meal_type,
        isFreeUser: true,
      };
      navigate(bindQueryParams("/diet/search-food", payload));
    } else if (type === "edit") {
      const payload = {
        mealTypeId: item?.meal_types_id,
        foodItemId: item?.food_item_id.toString(),
        foodName: item?.food_name,
        unitOptionId: item?.unit_option_id.toString(),
        quantity: item?.quantity.toString(),
        type: item?.type,
        foodItemLogId: item?.patient_food_item_logs_id,
        buttonType: "Update",
        is_active: item?.is_active,
        mealName: cardData?.meal_type,
        unitName: item?.UNIT_NAME ?? item?.unit_name,
        toDietScreen: true,
      };
      navigate(bindQueryParams("/diet/search-food-result", payload));
    } else {
      let payload: any = {
        meal_types_id: item.meal_types_id,
        food_item_id: item.food_item_id.toString(),
        food_name: item.food_name,
        quantity: item.quantity.toString(),
        unit_option_id: item.unit_option_id.toString(),
        patient_id: user.data.patientID,
        achieved_datetime: dateConverstion(
          dietPlan.date,
          "YYYY-MM-DD hh:mm:ss",
          "YYYY-MM-DD hh:mm:ss",
          true
        ),
        type: item.type,
      };
      if (type === "delete") {
        payload = {
          ...payload,
          is_deleted: "Y",
        };
      } else {
        payload = {
          ...payload,
          is_active: type === "consume" ? "Y" : "N",
        };
      }

      if (!!item?.patient_food_item_logs_id) {
        payload = {
          ...payload,
          patient_food_item_logs_id: item?.patient_food_item_logs_id,
        };
      }
      if (type === "delete") {
        setOpenDeleteBottomSheet({
          onConfirm: () => {
            setOpenDeleteBottomSheet(null);
            updateFoodItem(payload, type, item);
          },
          onCancel: () => {
            setOpenDeleteBottomSheet(null);
          },
        });
      } else {
        updateFoodItem(payload, type, item);
      }
    }
  };

  const updateFoodItem = (
    payload: any,
    type: "consume" | "abstain" | "edit" | "delete" | "add" | "option",
    extraData: any
  ) => {
    const tempPayload = {
      payload,
      extraData,
      type,
    };
    // showLoader();
    new Promise((resolve, reject) => {
      dispatch(
        logFoodItemRequest({
          payload: tempPayload,
          resolve,
          reject,
        })
      );
    })
      .then(() => {
        // dispatch(healthSectionRefresh({isHealthRefresh: true}));
      })
      .finally(() => {
        // hideLoader();
      });
  };
  return (
    <section id={cardData?.meal_type}>
      <Card className="p-0 mb-2 bg-white">
        <CommonMealHeaderCard
          start_time={cardData?.start_time}
          end_time={cardData?.end_time}
          mealIcon={mealMessageAndIcon?.icon}
          meal_type={cardData?.meal_type}
          onPressAdd={() => buttonPressEvents("add", cardData)}
          // addIconVisible={addIconVisible}
          consumed_calories={Math.round(
            Number(cardData?.totalConsumeCalories ?? 0) ?? 0
          )}
          total_calories={Math.round(
            Number(cardData?.totalCalories ?? 0) === 0
              ? Number(cardData?.default_cal ?? 0)
              : Number(cardData?.totalCalories ?? 0) ?? 0
          )}
        />

        <FoodItems
          message={mealMessageAndIcon?.message}
          foodItems={cardData?.data ?? []}
          buttonPressEvents={buttonPressEvents}
          // buttonVisible={addIconVisible}
          // freeUser={!dietPlan?.data?.health_coach_id}
        />

        <BottomSheetSelect
          open={!!openDeleteBottomSheet}
          close={() => setOpenDeleteBottomSheet(null)}
        >
          <section className="p-4 pb-6">
            <p className="text-md text-center mb-6">
              Are you sure you want to delete this food item from your meal?
            </p>
            <div className="flex items-center gap-4">
              <Button
                variant="secondary"
                label="No"
                className="w-full"
                onClick={openDeleteBottomSheet?.onCancel}
              />
              <Button
                variant="primary"
                label="Yes"
                className="w-full"
                onClick={openDeleteBottomSheet?.onConfirm}
              />
            </div>
          </section>
        </BottomSheetSelect>
      </Card>
    </section>
  );
};

export default FoodDiaryMealCard;
