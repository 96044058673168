import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "src/store";
import { useDispatch } from "react-redux";
import moment from "moment";

import { Header } from "src/components/molecules/Header";
import ExpandableDivs from "src/components/atoms/Layout/ExpandingLayout";
import ProgressRecordedDataCard from "src/components/molecules/progress/ProgressRecordedDataCard";
import { progressRedingLogsRequest, setRedirectFromSearch, setUpdatedValue, updatePatientReadingsRequest } from "src/store/slices/progressSlice";
import BottomSheetSelect from "src/components/atoms/BottomSheetSelect";
import TextInputAnimated from "src/components/atoms/TextInputAnimated";
import Button from "src/components/atoms/Button/Button";
import { hideLoader, showLoader } from "src/store/slices/loaderSlice";
import { toast } from "react-toastify";

const ProgressRecordedDataPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { selectedProgressMasterId, progressReadings, redirectFromSearch } = useSelector((state: RootState) => state.progress);
  const theme = useSelector((state: RootState) => state.theme);

  const [showBottomSheet, setshowBottomSheet] = useState<boolean>(false);
  const [updatedReadingValue, setUpdatedReadingValue] = useState<number | null>(
    progressReadings?.[0]?.reading_value ? parseFloat(progressReadings[0].reading_value) : null
  );

  const handleChangeRedingValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseFloat(e.target.value);
    setUpdatedReadingValue(newValue);
  };

  useEffect(() => {
    fetchProgressRecordedData();
  }, [selectedProgressMasterId]);

  const fetchProgressRecordedData = () => {
    dispatch(showLoader());
    const payload = {
      readings_master_id: selectedProgressMasterId,
      page: "1",
    };
    new Promise((resolve, reject) => {
      dispatch(
        progressRedingLogsRequest({
          payload,
          resolve,
          reject,
        })
      );
    })
      .then((result: any) => {
        dispatch(hideLoader());
      })
      .catch((error) => {
        toast.error(error);
        dispatch(hideLoader());
      })
      .finally(() => {
        setshowBottomSheet(false);
        dispatch(hideLoader());
      });
  }

  const fetchUpdatePatientRedingData = async () => {
    const payload = {
      reading_datetime: moment().format("YYYY-MM-DD HH:mm:ss"),
      reading_id: (progressReadings?.[0]?.readings_master_id ? progressReadings?.[0]?.readings_master_id : selectedProgressMasterId),
      reading_value: updatedReadingValue,
      weight_unit: progressReadings?.[0]?.measurements || null,
    };
    await new Promise((resolve, reject) => {
      dispatch(showLoader())
      dispatch(
        updatePatientReadingsRequest({
          payload,
          resolve,
          reject,
        })
      );
    })
      .then((result: any) => {
        if (result.code == 1) {
          setshowBottomSheet(false)
          fetchProgressRecordedData();
          dispatch(hideLoader())
          dispatch(
            setUpdatedValue({
              master_id: String(progressReadings?.[0]?.readings_master_id),
              value: Number(updatedReadingValue),
              date: moment().format("YYYY-MM-DD HH:mm:ss")
            })
          );
        }
      })
      .catch((error) => {
        toast.error(error);
        dispatch(hideLoader());
      })
      .finally(() => {
        setshowBottomSheet(false);
        dispatch(hideLoader());
      });
  }

  const onClickOfAddIcon = () => {
    setshowBottomSheet(true);
  };

  const handleClickOfUpdatePatientReading = async () => {
    fetchUpdatePatientRedingData();
  };

  const handleOnClickOfBackButton = () => {
    if (redirectFromSearch) {
      navigate("/progress")
      dispatch(setRedirectFromSearch(false))
    } else {
      navigate("/progress/progress-details")
    }
  }

  return (
    <div className=" bg-background-light-blue h-screen">
      <ExpandableDivs
        showExpandBtn={false}
        component1={
          <div className="flex items-center justify-between mr-3">
            <Header
              backButton={true}
              rightButton={false}
              title="All Recorded Data"
              className="bg-background-light-blue"
              onClickOfBackBtn={handleOnClickOfBackButton}
            />
            <button onClick={onClickOfAddIcon}>
              <h1 className=" font-poppins text-color-dark-blue text-sm font-semibold">Add</h1>
            </button>
          </div>
        }
        component2={
          <>
            <div className="p-4 flex flex-col gap-4">
              {progressReadings && progressReadings?.length > 0 ? (
                <>
                  {progressReadings.map((progressReadings, index) => (
                    <ProgressRecordedDataCard
                      key={index}
                      readingName={progressReadings.reading_name}
                      readingValue={Number(progressReadings.reading_value)}
                      recordedDate={progressReadings.reading_datetime}
                    />
                  ))}
                </>
              ) : (
                <>
                  <div className="flex justify-center font-poppins items-center text-text-primary text-sm">
                    Please add the value to log your vital.
                  </div>
                </>
              )}
            </div>
            {showBottomSheet && (
              <BottomSheetSelect open={showBottomSheet} close={() => setshowBottomSheet(false)}>
                <section className="p-4">
                  <h1 className="text-xl font-bold text-text-secodary">{progressReadings && progressReadings[0]?.reading_name}</h1>
                </section>
                <hr className="h-0.5 bg-background" />

                <section className={`p-4 flex flex-col gap-3`}>
                  <div className="flex gap-2">
                    <TextInputAnimated
                      id="Reading Value"
                      label="Reading Value"
                      value={null}
                      required
                      readonly={false}
                      onChange={(e) => handleChangeRedingValue(e)}
                    />
                    {progressReadings?.[0]?.measurements && (
                      <div className="flex items-center justify-center text-sm text-text-secondary bg-color-extra-light-gray p-2 rounded-md font-poppins">
                        {progressReadings?.[0]?.measurements || ""}
                      </div>
                    )}
                  </div>
                  <Button variant="primary" label="Update" name="Update" className="w-full" onClick={handleClickOfUpdatePatientReading} />
                </section>
              </BottomSheetSelect>
            )}
          </>
        }
      />
    </div>
  );
};
export default ProgressRecordedDataPage;