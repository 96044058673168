import React from "react";
import PropTypes from "prop-types";
import HealthyFoodImage from "src/assets/images/landing-page/healthy-food.png";
import PolygonIcon from "src/components/atoms/Icons/PolygonIcon";
import { useSelector } from "react-redux";
import { RootState } from "src/store";

const DietExerciseCard = (props: any) => {
  const {
    titleText,
    data,
    description,
    isLoading = false,
    // lottieAnimation,
    todaysAchievedValue,
    todaysAcheievedText,
    onPress,
    children,
    rightImage,
  } = props;
  const theme = useSelector((state: RootState) => state.theme);

  const handlePress = () => {
    if (onPress) onPress("card");
  };

  return (
    <div
      className={`flex-1 mb-4 pt-6 border border-[#E4E7EB] bg-white rounded-[45px] mx-4 overflow-hidden ${
        titleText === "Exercise" ? "pb-6" : ""
      }`}
    >
      <div
        className="flex flex-1 flex-row cursor-pointer"
        onClick={handlePress}
      >
        <div className="flex flex-col pl-6 w-1/2">
          {/* Header with Icon */}
          <div className="flex items-center">
            {data?.image_url && (
              <img
                src={data.image_url}
                alt="Diet/Exercise"
                className="h-10 w-10 object-contain"
              />
            )}
            <p className="text-lg font-bold text-gray-800 ml-3">{titleText}</p>
          </div>

          {/* Loading Animation or Description */}
          {/* {isLoading && lottieAnimation && <div>{lottieAnimation}</div>} */}

          {!isLoading && !todaysAchievedValue && (
            <p className="mt-2 text-sm text-text-subtitle">{description}</p>
          )}

          {/* Achieved Value */}
          {!isLoading && todaysAchievedValue > 0 && (
            <div className="flex items-center space-x-2 w-28 mt-2">
              <span className="text-lg font-bold">{todaysAchievedValue}</span>
              <span className="text-sm text-gray-500">
                {todaysAcheievedText}
              </span>
            </div>
          )}
          <div className="mt-10">
            <PolygonIcon color={theme.colors["--color-primary"]} />
          </div>
        </div>

        {/* Children Section */}
        <div className="w-1/2">
          <img
            src={rightImage}
            alt="Next"
            // className="absolute w-1/2 h-[75vw] -right-[44.44vw] -top-8"
          />
        </div>
      </div>

      {/* Diet Info Section */}
      {titleText === "Diet" && (
        <div className="flex justify-between items-center bg-yellow-100 rounded-b-[40px] py-5 px-6 mt-4">
          <p className="text-yellow-600">
            Carbs{" "}
            <span className="font-bold">
              {data?.carbs > 0 ? `${Number(data.carbs).toFixed(1)}g` : "- -"}
            </span>
          </p>
          <p className="text-yellow-600">
            Protein{" "}
            <span className="font-bold">
              {data?.protein > 0
                ? `${Number(data.protein).toFixed(1)}g`
                : "- -"}
            </span>
          </p>
          <p className="text-yellow-600">
            Fat{" "}
            <span className="font-bold">
              {data?.fat > 0 ? `${Number(data.fat).toFixed(1)}g` : "- -"}
            </span>
          </p>
        </div>
      )}
    </div>
  );
};

DietExerciseCard.propTypes = {
  titleText: PropTypes.string,
  data: PropTypes.object,
  description: PropTypes.string,
  isLoading: PropTypes.bool,
  //   lottieAnimation: PropTypes.node,
  todaysAchievedValue: PropTypes.number,
  todaysAcheievedText: PropTypes.string,
  onPress: PropTypes.func,
  rightImage: PropTypes.node,
  //   onPressSync: PropTypes.func,
  children: PropTypes.node,
};

export default DietExerciseCard;
