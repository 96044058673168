import React from 'react';

interface Props {
    color?: string;
    className?: string;
}

export const RightArrowIcon: React.FC<Props> = ({ color = "#760FB2", className }) => {
    return (
        <svg className={className} width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12.525 4.94165L17.5834 9.99998L12.525 15.0583"
                stroke={color}
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M3.41663 10H17.4416"
                stroke={color}
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
