import React from "react";
import { useSelector } from "react-redux";

import Card from "../Card/Card";
import CircularProgress from "../CircularProgress/CircularProgress";
import VectorIcon from "../Icons/VectorIcon";
import { RootState } from "../../../store";

interface Props {
  achievedValue: number;
  goalValue: number;
  subText: string;
  disabled?: boolean;
  onClick?: any;
  unitValue?: string;
}

const ProgressCard: React.FC<Props> = ({
  achievedValue = 1,
  goalValue = 1,
  subText,
  disabled = false,
  onClick,
  unitValue,
}) => {
  const theme = useSelector((state: RootState) => state.theme);
  const percentageValue =
    achievedValue > 0 && goalValue > 0
      ? Math.floor((achievedValue / goalValue) * 100)
      : 0;
  return (
    <button className="w-full" disabled={disabled} onClick={onClick}>
      <Card className="m-4 px-4">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-4">
            <CircularProgress
              value={percentageValue}
              text={`${percentageValue}%`}
              className="w-10 h-10"
            />
            <div className="text-left">
              <p className="text-sm text-text-title">
                <span className="text-xl font-semibold">{achievedValue}</span>{" "}
                of {goalValue} {unitValue}
              </p>
              <p className="text-xs leading-4 text-text-subtitle">{subText}</p>
            </div>
          </div>
          <div>
            <VectorIcon color={theme.colors["--color-primary"]} />
          </div>
        </div>
      </Card>
    </button>
  );
};

export default ProgressCard;
