// src/store/slices/authSlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { auth } from "../../types";
import { LOCAL_STORAGE } from "src/constants/common";
import { setUserAttributes } from "src/analytics";

// Initial state for the auth slice
const initialState: auth.UsersStateType = {
  clientSource: "default",
  user: {
    data: {} as auth.UserType,
    isLoading: false,
    errors: "",
  },
  isUserLoggedIn: false,
};

// Define the auth slice
const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    authenticateUserRequest: (
      state: auth.UsersStateType,
      action: PayloadAction<any>
    ) => {
      state.user.isLoading = true;
      state.user.errors = "";
    },
    authenticateUserSuccess: (
      state: auth.UsersStateType,
      action: PayloadAction<any>
    ) => {
      state.user.isLoading = false;
      state.user.data = action.payload.data;
      setUserAttributes(action.payload.data);
      localStorage.setItem(
        LOCAL_STORAGE.AUTH_TOKEN,
        action.payload.data?.token
      );
      localStorage.setItem(
        LOCAL_STORAGE.USER_INFO,
        JSON.stringify(action.payload.data)
      );
      state.isUserLoggedIn = true;
    },
    authenticateUserError: (
      state: auth.UsersStateType,
      { payload: error }: PayloadAction<string>
    ) => {
      state.user.isLoading = false;
      state.user.errors = error;
    },
    setClientSource: (
      state: auth.UsersStateType,
      action: PayloadAction<any>
    ) => {
      let clientSource =
        action.payload ||
        localStorage.getItem(LOCAL_STORAGE.CLIENT_SOURCE) ||
        "default";
      state.clientSource = clientSource;
    },
  },
});

// Export actions and reducer
export const {
  authenticateUserRequest,
  authenticateUserSuccess,
  authenticateUserError,
  setClientSource,
} = authSlice.actions;

export default authSlice.reducer;
