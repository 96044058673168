import React from "react";
import Picker from "react-mobile-picker";

interface Option {
  label: string;
  value: string;
}

interface PickerProps {
  options: Option[]; // Options array with label and value
  value: string; // Currently selected value
  onChange: (newValue: string) => void; // Callback for selected value
  className?: string; // Custom styling class
}

const WeelPicker: React.FC<PickerProps> = ({
  options,
  value,
  onChange,
  className = "",
}) => {
  // Convert options to a format suitable for rendering
  const handlePickerChange = (newValue: { [key: string]: string }) => {
    const selectedValue = newValue.value; // Extract the value
    onChange(selectedValue);
  };

  return (
    <div className={`weel-picker ${className}`}>
      <Picker
        value={{ value }} // Pass the current selected value
        onChange={handlePickerChange} // Handle changes
        itemHeight={50} // Height of each option
        height={200} // Overall height of the picker
        wheelMode="natural" // Enable natural wheel behavior
      >
        <Picker.Column name="value">
          {options.map((option) => (
            <Picker.Item
              key={option.value}
              value={option.value} // Set the value for each option
              className={`${
                value === option.value
                  ? "text-text-primary text-2xl font-medium"
                  : "text-text-subtitle text-xl font-thin"
              }`}
            >
              {option.label} {/* Render the label */}
            </Picker.Item>
          ))}
        </Picker.Column>
      </Picker>
    </div>
  );
};

export default WeelPicker;
