import React, { useCallback } from "react";
import { useSelector } from "react-redux";

import FoodDiaryMealCard from "./FoodDiaryMealCard";
import DietPlanMealCard from "./DietPlanMealCard";

import { IMealType } from "../../../../types/foodDiary";
import { RootState } from "src/store";

interface Props {
  data: IMealType[];
}

const MealsCards: React.FC<Props> = ({ data }) => {
  const { dietPlan } = useSelector((state: RootState) => state.foodDiary);

  const renderMealCard = useCallback(
    (props: any) => {
      if (dietPlan.type === "dietPlan") {
        return renderMealCardItem(props);
      } else {
        return renderEmptyMealCardItem(props);
      }
    },
    [dietPlan]
  );

  const renderEmptyMealCardItem = useCallback(
    ({ item }: { item: IMealType }) => {
      return <FoodDiaryMealCard cardData={item} />;
    },
    [dietPlan?.meals]
  );

  const renderMealCardItem = useCallback(
    ({ item }: { item: IMealType }) => {
      return <DietPlanMealCard mealData={item} />;
    },
    [dietPlan?.meals]
  );

  return (
    <div className="px-4">{data?.map((item) => renderMealCard({ item }))}</div>
  );
};

export default MealsCards;
