import React from 'react';

interface ProgressPointerProps {
    color?: string;
}

export const ProgressPointer: React.FC<ProgressPointerProps> = ({ color = "#F15223" }) => {
    return (
        <div>
            <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#filter0_d_4466_963)">
                    <path
                        d="M9.86603 7.5C9.48113 8.16667 8.51887 8.16667 8.13397 7.5L4.66987 1.5C4.28497 0.833333 4.7661 -8.94676e-07 5.5359 -8.27378e-07L12.4641 -2.21695e-07C13.2339 -1.54397e-07 13.715 0.833333 13.3301 1.5L9.86603 7.5Z"
                        fill={color}
                    />
                </g>
                <defs>
                    <filter id="filter0_d_4466_963" x="0.534424" y="0" width="16.9312" height="16" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                        <feOffset dy="4" />
                        <feGaussianBlur stdDeviation="2" />
                        <feComposite in2="hardAlpha" operator="out" />
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4466_963" />
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4466_963" result="shape" />
                    </filter>
                </defs>
            </svg>
        </div>
    );
};
