import React from "react";

interface Props {
  bgColor?: string;
  image?: string;
  className?: string;
}

const ProgressIcon: React.FC<Props> = ({ bgColor, image, className }) => {
  return (
    <div className={`${bgColor} rounded-4.5  flex items-center justify-center flex-shrink-0 ${className}`}>
      <img src={image} alt="Image" className="object-cover w-13 h-13" />
    </div>
  )
}

export default ProgressIcon;
