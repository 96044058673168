import environment from "../constants/environment";
import { decryptData, encryptData } from "../helpers/encrypt-decrypt";
import { getToken } from "../helpers/localStorage";

const methods = {
  POST: "post",
  GET: "get",
  PUT: "put",
  PATCH: "patch",
  DELETE: "delete",
};

interface RequestConfig {
  route: string;
  method: string;
  priv?: boolean;
  encrypt?: boolean;
  query_params?: Record<string, string | number>;
  payload?: any;
}

const handleResponse = async (
  response: Response,
  encrypt: boolean = true
): Promise<any> => {
  if (!response.ok) {
    const error = await response.text();
    throw new Error(
      `HTTP error! Status: ${response.status}, Message: ${error}`
    );
  }

  let res: any;

  const contentType = response.headers.get("Content-Type");
  if (contentType && contentType.indexOf("application/json") !== -1) {
    res = await response.json();
  } else {
    res = await response.text();
  }

  if (encrypt) {
    const decryptedResponse = decryptData(res);
    const parsedResponse = JSON.parse(decryptedResponse);

    return parsedResponse;
  } else {
    return res;
  }
};

const request = async ({
  method,
  route,
  priv = true,
  encrypt = true,
  query_params = {},
  payload = null,
}: RequestConfig): Promise<any> => {
  const headers: any = {
    "api-key": environment.API_KEY,
    ...(priv && { token: await getToken() }),
  };

  if (!headers["token"] && priv) {
    localStorage.clear();
    window.location.reload();
    return;
  }

  const isFormData = payload instanceof FormData;

  const config: RequestInit = {
    method,
    headers,
    body: isFormData
      ? payload
      : ["post", "put", "patch"].includes(method)
      ? encrypt && payload
        ? encryptData(payload)
        : payload
      : undefined,
  };

  const queryString = new URLSearchParams(
    Object.entries(query_params).reduce((acc, [key, value]) => {
      acc[key] = String(value);
      return acc;
    }, {} as Record<string, string>)
  ).toString();

  const basePath = `${environment.API_BASE_URL}${route}`;
  const url = queryString ? `${basePath}?${queryString}` : basePath;

  try {
    const response = await fetch(url, config);
    return await handleResponse(response, encrypt);
  } catch (err) {
    console.error("Request failed:", err);
    throw err;
  }
};

export { request, methods };
